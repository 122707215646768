import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import { Router } from '@angular/router';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import { environment } from '../../../environments/environment';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import { sleep, venueAcceptsOrders } from '../../../smoothr-web-app-core/utils/utils';
import { MenuPage } from '../menu/menu.page';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import Address from '../../../smoothr-web-app-core/models/Address';
import { OrderType } from 'src/smoothr-web-app-core/enums/OrderType';
import { NAVIGATIONURLS, NavigationService } from 'src/app/services/navigation.service';
import { MapPage } from '../map/map.page';
import { MapLocationModalComponent } from 'src/app/components/map-location-modal/map-location-modal.component';
import { ModalController, PopoverController } from '@ionic/angular';
import { SelectLanguagePopoverComponent } from 'src/app/components/select-language-popover/select-language-popover.component';
import { AllowGpsModalResult } from 'src/app/components/allow-gps-modal/allow-gps-modal.component';
import { MapsUtils } from 'src/smoothr-web-app-core/utils/maps-utils';
import Order from 'src/smoothr-web-app-core/models/Order';
import { Api } from 'src/smoothr-web-app-core/api/api';
import { CheckDeliveryRadiusModalComponent } from 'src/app/components/check-delivery-radius-modal/check-delivery-radius-modal.component';
import { BrazeService } from 'src/app/services/braze.service';
import { EnterFoodspotCodeModalComponent } from 'src/app/components/enter-foodspot-code-modal/enter-foodspot-code-modal.component';
import { TimeUtils } from 'src/smoothr-web-app-core/utils/time-utils';

enum Language {
	GreatBritain = 'en',
	German = 'de',
}

@Component({
	selector: 'app-home',
	templateUrl: './home.page.dean_and_david.html',
	styleUrls: ['./home.page.dean_and_david.scss'],
})
export class HomePage extends RepositoryDirective implements OnInit {
	static url = 'home';
	environment = environment;
	languageEnum = Language;
	orders: Order[] = [];
	selectedPreorderType: PreorderType = null;
	loading = false;
	preorderTypes = [PreorderType.INSIDE, PreorderType.TAKE_AWAY, PreorderType.DELIVERY, PreorderType.FOOD_SPOT].filter(it =>
		environment.deactivateDelivery ? it !== PreorderType.DELIVERY && environment.deactivateDelivery : true
	);
	news = [
		'../../../assets/dean_and_david/news.png',
		'../../../assets/dean_and_david/news.png',
		'../../../assets/dean_and_david/news.png',
	];
	active: any = {};
	opened = false;
	selectedLanguage = Language.German;
	public contentCards$ = this.brazeService.contentCards$;
	constructor(
		protected repository: RepositoryService,
		private router: Router,
		public translate: TranslateService,
		private navigationService: NavigationService,
		private modalCtrl: ModalController,
		private popover: PopoverController,
		private brazeService: BrazeService,
		private navService: NavigationService
	) {
		super(repository);
	}

	ngOnInit() {
		super.ngOnInit();
		this.loading = true;
		this.loadOrders();
	}
	async loadOrders() {
		this.orders = [];
		if (!this.customer) {
			console.log('user not loaded');

			return;
		}
		try {
			const result = await Api.getCustomerOrders(this.customer.uid);
			console.log(result);
			if (result.data.withPayment) {
				this.orders = result.data.withPayment
					.concat(result.data.withoutPayment)
					.filter(it => it?.preorder?.type !== PreorderType.FOOD_SPOT);
			}
		} catch (e) {}
	}
	ionViewDidEnter() {
		if (history.state.navigationId === 1) {
			this.repository.onOrderChange(null);
		}
		this.repository.verifiedOrder.emit(null);
	}

	async navigateToMap(item: PreorderType) {
		if (window?.location?.href?.includes('home')) {
			var ua = navigator.userAgent.toLowerCase();
			if (ua.indexOf('safari') != -1) {
				if (ua.indexOf('chrome') > -1) {
				} else {
					if (!this.brazeService.isPushPermissionGranted()) {
						const isSupported = this.brazeService.checkIsSupported();
						this.brazeService.requestPushPermission();
					}
				}
			}
		}
		if (item === PreorderType.DELIVERY) {
			// const result = await CheckDeliveryRadiusModalComponent.show(this.modalCtrl, this.address);
			// if (result?.address) {
			// 	this.repository.address.emit(result.address);
			// }
		}
		if (item == PreorderType.FOOD_SPOT) {
			this.askFoodSpotCode();
			return;
		}
		if (!this.repository?._address) {
			await this.checkGeolocation();
		}
		this.navigationService.navigateToUrlWithParams(NAVIGATIONURLS.map(), { preorderType: item });
	}
	async checkGeolocation() {
		try {
			const address = await MapsUtils.getUserGeocode();
			this.repository.address.emit(address);
		} catch (e) {
			console.error(e);
			this.repository.address.emit(null);
		}
	}
	async goToMenu(type: PreorderType) {}
	changeLang(language: string) {
		this.translate.setDefaultLang(language);
		this.translate.use(language);
	}

	async openPopoverLanuage(ev: any) {
		console.log(ev);

		this.opened = true;
		const popover = await this.popover.create({
			component: SelectLanguagePopoverComponent,
			cssClass: 'popover-home',
			event: ev,
			translucent: true,
			showBackdrop: false,
			side: 'top',
			alignment: 'end',
			reference: 'event',
			componentProps: {
				lang: this.translate.currentLang,
			},
		});
		popover.onWillDismiss().then(v => {
			console.log(v);
			if (v.data) {
				this.translate.setDefaultLang(v.data);
				this.translate.use(v.data);
				this.selectedLanguage = v.data;
			}

			this.opened = false;
		});
		await popover.present();

		return;
	}

	selectedImage() {
		return '../../../assets/' + this.selectedLanguage + '.svg';
	}
	openPage(value: string) {
		if (!value) {
			return;
		}
		this.router.navigate([value]);
	}
	async askFoodSpotCode() {
		try {
			const result = await EnterFoodspotCodeModalComponent.show(this.modalCtrl);
			if (result && result?.openingHours?.length > 0) {
				result.openingHours = TimeUtils.sanitizeHours(result.openingHours);
				this.repository.companyCode$.next(result);
				if (result.venue) {
					const venue = await this.repository.getVenue(result.venue);
					await this.selectVenue(venue, PreorderType.FOOD_SPOT);
					await sleep(200);
				}
			}
		} catch (e) {}
	}

	async selectVenue(venue: Venue, preorderType: PreorderType, attempt: number = 0, prevError: any = null) {
		if (!venueAcceptsOrders(venue, preorderType)) {
			return;
		}

		try {
			await this.repository.getVenue(venue._id);
			this.repository.createOrder(venue, this.address, OrderType.PREORDER, preorderType);

			await sleep(1000);
			this.navService.foodSpot();

			this.loading = false;
		} catch (e) {
			await this.selectVenue(venue, preorderType, attempt + 1, e);
		}
	}
}
