// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-default {
  display: flex;
  align-items: center;
  width: 90%;
  min-height: 150px;
  max-height: 150px;
  padding: 20px;
  border-radius: 16px;
  min-width: 90%;
  box-shadow: 0px 0px 20px 0px rgba(79, 97, 79, 0.1490196078);
  margin-right: 10px;
  overflow: hidden;
}
.item-default ion-img {
  width: 30%;
  height: 100%;
}
.item-default .info {
  width: 70%;
  padding: 10px;
}
.item-default .info h3, .item-default .info p {
  color: var(--ion-color-secondary);
  font-size: 14px;
  padding: 0px;
}
.item-default .info h3 {
  padding-bottom: 10px;
}

.item-image {
  display: flex;
  align-items: center;
  width: 90%;
  min-height: 150px;
  max-height: 150px;
  padding: 20px;
  border-radius: 16px;
  min-width: 90%;
  margin-right: 10px;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 4px 10px rgba(33, 33, 33, 0.02);
}
.item-image ion-img {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/app/components/draw-news-braze-item/draw-news-braze-item.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,UAAA;EACA,iBAAA;EACA,iBAAA;EACA,aAAA;EAEA,mBAAA;EACA,cAAA;EACA,2DAAA;EAEA,kBAAA;EACA,gBAAA;AADJ;AAGI;EACI,UAAA;EACA,YAAA;AADR;AAGI;EACI,UAAA;EASA,aAAA;AATR;AACQ;EACI,iCAAA;EACA,eAAA;EACA,YAAA;AACZ;AACQ;EACI,oBAAA;AACZ;;AAIA;EACI,aAAA;EACA,mBAAA;EACA,UAAA;EACA,iBAAA;EACA,iBAAA;EACA,aAAA;EAEA,mBAAA;EACA,cAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,+CAAA;AAFJ;AAII;EACI,kBAAA;EACA,OAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,iBAAA;AAFR","sourcesContent":[".item-default {\n    display: flex;\n    align-items: center;\n    width: 90%;\n    min-height: 150px;\n    max-height: 150px;\n    padding: 20px;\n\n    border-radius: 16px;\n    min-width: 90%;\n    box-shadow: 0px 0px 20px 0px #4F614F26;\n\n    margin-right: 10px;\n    overflow: hidden;\n\n    ion-img {\n        width: 30%;\n        height: 100%;\n    }\n    .info {\n        width: 70%;\n        h3,p {\n            color: var(--ion-color-secondary);\n            font-size: 14px;\n            padding: 0px;\n        }\n        h3 {\n            padding-bottom: 10px;\n        }\n        padding: 10px;\n    }\n}\n.item-image {\n    display: flex;\n    align-items: center;\n    width: 90%;\n    min-height: 150px;\n    max-height: 150px;\n    padding: 20px;\n\n    border-radius: 16px;\n    min-width: 90%;\n    margin-right: 10px;\n    position: relative;\n    overflow: hidden;\n    box-shadow: 0px 4px 10px rgba(33, 33, 33, 0.02);\n\n    ion-img {\n        position: absolute;\n        left: 0;\n        top: 0;\n        right: 0;\n        bottom: 0;\n        object-fit: cover;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
