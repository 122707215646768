import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { AlertController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import { HomePage } from '../home/home.page';
import { Api } from '../../../smoothr-web-app-core/api/api';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { OrderUtils } from '../../../smoothr-web-app-core/utils/order-utils';
import { environment } from '../../../environments/environment';
import { AnalyticsService } from '../../../smoothr-web-app-core/services/analytics/analytics.service';
import { numberD, numberToCurrency, sleep } from '../../../smoothr-web-app-core/utils/utils';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import { NavigationService } from '../../services/navigation.service';
import { PaymentMethod } from 'src/smoothr-web-app-core/enums/PaymentMethod';
import Payment from 'src/smoothr-web-app-core/models/Payment';
import PromoCode from 'src/smoothr-web-app-core/models/PromoCode';

import { Socket } from 'ngx-socket-io';
import * as L from 'leaflet';
import polyline from '@mapbox/polyline';
import { Observable } from 'rxjs';
import Order from 'src/smoothr-web-app-core/models/Order';
import { OrderStatus } from 'src/smoothr-web-app-core/enums/OrderStatus';
import SmoothrDispatchLocation from 'src/smoothr-web-app-core/models/SmoothrDispatchLocation';
import { DeliveryStatus } from 'src/smoothr-web-app-core/enums/DeliveryStatus';

@Component({
	selector: 'app-payment-success',
	templateUrl: './payment-success.page.html',
	styleUrls: ['payment-success.page.scss'],
})
export class PaymentSuccessPage implements OnInit, AfterViewInit {
	static url = 'payment/success';
	static urlWithPaymentParam = 'payment/success/:payment';
	static ORDER_ID_PARAM = 'order';
	moment = moment;
	OrderUtils = OrderUtils;
	pm = PaymentMethod;
	pt = PreorderType;
	orderVenue: Venue;
	error: any;
	orderId: string;
	orderStatusEnum = OrderStatus;
	environment = environment;
	numberToCurrency = numberToCurrency;
	loading = true;
	paymentData: Payment;
	promocode: PromoCode = null;
	paymentId: string = '';
	verifiedOrder: Order;
	private map: L.Map;
	private driverMarker: L.Marker;
	driverDispatch: SmoothrDispatchLocation = null;
	estimatedNewTime: string = '';
	currentPolyline: any;
	deliveryStatusEnum = DeliveryStatus;
	numberD = numberD;
	constructor(
		private translate: TranslateService,
		protected repository: RepositoryService,
		private alertCtrl: AlertController,
		private router: Router,
		private snackbarCtrl: MatSnackBar,
		private route: ActivatedRoute,
		private analytics: AnalyticsService,
		private navigationService: NavigationService,
		private socket: Socket,
		private changeDetection: ChangeDetectorRef
	) {}

	// static async navigate(router: Router, order: string, payment: string) {
	// 	await router.navigate([this.url + '/' + payment], {
	// 		queryParams: {
	// 			order
	// 		}
	// 	});
	// }

	ionViewDidEnter() {
		this.repository.onOrderChange(null);
	}
	ngAfterViewInit(): void {}

	private async initMap(dispatch: SmoothrDispatchLocation) {
		// Ensure the map container exists

		if (!dispatch?.driverLocation?.latitude) {
			return;
		}
		const mapContainer = document.getElementById('map-item');
		console.log('INIT MAP', mapContainer);
		if (mapContainer) {
			this.driverDispatch = dispatch;
			await sleep(1000);
			this.map = L.map('map-item', {
				center: [dispatch.driverLocation.latitude, dispatch.driverLocation.longitude],
				zoom: 13,
			});

			L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
				attribution: '&copy; OpenStreetMap contributors',
			}).addTo(this.map);

			// Decode the polyline
			if (dispatch.geometry) {
				this.updatePolyline(dispatch.geometry);
			}
			const routeCoordinates = polyline.decode(dispatch.geometry);

			// Convert the coordinates to LatLng objects
			const routeLatLngs = routeCoordinates.map(coord => L.latLng(coord[0], coord[1]));

			// Create custom icons
			const startIcon = L.icon({
				iconUrl: 'assets/dean_and_david/delivery/start.svg',
				iconSize: [38, 38], // size of the icon
				iconAnchor: [19, 38], // point of the icon which will correspond to marker's location
				popupAnchor: [0, -38], // point from which the popup should open relative to the iconAnchor
			});

			const endIcon = L.icon({
				iconUrl: 'assets/dean_and_david/delivery/final.svg',
				iconSize: [38, 38], // size of the icon
				iconAnchor: [19, 38], // point of the icon which will correspond to marker's location
				popupAnchor: [0, -38], // point from which the popup should open relative to the iconAnchor
			});

			// Add start marker with custom icon
			let startPoint = routeLatLngs[0];
			if (this.orderVenue) {
				if (this.orderVenue?.location?.coordinates && this.orderVenue?.location?.coordinates.length > 0) {
					startPoint = {
						lat: this.orderVenue?.location?.coordinates[1],
						lng: this.orderVenue?.location?.coordinates[0],
					};
					console.log('START POINT 2', startPoint);
				}
			}
			L.marker(startPoint, { icon: startIcon }).addTo(this.map).bindPopup('Start Point');

			// Add end marker with custom icon

			let endPoint = routeLatLngs[routeLatLngs.length - 1];

			if (this.verifiedOrder) {
				if (
					this.verifiedOrder?.preorder?.type === PreorderType.DELIVERY &&
					this.verifiedOrder?.preorder?.lat &&
					this.verifiedOrder?.preorder?.lng
				) {
					endPoint = {
						lat: this.verifiedOrder?.preorder?.lat,
						lng: this.verifiedOrder?.preorder?.lng,
					};
				}
			}
			L.marker(endPoint, { icon: endIcon }).addTo(this.map).bindPopup('End Point');
			if (dispatch.driverLocation) {
				this.updateDriverLocation(dispatch.driverLocation);
			}
			console.log('::::L::::', L, this.map);
		} else {
			console.error('Map container not found.');
		}
	}
	private updateDriverLocation(driverLocation: { latitude: number; longitude: number }, type: string = 'Car'): void {
		console.log('UPDAT DRIVER', driverLocation, this.driverMarker);

		if (this.driverMarker) {
			// Update the position of the existing marker
			this.driverMarker.setLatLng([driverLocation.latitude, driverLocation.longitude]);
		} else {
			// Create a new marker if it doesn't exist
			const driverIcon = L.icon({
				iconUrl: type == 'Car' ? 'assets/dean_and_david/delivery/car.svg' : 'assets/dean_and_david/delivery/driver.svg',
				iconSize: [35, 35], // size of the icon
				iconAnchor: [19, 38], // point of the icon which will correspond to marker's location
				popupAnchor: [0, -38], // point from which the popup should open relative to the iconAnchor
			});
			console.log('Update Driver', this.map);
			if (this.map) {
				this.driverMarker = L.marker({ lat: driverLocation.latitude, lng: driverLocation.longitude }, { icon: driverIcon })
					.addTo(this.map)
					.bindPopup('Driver');
			}
		}
	}

	ngOnInit(): void {
		this.route.queryParams.subscribe(params => {
			console.log({
				params,
			});
			if (this.repository._verifiedOrder && this.repository._verifiedOrder._id && !params.order) {
				this.orderId = this.repository._verifiedOrder._id;
			} else {
				this.orderId = params.order;
			}
			this.reloadOrder();
		});
		this.route.params.subscribe(v => {
			console.log(v);
			if (v?.payment) {
				this.paymentId = v?.payment;
			}
		});
		setTimeout(() => {
			console.log('TEST');
			// this.functionForTest()
		}, 4000);
	}
	ngOnDestroy(): void {
		console.log('DESTROY');
	}

	async reloadOrder(attempt: number = 0, error = null) {
		this.error = null;
		this.loading = true;
		if (attempt > 3) {
			this.error = error ? error : this.translate.instant('payment_success_page.payment_error');
			this.snackbarCtrl.open(error, null, {
				duration: 2000,
			});
			this.loading = false;
			return;
		}
		if (attempt > 0) {
			await sleep(200);
		}
		try {
			const orderResponse = (await Api.getOrder(this.orderId)) as any;
			this.verifiedOrder = orderResponse.data;
			this.repository.verifiedOrder.emit(orderResponse.data);
			console.log(orderResponse.data.payment);
			if (orderResponse?.data?.payment) {
				this.paymentData = (await Api.getPayment(orderResponse.data.payment)).data;
			} else {
				if (this.paymentId) {
					this.paymentData = (await Api.getPayment(this.paymentId)).data;
				}
			}

			if (!this.repository._verifiedOrder.isPayed && this.paymentData.method !== PaymentMethod.CASH) {
				await this.reloadOrder(attempt + 1, this.translate.instant('payment_success_page.please_reload'));
				return;
			}
			this.analytics.paymentSuccess();
			if (orderResponse?.data?.promoCode) {
				this.promocode = await this.loadPromocode(orderResponse.data.promoCode);
			}
			try {
				if (this.repository._venue && this.repository._venue._id === this.repository._verifiedOrder.venue) {
					this.orderVenue = this.repository._venue;
				} else {
					this.orderVenue = (await Api.getLazyVenue(this.repository._verifiedOrder.venue)).data;
				}
				if (this.verifiedOrder?.dispatch?.geometry) {
					this.initMap(this.verifiedOrder.dispatch);
				}
				if (orderResponse?.data.preorder?.type === PreorderType.DELIVERY) {
					setTimeout(() => {}, 2500);
					this.startListenSocketEvents(this.orderVenue._id, this.verifiedOrder._id);
				}
				//TODO: Testing

				this.error = false;
				this.loading = false;
			} catch (e) {
				console.error(e);
				await this.reloadOrder(attempt + 1, error ? error : e);
				return;
			}
		} catch (e) {
			console.error(e);
			await this.reloadOrder(attempt + 1, e);
			return;
		}
	}
	async loadPromocode(promocodeId: string) {
		try {
			return (await Api.getPromoCodeById(promocodeId)).data;
		} catch (e) {
			return null;
		}
	}

	async home() {
		if (this.loading) {
			return;
		}
		if (this.error) {
			this.reloadOrder();
			return;
		}
		this.loading = true;
		this.socket.disconnect();
		await this.repository.cleanRepository();
		await this.navigationService.home();
		// await Api.activateAndDeactivateDeliveryTracking(this.verifiedOrder._id, 'disable');
	}
	navigateToUrl(url: string) {
		if (url) {
			window.open(url, '_blank');
		}
	}
	async startListenSocketEvents(venueId: string, orderId: string) {
		console.log('START SOCKET');

		const roomId = `WEB_APP_${orderId}`;
		const test = roomId + '_' + venueId;

		this.socket.emit('authenticate', [roomId], [venueId], (ack: any) => {
			console.log('Socket authenticated for ', ack);
		});
		// this.socket.emit('client-test', [roomId], [venueId], (ack: any) => {
		// 	console.log('Socket authenticated for ', ack);
		// });
		this.socket.onAny(data => {
			console.log('Order Listening', data);
			// observer.next(data);
		});
		// this.joinRoom(orderId);
		this.socket.on('order', (data, result) => {
			// if(data?.order && data.type === '')
			// observer.next(data);
			if (result?.order) {
				this.verifiedOrder = result.order;
			}
			console.log('CHANGE ORDER', result);
		});
		this.socket.on(roomId, data => {
			console.log('Order Listening', data);
			// observer.next(data);
		});
		this.socket.on('live-tracking', async (data, value) => {
			console.log('LIVE TRACK', data, value);
			if (!this.map) {
				this.initMap(value.location);
			}
			await sleep(1000);
			if (value?.location?.driverLocation) {
				if (this.map) {
					this.updateDriverLocation(value?.location?.driverLocation, value?.location?.vehicleProfile ?? '');
				} else {
					setTimeout(() => {
						this.updateDriverLocation(value?.location?.driverLocation, value?.location?.vehicleProfile ?? '');
					}, 1000);
				}
			}
			if (value?.location?.estimatedDeliveryAt) {
				this.estimatedNewTime = value?.location?.estimatedDeliveryAt;
			}
			if (value?.location?.geometry) {
				this.updatePolyline(value?.location?.geometry);
			}
			// observer.next(data);
		});
		this.socket.on(test, data => {
			console.log('test', data);
		});
	}
	private updatePolyline(dispatchGeometry: string) {
		// Decode the new geometry
		const routeCoordinates = polyline.decode(dispatchGeometry);

		// Convert the coordinates to LatLng objects
		const routeLatLngs = routeCoordinates.map(coord => L.latLng(coord[0], coord[1]));
		console.log(routeLatLngs);

		// Clear the existing polyline from the map
		console.log('CURR AFTER BEFORE', this.currentPolyline);
		if (this.currentPolyline) {
			this.map.removeLayer(this.currentPolyline);
			this.currentPolyline = null;
		}
		console.log('CURR AFTER BEFORE', this.map);

		// Add the new route to the map

		this.currentPolyline = L.polyline(routeLatLngs, { color: 'blue' }).addTo(this.map);
		console.log('CURR AFTER', this.currentPolyline);

		// Fit the map bounds to the new route
		this.map.fitBounds(this.currentPolyline.getBounds());
	}
	onLiveTracking(): Observable<any> {
		return new Observable(observer => {
			this.socket.on('live-tracking', data => {
				console.log('LIVE TRACK', data);
				observer.next(data);
			});

			// Clean up the event listener when the observable is unsubscribed
			return () => this.socket.off('live-tracking');
		});
	}
	startDrawDispatch(dispatch: SmoothrDispatchLocation) {
		this.initMap(dispatch);
	}
	mapTime(time: any, isAsap: boolean = false) {
		if (!time) {
			return '';
		}
		if (isAsap && time) {
			return moment(time).format('HH:mm') + ' - ' + moment(time).add(20, 'minute').format('HH:mm');
		}
		return moment(time).format('HH : mm');
	}
}
