import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import Article from 'src/smoothr-web-app-core/models/Article';
import ArticleGroup from 'src/smoothr-web-app-core/models/ArticleGroup';
import ArticleOption from 'src/smoothr-web-app-core/models/ArticleOption';
import OptionGroup from 'src/smoothr-web-app-core/models/OptionGroup';
import Order from 'src/smoothr-web-app-core/models/Order';
import { filterMatchingOptions } from 'src/smoothr-web-app-core/utils/order-utils';
import { sleep } from 'src/smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-show-remove-ingrediants-dialog',
	templateUrl: './show-remove-ingrediants-dialog.component.html',
	styleUrls: ['./show-remove-ingrediants-dialog.component.scss'],
})
export class ShowRemoveIngrediantsDialogComponent implements OnInit {
	public optionGroups: OptionGroup[] = [];
	public order: Order;
	public articleGroup: ArticleGroup;
	public listOfArticleOptions: ArticleOption[] = [];
	filterMatchingOptions = filterMatchingOptions;
	constructor(private modalCtrl: ModalController) {}
	static async show(
		modalCtrl: ModalController,
		optionGroups: OptionGroup[],
		articleGroup: ArticleGroup,
		order: Order
	): Promise<{ data: ArticleOption[] }> {
		const modal = await modalCtrl.create({
			cssClass: 'eight_height',
			component: ShowRemoveIngrediantsDialogComponent,
			componentProps: {
				optionGroups,
				order,
				articleGroup,
			},
			showBackdrop: true,
			backdropDismiss: true,
		});
		await modal.present();
		const result = (await modal.onDidDismiss()).data;
		await sleep(200);
		return result;
	}

	ngOnInit() {
		if (this.articleGroup && this.articleGroup?.groups?.length > 0) {
			const mapCollection = new Map(this.articleGroup.groups.map(it => [it.group, it]));
			if (this.optionGroups?.length > 0) {
				this.optionGroups.forEach(it => {
					if (mapCollection.has(it._id)) {
						this.addOption(mapCollection.get(it._id), it);
					}
				});
			}
		}
	}
	addOption(event: ArticleOption, optionGroup: OptionGroup) {
		if (this.listOfArticleOptions.find(it => it.article._id === event.article._id)) {
			this.listOfArticleOptions = this.listOfArticleOptions.filter(it => it.article._id !== event.article._id);
		} else {
			if (optionGroup.limit === 1) {
				const findArticleOption = this.listOfArticleOptions.find(it => it.group === optionGroup._id);
				if (!findArticleOption) {
					this.listOfArticleOptions.push(event);
				} else {
					this.listOfArticleOptions = this.listOfArticleOptions.filter(it => it.article._id !== findArticleOption.article._id);
					this.listOfArticleOptions.push(event);
				}
			} else {
				this.listOfArticleOptions.push(event);
			}
		}
	}
	select() {
		this.modalCtrl.dismiss({
			data: this.listOfArticleOptions,
		});
	}
	close() {
		this.modalCtrl.dismiss();
	}
}
