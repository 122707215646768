import { Component, Input, OnInit } from '@angular/core';
import { BrazeService } from 'src/app/services/braze.service';
import WebContentCardBraze from 'src/smoothr-web-app-core/models/WebContentCardsBraze';

@Component({
	selector: 'app-draw-news-braze-item',
	templateUrl: './draw-news-braze-item.component.html',
	styleUrls: ['./draw-news-braze-item.component.scss'],
})
export class DrawNewsBrazeItemComponent implements OnInit {
	@Input() card: WebContentCardBraze;
	cardType = WebContentCardBraze;
	constructor(private brazeService: BrazeService) {}

	ngOnInit() {}
	openUrl(item: WebContentCardBraze) {
		this.brazeService.onCardClick(item);
		if (item?.url) {
			window.open(item.url, '_blank');
		}
	}
}
