import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Api } from 'src/smoothr-web-app-core/api/api';
import Customer from 'src/smoothr-web-app-core/models/Customer';
import { sleep } from 'src/smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-enter-personal-promo-code',
	templateUrl: './enter-personal-promo-code.component.html',
	styleUrls: ['./enter-personal-promo-code.component.scss'],
})
export class EnterPersonalPromoCodeComponent implements OnInit {
	public inputControl = new FormControl('');
	public isLoading = false;
	public customer: Customer;
	constructor(private modalCtrl: ModalController) {}
	static async show(modalCtrl: ModalController, customer: Customer): Promise<any> {
		const modal = await modalCtrl.create({
			component: EnterPersonalPromoCodeComponent,
			cssClass: 'auto-height',
			componentProps: {
				customer,
			},
		});
		await modal.present();
		const result = await modal.onDidDismiss();
		await sleep(100);
		return result.data;
	}
	ngOnInit() {}
	checkCode() {}
	async addPromo() {
		if (this.isLoading || !this.customer) {
			return;
		}
		this.isLoading = true;
		if (!this.inputControl.value) {
			this.setErrorForm();
			return;
		}
		try {
			const promoCode = (
				await Api.getPromoCode({
					code: this.inputControl.value,
					venue: '6734cca6ee8d1b5601172725',
				})
			).data;
			promoCode.mov = +promoCode.mov;
			const result = await Api.addPersonPromo({
				customer: this.customer._id,
				promoCode: promoCode._id,
			});
			console.log(result);
		} catch (e) {
			this.isLoading = false;
		}
	}
	private setErrorForm() {
		this.inputControl.setErrors({
			notExist: true,
		});
	}
	public dismiss() {
		this.modalCtrl.dismiss();
	}
}
