// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolbar-row {
  background: var(--ion-color-primary);
  color: white;
}
.toolbar-row ion-button {
  --background: transparent;
}

.title-text {
  font-size: 18px;
  color: var(--ion-color-secondary);
}

.header-block {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-block h3 {
  font-size: 16px;
}

ion-header {
  --box-shadow: none !important;
  box-shadow: 0px 4px 45px 0px rgba(0, 0, 0, 0.0784313725) !important;
}

.container {
  width: 100%;
}
@media (min-width: 991px) {
  .container {
    max-width: 50%;
    margin: 0 auto;
  }
}

swiper-container {
  --swiper-pagination-bottom: -3px !important;
  --swiper-pagination-color: var(--ion-color-secondary);
}
swiper-container swiper-slide {
  width: 100%;
  max-width: 100% !important;
  margin: 0px 0px 20px;
  padding: 20px;
}

ion-footer {
  padding: 10px;
  box-shadow: none;
  display: flex;
  justify-content: center;
}
ion-footer ion-button {
  width: 100%;
}
@media (min-width: 991px) {
  ion-footer ion-button {
    width: 50%;
    margin: 0 auto;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/pages/my-orders/my-orders.page.scss","webpack://./src/theme/mixins.scss"],"names":[],"mappings":"AAEA;EAIE,oCAAA;EACA,YAAA;AAJF;AAAE;EACE,yBAAA;AAEJ;;AAGA;EACE,eAAA;EACA,iCAAA;AAAF;;AAGA;EACE,aAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AAAF;AACA;EACE,eAAA;AACF;;AAEA;EACE,6BAAA;EACF,mEAAA;AACA;;AACA;EACE,WAAA;AAEF;AC7BE;ED0BF;IAGI,cAAA;IACA,cAAA;EAIF;AACF;;AAFA;EAGE,2CAAA;EACA,qDAAA;AAGF;AAFE;EACE,WAAA;EACA,0BAAA;EACA,oBAAA;EACA,aAAA;AAIJ;;AADA;EACE,aAAA;EACA,gBAAA;EAQA,aAAA;EACA,uBAAA;AAHF;AALE;EACE,WAAA;AAOJ;ACxDE;EDgDA;IAGI,UAAA;IACA,cAAA;EASJ;AACF","sourcesContent":["@import '../../../theme/mixins';\n\n.toolbar-row {\n  ion-button {\n    --background: transparent;\n  }\n  background: var(--ion-color-primary);\n  color: white;\n}\n.title-text {\n  font-size: 18px;\n  color: var(--ion-color-secondary);\n}\n\n.header-block {\n  padding: 20px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\nh3 {\n  font-size: 16px;\n}\n}\nion-header{\n  --box-shadow: none !important;\nbox-shadow: 0px 4px 45px 0px #00000014 !important\n}\n.container {\n  width: 100%;\n  @include desktop {\n    max-width: 50%;\n    margin: 0 auto;\n  }\n}\nswiper-container {\n\n\n  --swiper-pagination-bottom: -3px !important;\n  --swiper-pagination-color: var(--ion-color-secondary);\n  swiper-slide {\n    width: 100%;\n    max-width: 100% !important;\n    margin: 0px 0px 20px;\n    padding: 20px;\n  }\n}\nion-footer {\n  padding: 10px;\n  box-shadow: none;\n  ion-button {\n    width: 100%;\n    @include desktop {\n      width: 50%;\n      margin: 0 auto;\n    }\n  }\n  display: flex;\n  justify-content: center;\n}","@mixin desktop {\n  @media (min-width: 991px) {\n    @content;\n  }\n}\n\n@mixin mobile {\n  @media (max-width: 992px) {\n    @content\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
