import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Api } from 'src/smoothr-web-app-core/api/api';
import { Company } from 'src/smoothr-web-app-core/models/CompanyCode';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import { sleep } from 'src/smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-enter-foodspot-code-modal',
	templateUrl: './enter-foodspot-code-modal.component.html',
	styleUrls: ['./enter-foodspot-code-modal.component.scss'],
})
export class EnterFoodspotCodeModalComponent implements OnInit {
	public inputControl = new FormControl('');
	public loading = false;
	constructor(
		public translate: TranslateService,
		private snackCtrl: MatSnackBar,
		private modalCtrl: ModalController
	) {}
	static async show(modalCtrl: ModalController): Promise<Company> {
		const modal = await modalCtrl.create({
			component: EnterFoodspotCodeModalComponent,
			cssClass: 'delivery-modal',
			componentProps: {},
		});
		await modal.present();
		const result = await modal.onDidDismiss();
		await sleep(100);
		return result.data;
	}
	ngOnInit() {
		this.inputControl.valueChanges.subscribe(v => {
			console.log(v);
		});
	}
	close() {
		this.modalCtrl.dismiss();
	}
	async validate() {
		if (!this.inputControl.value) {
			return;
		}
		try {
			this.loading = true;
			const code = (await Api.validateCompanyCode(this.inputControl.value)).data;
			this.loading = false;
			this.modalCtrl.dismiss(code);
		} catch (e) {
			this.loading = false;

			this.inputControl.setErrors({
				notExist: true,
			});
			this.snackCtrl.open('Sorry, but this company code is not exist...', null, {
				duration: 4000,
			});
		}
	}
}
