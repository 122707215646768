import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'zone.js';

import { AppModule } from './app/app.module';

import { environment } from './environments/environment';

if (environment.production) {
	enableProdMode();
}
platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch(err => console.error(err));
if ('serviceWorker' in navigator) {
	navigator.serviceWorker
		.register('/service-worker.js')
		.then(registration => {
			console.log('Service Worker registered with scope:', registration.scope);
		})
		.catch(error => {
			console.error('Service Worker registration failed:', error);
		});
}
