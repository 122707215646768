import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { AnalyticsService } from '../smoothr-web-app-core/services/analytics/analytics.service';
import { Api } from '../smoothr-web-app-core/api/api';
import { NavigationService } from './services/navigation.service';
import { register } from 'swiper/element/bundle';
import { BrazeService } from './services/braze.service';
import { RepositoryService } from 'src/smoothr-web-app-core/services/repository/repository.service';
import { CookieConsentService } from './services/cookie.service';

register();

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
	static largeScreen = false;
	static analyticsEnabled = false;
	isCookieAccepted = false;
	static isWebApp = true;
	static isNativeApp = false;
	constructor(
		private router: Router,
		private analytics: AnalyticsService,
		platform: Platform,

		private navigationService: NavigationService,
		private brazeService: BrazeService,
		private cookieService: CookieConsentService,
		public translate: TranslateService
	) {
		console.log(environment.customerGroup + ' ' + environment.version);
		const favIcon: any = document.querySelector("link[rel*='icon']") || document.createElement('link');
		favIcon.type = 'image/x-icon';
		favIcon.rel = 'shortcut icon';
		favIcon.href = '/assets/dean_and_david/logo.png';
		document.getElementsByTagName('head')[0].appendChild(favIcon);
		document.title = translate.instant('index.title.' + environment.customerGroup);

		this.handleCookieConsent();
		const languageValue = localStorage.getItem('langOfApp');
		console.log(languageValue);
		if (languageValue) {
			translate.setDefaultLang(languageValue);
			translate.use(languageValue);
		} else {
			translate.setDefaultLang('de');
			translate.use('de');
		}

		translate.onLangChange.subscribe(languageChanged => {
			if (languageChanged) localStorage.setItem('langOfApp', languageChanged.lang);
		});
		platform.ready().then(() => {
			AppComponent.largeScreen = platform.width() >= 992;
			platform.resize.subscribe(() => {
				AppComponent.largeScreen = platform.width() >= 992;
			});
		});
		Api.isMaintenanceActive()
			.then(result => {
				if (result) {
					this.navigationService.maintenance();
				}
			})
			.catch(error => {
				console.log(error);
			});
	}
	private disableSdkBraze() {
		this.brazeService.disableSdk();
	}

	ngOnInit() {}

	ngDoCheck() {}

	private handleCookieConsent() {
		try {
			this.cookieService.initializeCookieConsent();
		} catch (e) {}
	}

	async setAnalyticsEnabled(enabled: boolean) {
		await this.analytics.setAnalyticsCollectionEnabled(enabled);
	}

	openPage(value: string) {
		if (!value) {
			return;
		}
		this.router.navigate([value]);
	}
}
