import axios, { AxiosPromise } from 'axios';
import Venue from '../models/Venue';
import Order from '../models/Order';
import Payment from '../models/Payment';
import Customer from '../models/Customer';
import MyOrdersResponse from '../models/MyOrdersResponse';
import Slot from '../models/Slot';
import ArticleCategory from '../models/ArticleCategory';
import OptionGroup from '../models/OptionGroup';

import { customerBody, orderBody, paymentBody } from '../models/Converter';
import PromoCode from '../models/PromoCode';
import { Information } from '../models/Information';
import { AuthLoginResponse } from '../models/AuthLoginResponse';
import { Legal } from '../models/Legal';
import { AuthStrategy } from '../models/AuthStrategy';
import { CustomerAuth } from '../models/CustomerAuth';
import { EventEmitter } from '@angular/core';
import { environment } from '../../environments/environment';
import { NominatimPlace } from '../models/NominatimPlace';
import CheckoutRecommendation from '../models/CheckoutRecommendation';
import { sanitizeId } from '../utils/utils';
import moment, { Moment } from 'moment';
import Table from '../models/Table';
import ValidateVoucherRequest from '../models/ValidateVoucherRequest';
import { OrderType } from '../enums/OrderType';
import { Company } from '../models/CompanyCode';
import { SearchCity } from '../models/SearchCity';
import UberQoateParams from '../models/UberQoateParams';
import UberQoateResponse from '../models/UberQoateResponse';

const ORDER_SERVICE = '/v2/order/';
const CUSTOMER_SERVICE = '/v1/customer/';
const GENERAL_SERVICE = '/v1/general/';
const PAYMENT_SERVICE = '/v3/payment/';
const AUTH_SERVICE = '/v1/auth/';
const WALLET_SERVICE = '/v1/wallet';
const DND_SERVICE = '/v1/dnd/';
const CITY_SERVICE = '/v1/city/';

const VENUE = 'venue/';
const LOGIN = 'login/';

const LOGIN_ENDPOINT = AUTH_SERVICE + LOGIN;
const CUSTOMER_ENDPOINT = CUSTOMER_SERVICE;
const VENUE_ENDPOINT = GENERAL_SERVICE + VENUE;

const ORDER_ENDPOINT = ORDER_SERVICE;
const TEST_ORDER_ENDPOINT = ORDER_SERVICE + 'test/';
const SLOT_ENDPOINT = ORDER_ENDPOINT + 'slot/';
const CUSTOMER_LOGIN_ENDPOINT = CUSTOMER_ENDPOINT + 'auth/login';
const CUSTOMER_LOGOUT_ENDPOINT = CUSTOMER_ENDPOINT + 'auth/logout';
const CUSTOMER_REGISTER_ENDPOINT = CUSTOMER_ENDPOINT + 'auth/register';
const CUSTOMER_VERIFY_EMAIL_ENDPOINT = CUSTOMER_ENDPOINT + 'auth/verify/email';
const CUSTOMER_SEND_EMAIL_VERIFICATION_ENDPOINT = CUSTOMER_ENDPOINT + 'auth/verify/resend';
const CUSTOMER_ONE_TIME_LOGIN_ENDPOINT = CUSTOMER_ENDPOINT + 'auth/token/code';
const CUSTOMER_REFRESH_AUTH_TOKEN_ENDPOINT = CUSTOMER_ENDPOINT + 'auth/token/refresh';
const CUSTOMER_FORGOT_PASSWORD_ENDPOINT = CUSTOMER_ENDPOINT + 'auth/password/forgot/request';
const CUSTOMER_RESET_PASSWORD_ENDPOINT = CUSTOMER_ENDPOINT + 'auth/password/forgot/reset';
const CUSTOMER_DELETE_ACCOUNT_ENDPOINT = CUSTOMER_ENDPOINT;
const MAINTENANCE_ENDPOINT = GENERAL_SERVICE + 'maintenance/status';
const CANCEL_COMMAND = '/cancel/customer';
const PAYMENT_CVV = '/v2/payment/ccv/create/page/';
const UBER_SERVICE = '/v1/uber/';

const authHeader = 'Authorization';

class ApiService {
	axios = axios.create();

	onTokenRefreshed = new EventEmitter<CustomerAuth>();
	authToken: string;
	refreshToken: string;

	constructor() {
		this.axios.defaults.baseURL = environment.baseUrl;
		this.axios.interceptors.request.use(
			async config => {
				if (config.url === TEST_ORDER_ENDPOINT) {
					return config;
				}
				if (this.authToken) {
					config.headers[authHeader] = 'Bearer ' + this.authToken;
				}
				return config;
			},
			error => Promise.reject(error)
		);
		this.axios.interceptors.response.use(
			response => response,
			error => {
				const originalRequest = error.config;
				if (error.response.status === 401) {
					if (
						originalRequest.url === TEST_ORDER_ENDPOINT ||
						originalRequest.url === CUSTOMER_LOGOUT_ENDPOINT ||
						originalRequest.url === CUSTOMER_LOGIN_ENDPOINT
					) {
						return Promise.reject(error);
					}
					if (originalRequest.url === CUSTOMER_REFRESH_AUTH_TOKEN_ENDPOINT) {
						console.error('auth tokes could not be reissued');
						this.onTokenRefreshed.emit(null);
						return Promise.reject(error);
					} else if (!originalRequest._retry && this.onTokenRefreshed && this.refreshToken) {
						console.log('refreshing auth token with refreshToken');
						originalRequest._retry = true;
						return this.refreshAuthToken()
							.then(res => this.onTokenRefreshed.emit(res.data))
							.catch(_ => this.onTokenRefreshed.emit(null));
					}
					this.onTokenRefreshed.emit(null);
				}
				return Promise.reject(error);
			}
		);
	}

	public authLogin(authLogin: { email: string; password: string }): AxiosPromise<AuthLoginResponse> {
		return this.axios.post(LOGIN_ENDPOINT, authLogin);
	}

	public createOrder(order: Order, keepPreorder = false): AxiosPromise<Order> {
		return this.axios.post(ORDER_ENDPOINT, orderBody(order, keepPreorder));
	}

	public createTestOrder(authToken: string, order: Order): AxiosPromise<{ order: Order; payment: string }> {
		if (environment.flavor === 'prodRelease') {
			order.flavor = 'prodDebug';
		}
		return this.axios.post(TEST_ORDER_ENDPOINT + order.venue, orderBody(order), {
			headers: {
				Authorization: 'Bearer ' + authToken,
			},
		});
	}

	public getAllVenues(customerGroup: string): AxiosPromise<Venue[]> {
		let country = '';
		if (environment.location) {
			country = '&country=' + environment.location;
		}
		return this.axios.get(VENUE_ENDPOINT + '?customerGroup=' + customerGroup + country);
	}

	public getVenues(customerGroup: string, lat: number, lng: number, distance: number, articles: boolean): AxiosPromise<Venue[]> {
		return this.axios.get(
			VENUE_ENDPOINT + `?lat=${lat}&lng=${lng}&distance=${distance}&articles=${articles}&customerGroup=${customerGroup}`
		);
	}

	public getVenuesBy(postalCode: string, customerGroup: string): AxiosPromise<Venue[]> {
		return this.axios.get(VENUE_ENDPOINT + '?deliveryPostalCode=' + postalCode + '&customerGroup=' + customerGroup);
	}
	public getLazyVenue(venueId: string, isIncludeCustomerGroup: boolean = false): AxiosPromise<Venue> {
		return this.axios.get(VENUE_ENDPOINT + sanitizeId(venueId, isIncludeCustomerGroup));
	}
	public getVenueBySlug(slug: string, isIncludeCustomerGroup: boolean = false): AxiosPromise<Venue> {
		return this.axios.get(VENUE_ENDPOINT + '/byslug/' + sanitizeId(slug, isIncludeCustomerGroup));
	}

	public getCategoriesWithArticles(venue: string, articles: boolean): AxiosPromise<ArticleCategory[]> {
		return this.axios.get(GENERAL_SERVICE + `articlecategory/?venue=${venue}&articles=${articles}`);
	}

	public getOptionGroupsByVenue(venue: string): AxiosPromise<OptionGroup[]> {
		return this.axios.get(GENERAL_SERVICE + 'articleoption/byvenue/' + venue);
	}

	public getCustomerOrders(userUid: string): AxiosPromise<MyOrdersResponse> {
		return this.axios.get(PAYMENT_SERVICE + 'customer/' + sanitizeId(userUid));
	}

	public async createPayment(payment: Payment): Promise<Payment> {
		payment._id = undefined;
		return (await this.axios.post(PAYMENT_SERVICE, paymentBody(payment))).data;
	}

	public async finishPaypalPayment(paypalOrderId: string): Promise<Payment> {
		return (await this.axios.patch(PAYMENT_SERVICE + paypalOrderId + '/paypal/pay')).data;
	}

	public getSlots(venue: string, from: string, to: string): AxiosPromise<Slot[]> {
		return this.axios.get(SLOT_ENDPOINT + venue + '?from=' + from + '&to=' + to);
	}

	public getOrder(order: string): AxiosPromise<Order> {
		return this.axios.get(ORDER_ENDPOINT + order);
	}

	// Customer

	public getCustomer(): AxiosPromise<Customer> {
		return this.axios.get(CUSTOMER_ENDPOINT);
	}

	public patchCustomer(customer: Customer): AxiosPromise<Customer> {
		customer._id = undefined;
		return this.axios.patch(CUSTOMER_ENDPOINT, customerBody(customer));
	}

	public signIn(strategy: AuthStrategy, isMobile = false): AxiosPromise<{ url: string; isRedirect: boolean }> {
		return this.axios.post(CUSTOMER_LOGIN_ENDPOINT, {
			strategy,
			customerGroup: environment.customerGroup,
			isMobile: isMobile,
		});
	}

	public signInAnonymous(uid: string): AxiosPromise<CustomerAuth> {
		return this.axios.post(CUSTOMER_LOGIN_ENDPOINT, {
			strategy: AuthStrategy.ANONYMOUS,
			uid: uid ? uid : undefined,
			customerGroup: environment.customerGroup,
		});
	}

	public signInWithCredentials(email: string, password: string): AxiosPromise<CustomerAuth> {
		return this.axios.post(CUSTOMER_LOGIN_ENDPOINT, {
			strategy: AuthStrategy.CREDENTIALS,
			customerGroup: environment.customerGroup,
			email,
			password,
		});
	}

	public signUpWithCredentials(data?: Partial<Customer>, password?: string): AxiosPromise<Customer> {
		return this.axios.post(CUSTOMER_REGISTER_ENDPOINT, {
			...data,
			strategy: AuthStrategy.CREDENTIALS,
			customerGroup: environment.customerGroup,
			preferredVenues: data.preferredVenues ?? [],
			password,
		});
	}

	public verifyEmail(token: string): AxiosPromise<CustomerAuth> {
		return this.axios.post(CUSTOMER_VERIFY_EMAIL_ENDPOINT, {
			token,
		});
	}

	public forgotPassword(email: string): AxiosPromise {
		return this.axios.post(CUSTOMER_FORGOT_PASSWORD_ENDPOINT, {
			email,
			customerGroup: environment.customerGroup,
		});
	}

	public oneTimeLogin(id: string, authCode: string): AxiosPromise<CustomerAuth> {
		return this.axios.post(CUSTOMER_ONE_TIME_LOGIN_ENDPOINT, {
			id,
			authCode,
		});
	}

	public logout(): AxiosPromise {
		return this.axios.post(CUSTOMER_LOGOUT_ENDPOINT);
	}

	public resetPassword(token: string, password: string): AxiosPromise {
		return this.axios.post(CUSTOMER_RESET_PASSWORD_ENDPOINT, {
			token,
			password,
		});
	}

	public refreshAuthToken(): AxiosPromise<CustomerAuth> {
		return this.axios.post(CUSTOMER_REFRESH_AUTH_TOKEN_ENDPOINT, {
			refreshToken: this.refreshToken,
		});
	}

	public sendEmailVerification(email: string, isMobile = false): AxiosPromise {
		return this.axios.post(CUSTOMER_SEND_EMAIL_VERIFICATION_ENDPOINT, {
			email,
			customerGroup: environment.customerGroup,
			isMobile: isMobile,
		});
	}
	public deleteAccount(userId: string): AxiosPromise {
		return this.axios.delete(CUSTOMER_DELETE_ACCOUNT_ENDPOINT + userId);
	}
	public applyPromoCode(order: Order): AxiosPromise<Order> {
		return this.axios.post(ORDER_ENDPOINT + 'promoCode', orderBody(order));
	}

	public getPromoCode(body: { code: string; venue: string }): AxiosPromise<PromoCode> {
		return this.axios.post(GENERAL_SERVICE + 'promoCode/code', body);
	}
	public getPromoCodeById(id: string): AxiosPromise<PromoCode> {
		return this.axios.get(GENERAL_SERVICE + 'promoCode/' + id);
	}

	public isMaintenanceActive(): Promise<boolean> {
		return new Promise<boolean>(async (resolve, reject) => {
			try {
				resolve((await this.axios.get(MAINTENANCE_ENDPOINT)).data);
			} catch (e) {
				reject(e);
			}
		});
	}

	public getInformations(): AxiosPromise<Information[]> {
		return this.axios.post(GENERAL_SERVICE + 'information/url', {
			url: window.location.hostname,
		});
	}

	public getOrderByCodeAndDate(venue: string, code: string, slot: string): AxiosPromise<Order> {
		return this.axios.get(ORDER_ENDPOINT + 'venue/' + venue + '/code/' + code + '?slot=' + slot);
	}

	public preponeSlot(order: string): AxiosPromise<Order> {
		return this.axios.patch(SLOT_ENDPOINT + order);
	}

	public getPayment(payment: string): AxiosPromise<Payment> {
		return this.axios.get(PAYMENT_SERVICE + 'byId/' + payment);
	}

	public changeSlot(order: Order, time: Moment): AxiosPromise<Order> {
		return this.axios.patch(SLOT_ENDPOINT + order._id + '?time=' + time.toISOString());
	}

	public getLegal(venue: string): AxiosPromise<Legal> {
		return this.axios.get(VENUE_ENDPOINT + venue + '/legal');
	}

	public cancelOrder(order: string): AxiosPromise {
		return this.axios.patch(ORDER_ENDPOINT + order + CANCEL_COMMAND);
	}

	public getCartRecommendations(body: {
		venue: string;
		articles: { _id: string; quantity: number }[];
		orderType: OrderType;
		subOrderType: string;
	}): AxiosPromise<CheckoutRecommendation[]> {
		return this.axios.post(GENERAL_SERVICE + 'cartrecommendation/result', body);
	}

	public mapSearch(query: string, limit: number): AxiosPromise<NominatimPlace[]> {
		const countryCodes = environment.countryList.reduce((previousValue, currentValue) => previousValue + ',' + currentValue);
		return this.axios.get(`/v1/maps/search?limit=${limit}&format=jsonv2&addressdetails=1&q=${query}&countrycodes=${countryCodes}`);
	}

	public geocode(lat: number, lng: number): AxiosPromise<NominatimPlace> {
		return this.axios.get(`/v1/maps/reverse?limit=5&format=jsonv2&addressdetails=1&lat=${lat}&lon=${lng}`);
	}

	public getTable(venue: string, tableNumber: string): AxiosPromise<Table> {
		return this.axios.get(VENUE_ENDPOINT + venue + '/table/' + tableNumber);
	}

	public getOrdersByTable(venue: string, table: string) {
		const yesterday = moment().add(-1, 'days').toISOString();
		return this.axios.get(`${ORDER_ENDPOINT}filtered?tables[]=${table}&venue[]=${venue}&from=${yesterday}`);
	}

	public getTablesByVenue(venue: string): AxiosPromise<Table[]> {
		return this.axios.get(`${GENERAL_SERVICE}venue/${venue}/tables`);
	}
	public async addFavouritesVenueToUser(userId: string, favVenues: string[]) {
		return (
			await this.axios.patch(`v1/customer/${userId}/favorite-venues/put`, {
				favVenues,
			})
		).data;
	}
	public async removeFavouritesVenueToUser(userId: string, favVenues: string[]) {
		return (
			await this.axios.patch(`v1/customer/${userId}/favorite-venues/pull`, {
				favVenues,
			})
		).data;
	}
	public async createCCVPayment(venueId: string, payment: Payment): Promise<Payment> {
		payment._id = undefined;
		return (await this.axios.post(PAYMENT_CVV + `${venueId}`, paymentBody(payment))).data;
	}
	public checkSanifairCoupon(venue: Venue, order: Order, sanitazidCode: string) {
		console.log(`reserveSanifair(order: ${order._id}, code: ${sanitazidCode}`);
		// return this.axios.get()
	}
	public async reserveSanifairVoucher(orderId: string, validateVoucherRequest: ValidateVoucherRequest): Promise<Order> {
		return (
			await this.axios.patch(ORDER_ENDPOINT + orderId + '/sanifair/voucher/reserve', {
				...validateVoucherRequest,
			})
		).data;
	}
	public async extendSanifairVoucher(orderId: string): Promise<Order> {
		return (await this.axios.patch(ORDER_ENDPOINT + orderId + '/sanifair/voucher/extend')).data;
	}
	public async invalidateOrder(orderId: string) {
		return await this.axios.patch(ORDER_ENDPOINT + orderId + '/failed');
	}
	public async updateOrder(order: Order) {
		return await this.axios.patch(ORDER_ENDPOINT + order._id, order);
	}
	public async validateCompanyCode(code: string): AxiosPromise<Company> {
		return await this.axios.get(DND_SERVICE + 'company/company-code/' + code);
	}
	public async getCompanyById(companyId: string): AxiosPromise<Company> {
		return await this.axios.get(DND_SERVICE + 'company/short/' + companyId);
	}
	public async verifyUserByCode(code: string, userId: string): Promise<CustomerAuth> {
		return (
			await this.axios.post(`v1/customer/auth/verify/email-by-code/${userId}`, {
				code,
			})
		).data;
	}
	public async resetPasswordSendCode(code: string, email: string): Promise<{ passwordResetToken: string }> {
		return (
			await this.axios.post(`v1/customer/auth/password/by-code/token`, {
				code,
				email,
			})
		).data;
	}
	public async getListOfVenues(city: string) {
		return this.axios.get(VENUE_ENDPOINT + '?customerGroup=' + environment.customerGroup + '&city=' + city);
	}
	public async activateAndDeactivateDeliveryTracking(orderId: string, enable: string) {
		return this.axios.get(ORDER_SERVICE + orderId + '/live-tracking/' + enable);
	}
	public async calculateTaxes(order: Order): AxiosPromise<Order> {
		return this.axios.post(ORDER_ENDPOINT + 'taxes/estimation', orderBody(order));
	}
	public async createWorldLinePayment(data: any, venueId: string): Promise<Payment> {
		return (await this.axios.post(PAYMENT_SERVICE + 'worldline/page/' + venueId, data)).data;
	}
	public async loadCitiesFromService(search: string) {
		return (await this.axios.get<SearchCity[]>(CITY_SERVICE + 'search?search=' + search)).data;
	}
	public getPromoCodeByLinkedCustomerGroup(code: string): AxiosPromise<PromoCode> {
		return this.axios.post(GENERAL_SERVICE + 'promoCode/code/byLinkedCustomerGroup', {
			code: code,
			linkedCustomerGroup: environment.customerGroup,
		});
	}
	public getAllPromoCodesNotLinkedToUser(): AxiosPromise<PromoCode[]> {
		return this.axios.post(GENERAL_SERVICE + 'promoCode/code/byLinkedCustomerGroup', {
			linkedCustomerGroup: environment.customerGroup,
		});
	}
	public async addPersonPromo(data: { customer: string; promoCode: string }) {
		return (await this.axios.patch(CUSTOMER_SERVICE + 'personal-promocodes/add', data)).data;
	}
	public async deletePersonalPromo(data: { customer: string; promoCode: string }) {
		return (await this.axios.patch(CUSTOMER_SERVICE + 'personal-promocodes/remove', data)).data;
	}
	public async getPersonalPromo(customerUid: string) {
		return (await this.axios.get<PromoCode[]>(CUSTOMER_SERVICE + 'personal-promocodes/' + customerUid)).data;
	}
	public async boundPromoCodeToUser(promoId: string, userId: string) {
		return (await this.axios.patch<PromoCode[]>(GENERAL_SERVICE + 'promoCode/' + promoId + '/bind/' + userId)).data;
	}
	public async checkQoate(data: UberQoateParams, uberCustomerId: string) {
		return (await this.axios.post<UberQoateResponse>(`${UBER_SERVICE}` + uberCustomerId + '/quote', data)).data;
	}
}

export let Api = new ApiService();
export { PAYMENT_SERVICE, WALLET_SERVICE };
