import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import PromoCode from 'src/smoothr-web-app-core/models/PromoCode';
import { sleep } from 'src/smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-coupon-item-show-modal',
	templateUrl: './coupon-item-show-modal.component.html',
	styleUrls: ['./coupon-item-show-modal.component.scss'],
})
export class CouponItemShowModalComponent implements OnInit {
	public promoCode: PromoCode;
	constructor(private modalCtrl: ModalController) {}
	static async show(modalCtrl: ModalController, promoCode: PromoCode): Promise<any> {
		const modal = await modalCtrl.create({
			component: CouponItemShowModalComponent,
			cssClass: 'auto-height',
			componentProps: {
				promoCode,
			},
		});
		await modal.present();
		const result = await modal.onDidDismiss();
		await sleep(100);
		return result.data;
	}
	ngOnInit() {}
	close() {
		this.modalCtrl.dismiss();
	}
	async showBarCodeForSOT() {
		try {
		} catch (e) {
			console.log(e);
		}
	}
}
