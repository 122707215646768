import { Component, Input, OnChanges, OnInit, SimpleChanges, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CustomModalWithCountrySelectionComponent } from './custom-modal-with-country-selection/custom-modal-with-country-selection.component';
import { ModalController } from '@ionic/angular';

@Component({
	selector: 'app-input-phone-with-county-code',
	templateUrl: './input-phone-with-county-code.component.html',
	styleUrls: ['./input-phone-with-county-code.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InputPhoneWithCountyCodeComponent),
			multi: true,
		},
	],
})
export class InputPhoneWithCountyCodeComponent implements ControlValueAccessor, OnInit, OnChanges {
	countryCode = '+49';
	flag = 'de';
	@Input() code: string;
	@Input() phone: string;
	@Input() hasBackground: boolean = true;
	onChange: any = () => {};
	onTouched: any = () => {};
	form: FormGroup;

	constructor(
		private fb: FormBuilder,
		private modalCtrl: ModalController
	) {
		this.form = this.fb.group({
			phone: '',
			country: '',
		});
	}

	ngOnInit() {
		this.form.patchValue(
			{
				country: '+49',
			},
			{ emitEvent: true }
		);
		this.form.valueChanges.subscribe(() => {
			this.onChange({
				phone: this.form.get('phone')?.value ?? '',
				country: this.form.get('country')?.value ?? '',
			});
		});
	}
	ngOnChanges(changes: SimpleChanges): void {}
	writeValue(value: { county: string; phone: string }): void {
		this.form.patchValue({
			phone: value?.phone ?? '',
		});
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	async selectCountryCode() {
		const result = await CustomModalWithCountrySelectionComponent.show(this.modalCtrl, this.form.get('country')?.value);
		console.log(result);
		if (result?.dialCode) {
			this.flag = result.flagClass;
			this.form.patchValue(
				{
					country: '+' + result.dialCode,
				},
				{ emitEvent: true }
			);
		}
	}
}
