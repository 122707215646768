import { Injectable, NgZone } from '@angular/core';
import { BrazeService } from './braze.service';
import { AnalyticsService } from 'src/smoothr-web-app-core/services/analytics/analytics.service';

@Injectable({
	providedIn: 'root',
})
export class CookieConsentService {
	constructor(
		private ngZone: NgZone,
		private brazeService: BrazeService,
		private analyticsService: AnalyticsService
	) {}
	public initializeCookieConsent() {
		//todo Turn off
		this.enableMarketing();
		window.addEventListener('CookieConsent', () => {
			console.log('HERE');

			this.ngZone.run(() => this.handleConsent());
		});
		window.addEventListener('CookieConsent.onConsentChanged', () => {
			console.log('HERE');

			this.ngZone.run(() => this.handleConsent());
		});
		window.addEventListener('load', () => {
			console.log('HERE');

			if (window['CookieConsent'] && window['CookieConsent'].consent) {
				this.ngZone.run(() => this.handleConsent());
			}
		});
	}

	private async handleConsent() {
		const consent = window['CookieConsent'].consent;
		if (consent) {
			if (consent.statistics) {
				await this.setAnalyticsEnabled(true);
			} else {
				await this.setAnalyticsEnabled(false);
			}
			if (consent.marketing) {
				this.enableMarketing();
			} else {
				this.disableMarketing();
			}
		}
	}
	private enableMarketing() {
		this.startBrazeInitialize();
	}

	private disableMarketing() {
		this.brazeService.disableSdk();
	}
	private startBrazeInitialize() {
		this.brazeService.initializeService();
		this.brazeService.logCustomEvent('AppInitialized');
		this.brazeService.requestPushPermission();
		this.brazeService.showInAppMessage();
	}
	async setAnalyticsEnabled(enabled: boolean) {
		await this.analyticsService.setAnalyticsCollectionEnabled(enabled);
	}
}
