export enum PreorderType {
	TAKE_AWAY = 'take_away',
	INSIDE = 'inside',
	DELIVERY = 'delivery',
	PARK_COLLECT = 'park_collect',
	FOOD_SPOT = 'foodspot',
}

export const PreorderTypeArray: PreorderType[] = [
	PreorderType.TAKE_AWAY,
	PreorderType.INSIDE,
	PreorderType.DELIVERY,
	PreorderType.PARK_COLLECT,
	PreorderType.FOOD_SPOT,
];
