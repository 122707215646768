import { CommonModule } from '@angular/common';
import {
	AfterViewInit,
	CUSTOM_ELEMENTS_SCHEMA,
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	Output,
	ViewChild,
} from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AllowGpsModalResult } from '../allow-gps-modal/allow-gps-modal.component';
import ArticleCategory from 'src/smoothr-web-app-core/models/ArticleCategory';
import { SwiperOptions } from 'swiper/types';
import { AppComponent } from 'src/app/app.component';

@Component({
	selector: 'app-scrolling-categories',
	standalone: true,
	imports: [CommonModule, IonicModule, TranslateModule],
	templateUrl: './scrolling-categories.component.html',
	styleUrls: ['./scrolling-categories.component.scss'],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScrollingCategoriesComponent implements AfterViewInit {
	categories: ArticleCategory[] = [];
	defaultCategoriesLength = 0;
	@Input() set selectedCategoryIndex(value: number) {
		if (this.swiper) {
			this.goToIndex(value);
		}
	}
	@Input() set articleCategories(value: ArticleCategory[]) {
		console.log('INCOME', value);
		if (value && value.length != this.defaultCategoriesLength) {
			this.defaultCategoriesLength = value.length;
			this.categories = value;
			console.log(this.categories.length);
			this.setupSwiper();
		}
	}
	@Output() outputSelectedCategory = new EventEmitter<number>();
	@ViewChild('swiperCategories') swiper?: ElementRef;
	amountOfSlides = 0;
	public config: SwiperOptions = {
		loop: true,
		speed: 400,
		navigation: true,
		grabCursor: true,
		autoplay: false,
		initialSlide: 1,
		effect: 'coverflow',
		direction: 'horizontal',
		// coverflowEffect: {
		// 	depth: 0,
		// 	rotate: 0,
		// 	scale: 0.9,
		// 	stretch: -2,
		// 	modifier: 1.1,
		// },
	};
	constructor() {
		console.log('innerWidht');
		this.amountOfSlides = window.innerWidth / (AppComponent.largeScreen ? 112 : 80);
	}
	ngAfterViewInit(): void {}
	setupSwiper() {
		setTimeout(() => {
			this.swiper.nativeElement.swiper.loopDestroy();
			this.swiper.nativeElement.swiper.loopCreate();
			this.swiper.nativeElement.swiper.update();
			this.swiper.nativeElement.swiper.slideTo(this.defaultCategoriesLength);
			console.log('ActiveIndex', this.swiper.nativeElement.swiper, this.swiper.nativeElement.swiper.activeIndex);
			// this.swiper.nativeElement.swiper.on('slideChange', v => {
			// 	// console.log(this.categories[v.realIndex].name.de);
			// 	this.outputSelectedCategory.emit(v.realIndex % this.defaultCategoriesLength);
			// });
			this.swiper.nativeElement.swiper.slideToLoop(0);
		}, 2000);
	}
	slideChange(value: any) {
		console.log('Slide change', value);
	}
	goToIndex(index: number) {
		console.log(this.defaultCategoriesLength);
		const result = index % this.defaultCategoriesLength;
		console.log(result);
		this.swiper.nativeElement.swiper.loopDestroy();
		this.swiper.nativeElement.swiper.loopCreate();
		this.swiper.nativeElement.swiper.slideToLoop(index);
		this.swiper.nativeElement.swiper.update();
	}

	selectIndex(index: number) {
		console.log(this.defaultCategoriesLength);
		const result = index % this.defaultCategoriesLength;
		console.log(result);
		this.swiper.nativeElement.swiper.loopDestroy();
		this.swiper.nativeElement.swiper.loopCreate();
		this.swiper.nativeElement.swiper.slideToLoop(index);
		this.swiper.nativeElement.swiper.update();
		this.outputSelectedCategory.emit(index % this.defaultCategoriesLength);
	}
}
