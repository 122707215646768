import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import Customer from 'src/smoothr-web-app-core/models/Customer';
import { SelectLanguagePopoverComponent } from '../select-language-popover/select-language-popover.component';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['header.component.scss'],
})
export class HeaderComponent implements OnInit {
	@Input()
	isBack: boolean = false;
	@Input()
	title: string = '';
	@Input()
	src: string;
	@Output() back = new EventEmitter<void>();
	@Input() customer: Customer;
	public isOpen = false;
	public listCountries = [
		{
			name: 'German',
			icon: '../../../assets/dean_and_david/lang/de.svg',
			value: 'de',
		},
		{
			name: 'English',
			icon: '../../../assets/dean_and_david/lang/gb.svg',
			value: 'en',
		},

		{
			name: 'Italy',
			icon: '../../../assets/dean_and_david/lang/cs.svg',
			value: 'cr',
		},
	];
	constructor(
		private popover: PopoverController,
		public translate: TranslateService,
		private navService: NavigationService
	) {}

	ngOnInit() {}
	goBack() {
		this.back.emit();
	}
	async openPopoverSelectionLanguage(ev: any) {
		if (this.isOpen) {
			return;
		}
		this.isOpen = true;
		const popover = await this.popover.create({
			component: SelectLanguagePopoverComponent,
			event: ev,
			translucent: true,
			cssClass: 'popover-select-language',
			showBackdrop: false,
			componentProps: {
				lang: this.translate.currentLang,
			},
		});
		popover.onWillDismiss().then(v => {
			// this.visible = false;
			this.isOpen = false;
		});
		await popover.present();
	}
	findFlag() {
		return this.listCountries.find(opt => opt.value === this.translate.currentLang).icon;
	}
	login() {
		this.navService.signIn();
	}
}
