import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import Order from 'src/smoothr-web-app-core/models/Order';
import { sleep } from 'src/smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-order-content-modal',
	templateUrl: './order-content-modal.component.html',
	styleUrls: ['./order-content-modal.component.scss'],
})
export class OrderContentModalComponent {
	constructor(private modalCtrl: ModalController) {}

	static async presentModal(modalCtrl: ModalController) {
		console.log(modalCtrl);
		const modal = await modalCtrl.create({
			component: OrderContentModalComponent,
			backdropDismiss: true,
			showBackdrop: true,
			cssClass: 'menu-modal',
		});
		await modal.present();
		await sleep(200);
		return await modal.onDidDismiss();
	}
	dismiss() {
		this.modalCtrl.dismiss();
	}
}
