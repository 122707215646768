// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slot-square {
  background: white;
  text-align: center;
  padding: var(--ion-margin);
  transition: border-top-color 0.2s linear, border-bottom-color 0.2s linear, border-left-color 0.2s linear, border-right-color 0.2s linear, color 0.2s linear, background-color 0.2s linear;
  text-transform: uppercase;
  margin-bottom: 4px;
  cursor: pointer;
  border-radius: 16px;
  border: 1.5px solid var(--ion-color-primary);
  color: var(--ion-color-secondary);
  font-family: AppFontBold, san-serif;
}
.slot-square.selected {
  color: white;
  background: var(--ion-color-primary);
  font-weight: bold;
}

.slot-col {
  max-height: 250px;
  overflow: auto;
}

.header-color {
  color: #B0B0B0;
  line-height: 17px;
  margin: 5px;
  font-size: 14px;
  font-family: OpenSans, sans-serif;
  text-align: center;
}

.no-padding {
  padding: 0px !important;
}

.smaller {
  font-size: 11px;
  padding: 16px 0px;
  text-transform: lowercase;
}`, "",{"version":3,"sources":["webpack://./src/app/components/select-date-formcontrol/select-date-formcontrol.component.scss"],"names":[],"mappings":"AAEA;EACE,iBAAA;EACA,kBAAA;EACA,0BAAA;EACA,yLACQ;EAOR,yBAAA;EACA,kBAAA;EACA,eAAA;EACA,mBAAA;EACA,4CAAA;EACA,iCAAA;EACA,mCAAA;AARF;AASE;EACE,YAAA;EACA,oCAAA;EACA,iBAAA;AAPJ;;AAUA;EACE,iBAAA;EACA,cAAA;AAPF;;AAUA;EACE,cAAA;EACA,iBAAA;EACA,WAAA;EACA,eAAA;EACA,iCAAA;EACA,kBAAA;AAPF;;AASA;EACE,uBAAA;AANF;;AAQA;EACE,eAAA;EACA,iBAAA;EACA,yBAAA;AALF","sourcesContent":["@import \"../../../theme/mixins\";\n\n.slot-square {\n  background: white;\n  text-align: center;\n  padding: var(--ion-margin);\n  transition:\n          border-top-color 0.2s linear,\n          border-bottom-color 0.2s linear,\n          border-left-color 0.2s linear,\n          border-right-color 0.2s linear,\n          color 0.2s linear,\n          background-color 0.2s linear;\n  // border: 0.5px solid #B0B0B0;\n  text-transform: uppercase;\n  margin-bottom: 4px;\n  cursor: pointer;\n  border-radius: 16px;\n  border: 1.5px solid var(--ion-color-primary);\n  color: var(--ion-color-secondary);\n  font-family: AppFontBold,san-serif;\n  &.selected {\n    color: white;\n    background: var(--ion-color-primary);\n    font-weight: bold;\n  }\n}\n.slot-col {\n  max-height: 250px;\n  overflow: auto;\n}\n\n.header-color{\n  color: #B0B0B0;\n  line-height: 17px;\n  margin: 5px;\n  font-size: 14px;\n  font-family: OpenSans, sans-serif;\n  text-align: center;\n}\n.no-padding{\n  padding: 0px !important;\n}\n.smaller{\n  font-size: 11px;\n  padding: 16px 0px;\n  text-transform: lowercase;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
