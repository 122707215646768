import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ShortInfoModalComponent } from '../short-info-modal/short-info-modal.component';
import { RecommendationModalAction, RecommendationModalComponent } from '../recommendation-modal/recommendation-modal.component';
import ArticleGroup from '../../../smoothr-web-app-core/models/ArticleGroup';
import { getAvailability, getPrice, numberToCurrency } from '../../../smoothr-web-app-core/utils/utils';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import { filterMatchingOptions, OrderUtils } from '../../../smoothr-web-app-core/utils/order-utils';
import { ValidationUtils } from '../../../smoothr-web-app-core/utils/validation-utils';
import { DisplayMode } from '../../../smoothr-web-app-core/enums/DisplayMode';
import { DisplayIdentifier } from '../../../smoothr-web-app-core/enums/DisplayIdentifier';
import OptionGroup from '../../../smoothr-web-app-core/models/OptionGroup';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import Article from '../../../smoothr-web-app-core/models/Article';
import ArticleOption from '../../../smoothr-web-app-core/models/ArticleOption';
import FulfilledDependency from '../../../smoothr-web-app-core/models/FulfilledDependency';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import { defaultsToArticleOption, sleep, getBasePrice } from '../../../smoothr-web-app-core/utils/utils';
import { environment } from 'src/environments/environment';
import { PromoCodeType } from '../../../smoothr-web-app-core/models/PromoCodeType';
import { AnalyticsService } from '../../../smoothr-web-app-core/services/analytics/analytics.service';
import { OrderType } from 'src/smoothr-web-app-core/enums/OrderType';
import { shoGif } from '../item-view/item-view.component';
import { ShowRemoveIngrediantsDialogComponent } from '../show-remove-ingrediants-dialog/show-remove-ingrediants-dialog.component';
import { AllergensInfoModalComponent } from '../allergens-info-modal/allergens-info-modal.component';
import { FormControl } from '@angular/forms';

@Component({
	selector: 'app-modal-info',
	templateUrl: './modal-info.component.html',
	styleUrls: ['modal-info.component.scss'],
})
export class ModalInfoComponent extends RepositoryDirective implements OnInit, AfterViewInit {
	environment = environment;
	articleGroup: ArticleGroup;
	// false if coming from checkout
	recommend = true;
	noQuantity = false;

	OrderUtils = OrderUtils;
	validationUtils = ValidationUtils;
	// @ViewChild(IonSlides, { static: true }) slides: IonSlides;
	@ViewChild('swiperInfo') slides: ElementRef | undefined;
	showGif = shoGif.default;
	dm = DisplayMode;
	di = DisplayIdentifier;
	slideOpts = {
		spaceBetween: 0,
		initialSlide: 0,
		speed: 400,
		allowTouchMove: false,
	};
	sliding = false;
	pages: OptionGroup[][] = [];
	currentPageIndex = 0;
	indicesOfRecommendations: number[] = [];
	isLastPage = false;
	numberToCurrency = numberToCurrency;
	getPrice = getPrice;
	getBasePrice = getBasePrice;
	filterMatchingOptions = filterMatchingOptions;
	checkIfButtonFinishedAvailable = false;
	isFavourite: boolean = false;
	showMostWanted: boolean = false;
	isCloseButtonValid: boolean = false;
	checkVeganFeatureValue = '';

	public enterNotes = new FormControl('');

	constructor(
		public modalCtrl: ModalController,
		protected repository: RepositoryService,
		private snackBarCtrl: MatSnackBar,
		private translate: TranslateService,
		private analytics: AnalyticsService,
		private cdr: ChangeDetectorRef
	) {
		super(repository);
	}

	get type(): PreorderType {
		return this.order.preorder.type;
	}

	ngOnInit() {
		super.ngOnInit();
		this.analytics.beginArticleEdit(this.articleGroup.article);
		const listGroups = this.articleGroup.article.groups.filter(
			it => !(it.tags && it.tags.find(tag => tag.identifier == 'modifier_remove' || tag.identifier == 'modifier_select'))
		);
		if (this.pages.length === 0) {
			this.pages.push([]);
		}
		for (const group of listGroups) {
			const index = Math.floor(group.sortOrder / 10);
			const foundArticlesWithModifiers = (group?.articles ?? []).filter(
				it =>
					!!it.tags.find(
						tag =>
							tag.identifier === 'filter_superfood' ||
							tag.identifier === 'filter_premium' ||
							tag.identifier === 'filter_basic'
					)
			);
			if (foundArticlesWithModifiers.length > 0) {
				const foundArticlesSuperFood = (group?.articles ?? []).filter(
					it => !!it.tags.find(tag => tag.identifier === 'filter_superfood')
				);
				const foundArticlesPremium = (group?.articles ?? []).filter(
					it => !!it.tags.find(tag => tag.identifier === 'filter_premium')
				);
				const foundArticlesBasic = (group?.articles ?? []).filter(it => !!it.tags.find(tag => tag.identifier === 'filter_basic'));
				if (foundArticlesSuperFood.length > 0) {
					const copyGroup = JSON.parse(JSON.stringify(group)) as OptionGroup;
					copyGroup.description = {
						...copyGroup.description,
						de: 'Superfood Extras',

						en: 'Superfood Extras',
					};
					copyGroup.articles = foundArticlesSuperFood;

					this.pages[0].push(copyGroup);
				}
				if (foundArticlesPremium.length > 0) {
					const copyGroup = JSON.parse(JSON.stringify(group)) as OptionGroup;
					copyGroup.description = {
						...copyGroup.description,
						de: 'Premium Extras',
						en: 'Premium Extras',
					};
					copyGroup.articles = foundArticlesPremium;
					this.pages[0].push(copyGroup);
				}
				if (foundArticlesBasic.length > 0) {
					const copyGroup = JSON.parse(JSON.stringify(group)) as OptionGroup;
					copyGroup.description = {
						...copyGroup.description,
						de: 'Basic Extras',
						en: 'Basic Extras',
					};
					copyGroup.articles = foundArticlesBasic;
					this.pages[0].push(copyGroup);
				}
			} else {
				this.pages[0].push(group);
			}
		}
		if (this.pages.length > 1) {
			this.pages = this.pages.filter(page => page !== null);
		}

		this.indicesOfRecommendations = [];
		this.pages.forEach((page, pageIndex) => {
			if (page.length > 0 && this.articleGroup.article.recommendations.find(recommendation => recommendation.group === page[0]._id)) {
				this.indicesOfRecommendations.push(pageIndex);
			}
		});
		this.onSlideChange();
		if (this.articleGroup.article.tags.find(it => it.identifier === 'most_wanted')) {
			this.showMostWanted = true;
		}
		console.log(this.articleGroup);
		try {
			this.isCloseButtonValid = ValidationUtils.areGroupsValid(this.articleGroup, this.pages[0]);
		} catch (e) {
			this.isCloseButtonValid = false;
		}
		this.checkVeganFeature(this.articleGroup.article);
	}

	async ngAfterViewInit() {
		await this.slides?.nativeElement.swiper.isBeginning;
		console.log(this.pages);
		console.log(this.articleGroup);
		this.checkIfHaveGroups();
	}

	async finish() {
		if (this.enterNotes.value) {
			this.articleGroup.note = this.enterNotes.value;
		}
		this.analytics.finishArticleEdit(this.articleGroup.article, this.currentPageIndex);
		await this.modalCtrl.dismiss({
			articleGroup: this.articleGroup,
		});
	}

	async close() {
		this.analytics.cancelArticleEdit(this.articleGroup.article, this.currentPageIndex);
		await this.modalCtrl.dismiss();
	}

	increase() {
		this.articleGroup.quantity += 1;
	}

	decrease() {
		if (this.articleGroup.quantity > 1) {
			this.articleGroup.quantity -= 1;
		}
	}

	optionGroupDisabled(optionGroup: OptionGroup): boolean {
		if (OrderUtils.isBogoOrFreeArticlePromo(this.order) && this.order.promoCode.type === PromoCodeType.BOGO) {
			const bogoArticleIndex = this.order.orderedArticles.findIndex(ag => ag.isPromo);
			const bogoArticle = this.order.orderedArticles[bogoArticleIndex];
			const matchingBogoArticleIndices = this.order.orderedArticles
				.map((ag, agIndex) => {
					return ag.article._id === bogoArticle.article._id &&
						!ag.isPromo &&
						OrderUtils.bogoPrice(ag, this.order.type, this.order.preorder.type) ===
							OrderUtils.bogoPrice(bogoArticle, this.order.type, this.order.preorder.type)
						? agIndex
						: -1;
				})
				.filter(agIndex => agIndex !== -1);
			// article that should be removed is a possible parent of promoCode article
			if (
				matchingBogoArticleIndices.length === 1 &&
				bogoArticle.article._id === this.articleGroup.article._id &&
				OrderUtils.bogoPrice(this.articleGroup, this.order.type, this.order.preorder.type) ===
					OrderUtils.bogoPrice(bogoArticle, this.order.type, this.order.preorder.type)
			) {
				return !this.recommend;
			}
		}
		return this.articleGroup.isPromo && optionGroup.displayIdentifiers.indexOf(DisplayIdentifier.main) >= 0;
	}

	async add(
		pageIndex: number,
		optionGroupIndex: number,
		option: ArticleOption,
		group: OptionGroup,
		fulfilledDependency: FulfilledDependency,
		dependencyNumber: number
	) {
		if (this.sliding) {
			return;
		}
		OrderUtils.addOptionNew(this.articleGroup.groups, option, group, fulfilledDependency, dependencyNumber);
		this.isCloseButtonValid = ValidationUtils.areGroupsValid(this.articleGroup, this.pages[0]);
		this.articleGroup.groups = this.articleGroup.groups.filter(group => {
			const foundOption = this.articleGroup.article.groups.find(it => it._id === group.group);
			if (!foundOption) {
				return false;
			}
			const dependencies = ValidationUtils.isGroupDependencyFulfilled(
				this.articleGroup.article,
				this.articleGroup.groups,
				foundOption
			);
			if ((dependencies.times > 0 && group.dependencyNumber === 0) || group.dependencyNumber > dependencies.times) {
				return false;
			}
			return true;
		});
		this.articleGroup.article.groups.forEach(grp => {
			if (ValidationUtils.areGroupsValid(this.articleGroup, [grp])) {
				return;
			}
			if (grp.limit != grp.requiredAmount || grp.limit != grp.articles.length || grp.hasMultiple) {
				return;
			}

			const dependency = ValidationUtils.isGroupDependencyFulfilled(this.articleGroup.article, this.articleGroup.groups, grp);
			console.log(dependency);
			for (let depNumber = 1; depNumber <= dependency.times; depNumber++) {
				console.log('For Each', depNumber);

				grp.articles.forEach(opt => {
					const option = new ArticleOption();
					option.article = opt;
					option.group = grp._id;
					option.dependency = dependency?.dependency?._id;
					option.dependencyNumber = depNumber;
					option.dependsOn = dependency?.dependsOn;
					option.quantity = 1;
					OrderUtils.addOptionNew(this.articleGroup.groups, option, grp, dependency, depNumber);
				});
			}
		});
		console.log(
			this.articleGroup.article.name.de,
			this.articleGroup.groups.map(option => ({
				name: option.article.name,
				dependencyNumber: option.dependencyNumber,
				quantity: option.quantity,
			}))
		);
		this.isCloseButtonValid = ValidationUtils.areGroupsValid(this.articleGroup, this.pages[0]);

		if (
			this.pages[pageIndex].length <= optionGroupIndex ||
			group.limit <= 0 ||
			this.articleGroup.groups
				.map(addedOption => (group._id === addedOption.group ? addedOption.quantity : 0))
				.reduce((prev, curr) => prev + curr, 0) < group.limit
		) {
			return;
		}

		const slideIndex = await this.slides?.nativeElement.swiper.activeIndex;
		try {
			const container =
				// @ts-ignore
				this.slides.el.children[0].children[slideIndex].children[0].children[1];
			const options: HTMLCollection = container.children;
			const previousNotFulfilledOptions = this.pages[pageIndex]
				.map(
					(optionGroup, index) =>
						index < optionGroupIndex &&
						ValidationUtils.isGroupDependencyFulfilled(this.articleGroup.article, this.articleGroup.groups, optionGroup).times <
							0
				)
				.filter(res => res).length;
			options[optionGroupIndex + 2 - previousNotFulfilledOptions].scrollIntoView({ behavior: 'smooth' });
			// this.isCloseButtonValid = ValidationUtils.areGroupsValid(this.articleGroup, this.pages[0])
		} catch (e) {
			console.error({
				pageIndex,
				message: 'could not scroll',
				e,
			});
			this.isCloseButtonValid = ValidationUtils.areGroupsValid(this.articleGroup, this.pages[0]);
		}
		this.isCloseButtonValid = ValidationUtils.areGroupsValid(this.articleGroup, this.pages[0]);
	}
	async pageIndex(): Promise<number> {
		await sleep(100);
		let activeIndex = this.slides?.nativeElement?.swiper?.activeIndex;
		const activeIndexValue = activeIndex + this.hiddenCount(activeIndex);
		return activeIndexValue;
	}

	hiddenCount(until: number): number {
		let realIndex = until;
		this.indicesOfRecommendations.forEach(index => {
			if (realIndex >= index) {
				realIndex = realIndex + 1;
			}
		});
		return this.articleGroup.isRecommendedRecipe
			? this.indicesOfRecommendations
					.map(index => (index <= realIndex ? 1 : 0))
					.reduce((previousValue, currentValue) => previousValue + currentValue, 0)
			: 0;
	}

	async addToOrder(finished: boolean = false) {
		if (
			this.currentPageIndex === 0 &&
			this.recommend &&
			this.articleGroup.article.recommendations &&
			this.articleGroup.article.recommendations.length > 0 &&
			ValidationUtils.areGroupsValid(this.articleGroup, this.pages[0])
		) {
			const recommendations = defaultsToArticleOption(
				this.articleGroup.article,
				this.articleGroup.groups,
				this.articleGroup.article.recommendations,
				OrderType.PREORDER,
				this.order.preorder.type
			);
			const groups: OptionGroup[] = [];
			this.articleGroup.article.recommendations.forEach(option => {
				const insertedGrp = groups.find(grp => grp._id === option.group);
				if (!insertedGrp) {
					const group = this.articleGroup.article.groups.find(grp => option.group === grp._id);
					// only fulfilled recommendations are relevant
					if (ValidationUtils.isGroupDependencyFulfilled(this.articleGroup.article, recommendations, group).times >= 0) {
						groups.push(this.articleGroup.article.groups.find(grp => option.group === grp._id));
					}
				}
			});
			// Check if some recommendations are inactive before we allow the user to select recommendations
			const allRequiredAvailable = groups
				.map(grp => recommendations.filter(opt => opt.group === grp._id).length)
				.map((value, index) => groups[index].requiredAmount <= value)
				.reduce((previousValue, currentValue) => previousValue && currentValue, true);
			const recommendationAction = allRequiredAvailable
				? await RecommendationModalComponent.show(this.modalCtrl)
				: RecommendationModalAction.CUSTOMIZATION;
			switch (recommendationAction) {
				case RecommendationModalAction.CUSTOMIZATION:
					this.articleGroup.isRecommendedRecipe = false;
					await sleep(100);
					if (!(await this.slides?.nativeElement.swiper.isEnd)) {
						await this.nextSlide();

						return;
					}
					if (!ValidationUtils.areGroupsValid(this.articleGroup, this.articleGroup.article.groups)) {
						this.snackBarCtrl.open(this.translate.instant('modal_info.pick_error'));
						return;
					}
					await this.finish();
					return;
				case RecommendationModalAction.DISMISS:
					return;
				case RecommendationModalAction.RECOMMENDATION:
					this.articleGroup.isRecommendedRecipe = true;
					await sleep(100);
					// filter options of first page
					const optionsToKeep = [
						...this.articleGroup.groups.filter(
							option => this.pages[0].findIndex(optionGroup => optionGroup._id === option.group) >= 0
						),
						...recommendations,
					];
					this.articleGroup.groups = [];
					optionsToKeep.forEach(opt => {
						const grp = this.articleGroup.article.groups.find(group => group._id === opt.group);
						const dep = ValidationUtils.isGroupDependencyFulfilled(this.articleGroup.article, optionsToKeep, grp);
						OrderUtils.addOption(this.articleGroup.groups, opt, grp, dep);
					});
					await this.nextSlide();
					return;
				default:
					return;
			}
		} else {
			if (this.articleGroup.isRecommendedRecipe) {
				for (const recommendationIndex of this.indicesOfRecommendations) {
					const page = this.pages[recommendationIndex].map(group => group._id);
					this.articleGroup.groups = this.articleGroup.groups.filter(opt => page.indexOf(opt.group) < 0);
				}
				this.articleGroup.groups = defaultsToArticleOption(
					this.articleGroup.article,
					this.articleGroup.groups,
					this.articleGroup.article.recommendations,
					OrderType.PREORDER,
					this.order.preorder.type
				);
			}
			if (!(await this.slides?.nativeElement?.swiper?.isEnd)) {
				await this.nextSlide();
				return;
			}
			if (!ValidationUtils.areGroupsValid(this.articleGroup, this.articleGroup.article.groups)) {
				this.snackBarCtrl.open(this.translate.instant('modal_info.pick_error'), null, {
					duration: 2000,
				});
				return;
			}
			if (finished) {
				await this.finish();
			}
		}
	}

	async onSlideChange() {
		const pageIndex = await this.pageIndex();
		this.currentPageIndex = pageIndex;
		this.isLastPage = await this.slides?.nativeElement.swiper.isEnd;

		console.log({
			// slideIndex,
			index: pageIndex,
			lastPage: this.isLastPage,
			pages: this.pages.map(page => page.map(grp => grp.name.de)),
			modes: this.pages.map(page => page.map(grp => grp.displayMode)),
		});
	}

	async prevSlide() {
		if (this.sliding) {
			return;
		}
		if (await this.slides?.nativeElement?.swiper?.isBeginning) {
			this.close();
		}
		this.sliding = true;
		if (!(await this.slides?.nativeElement?.swiper?.isBeginning)) {
			await this.slides?.nativeElement?.swiper.slidePrev(500);
		}
		this.sliding = false;
	}

	async nextSlide() {
		if (this.sliding) {
			return;
		}

		this.sliding = true;
		if (await this.slides?.nativeElement.swiper.isEnd) {
			await this.addToOrder();
			return;
		}
		await this.validateAndSlideNext(this.pages[await this.pageIndex()]);
	}

	async validateAndSlideNext(optionGroups: OptionGroup[]) {
		const pageValid = ValidationUtils.areGroupsValid(this.articleGroup, optionGroups);
		if (!pageValid) {
			this.snackBarCtrl.open(this.translate.instant('modal_info.pick_error'));
			this.sliding = false;
			return;
		}
		if (await this.slides?.nativeElement?.swiper.isEnd) {
			await this.addToOrder();
		} else {
			this.analytics.stepArticleEdit(this.articleGroup.article, this.currentPageIndex);
			await this.slides.nativeElement?.swiper.update();
			await this.slides?.nativeElement?.swiper.slideNext(500);
		}
		this.sliding = false;
	}

	async closeSlide() {
		console.log(this.sliding);
		if (this.sliding) {
			return;
		}
		this.sliding = true;
		await this.slides.nativeElement?.swiper.update();
		await this.slides.nativeElement?.swiper.slideTo(0);
		this.sliding = false;
	}

	async openInfo(article: Article) {
		await ShortInfoModalComponent.show(this.modalCtrl, article.info);
	}

	currentPage(): OptionGroup {
		return this.pages[this.currentPageIndex][0];
	}

	nextFulfilledPage(currentPage: number): number {
		return this.pages.findIndex(
			(groups, index) => index > currentPage && ValidationUtils.someGroupsFulfilled(this.articleGroup, groups)
		);
	}

	previousFulfilledPage(currentPage: number): number {
		return Math.max(
			...this.pages.map((page, index) => {
				if (index < currentPage && ValidationUtils.someGroupsFulfilled(this.articleGroup, page)) {
					return index;
				} else {
					return 0;
				}
			})
		);
	}

	async clearCurrentPageAndNext(pageIndex) {
		this.articleGroup.groups = this.articleGroup.groups.filter(option => {
			for (const grp of this.pages[pageIndex]) {
				if (option.group === grp._id) {
					return false;
				}
			}
			return true;
		});
		await this.nextSlide();
	}

	async requireOneOfAndNextSlide(pageIndex) {
		const currentPageArticles = this.articleGroup.groups.filter(option => {
			for (const grp of this.pages[pageIndex]) {
				if (option.group === grp._id) {
					return true;
				}
			}
			return false;
		});
		if (currentPageArticles.length === 0) {
			this.snackBarCtrl.open(this.translate.instant('modal_info.pick_error'));
			return;
		}
		await this.nextSlide();
	}

	pageVisible(pageIndex: number): boolean {
		// Some pages needs to be invisible (if dependencies are not fulfilled or recommendations taken)

		return (
			(((!this.articleGroup.isRecommendedRecipe || this.indicesOfRecommendations.indexOf(pageIndex) < 0) &&
				ValidationUtils.someGroupsFulfilled(this.articleGroup, this.pages[pageIndex])) ||
				!ValidationUtils.areGroupsValid(this.articleGroup, this.pages[pageIndex])) &&
			(pageIndex > 0
				? !!this.pages[pageIndex].find(it => it.visible) &&
				  !!this.pages[pageIndex][0]?.articles?.find(
						it => it?.visible && getAvailability(it, OrderType.PREORDER, this.order.preorder.type)
				  )
				: true)
		);
	}

	isPageOverlay(pageIndex: number): boolean {
		return pageIndex !== 0 && this.pages[pageIndex][0].displayMode !== DisplayMode.DETAIL_OVERLAY;
	}
	checkPageIfHaveGrid(articleGroup: ArticleGroup) {
		return (
			articleGroup.article.groups.length > 0 &&
			articleGroup.article.groups.filter(it => !!it.tags.find(tag => tag.identifier === 'half_pizza')).length > 0
		);
		// return articleGroup.article.tags.find(it => it.identifier == 'half_pizza');
	}
	checkIfLandscape(src: string) {
		const image = new Image();
		image.src = src;
		var height = image.height;
		var width = image.width;

		if (width > height + 100) {
			return true;
		}
	}
	checkIfHaveGroups() {
		this.checkIfButtonFinishedAvailable =
			this.articleGroup.article.groups.length > 0 &&
			!this.articleGroup.article.tags.find(it => it?.identifier?.includes('deposit')) &&
			this.articleGroup.article.groups.filter(it => it.requiredAmount > 0 && it.visible)?.length === 0;
	}
	checkIfOwnPizza(article: Article) {
		return article?.number == '40144';
	}
	checkTag(optionGroup: OptionGroup) {
		return optionGroup && optionGroup.tags.length > 0 && optionGroup.tags.find(it => it?.identifier === 'with_bread');
	}
	// addCustomOption(optionGroup: OptionGroup, pageIndex: number, optionGroupIndex: number, dependency: FulfilledDependency) {
	// 	const foundArticle = optionGroup.articles.find(it => it.visible);
	// 	if (foundArticle) {
	// 		const articleOption = new ArticleOption();
	// 		articleOption.article = foundArticle;
	// 		articleOption.group = optionGroup._id;
	// 		articleOption.quantity = 1;
	// 		this.add(pageIndex, optionGroupIndex, articleOption, optionGroup, dependency);
	// 	}
	// }
	checkOptionGroup(optionGroups: OptionGroup[]) {
		return optionGroups.find(
			op =>
				op.articles.filter(it => it.visible && it.isActive).length > 0 &&
				!!op.tags.find(it => it.identifier == 'modifier_remove' || it.identifier == 'modifier_select')
		);
	}
	async openRemoveIngredients(optionGroups: OptionGroup[]) {
		const foundGroupWithRemoving = optionGroups.filter(
			op => !!op.tags.find(it => it.identifier == 'modifier_remove') || !!op.tags.find(it => it.identifier == 'modifier_select')
		);
		if (!foundGroupWithRemoving) {
			return;
		}
		const result = await ShowRemoveIngrediantsDialogComponent.show(
			this.modalCtrl,
			foundGroupWithRemoving,
			this.articleGroup,
			this.order
		);
		if (result && result.data && result.data.length > 0) {
			result.data.forEach(async opt => {
				const fulfilledDependency = new FulfilledDependency();
				fulfilledDependency.dependency = null;
				fulfilledDependency.dependsOn = null;
				fulfilledDependency.times = 0;
				await this.add(
					0,
					0,
					opt,
					foundGroupWithRemoving.find(it => it._id === opt.group),
					fulfilledDependency,
					0
				);
			});
		}
	}
	showNutrition(article: Article) {
		AllergensInfoModalComponent.show(this.modalCtrl, article);
	}
	generateDependencies(fulfilledDependency: FulfilledDependency): number[] {
		if (fulfilledDependency.times === 0) return [0];
		if (fulfilledDependency.times === -1) return [];
		const dependencyNumbers: number[] = [];
		for (let dependencyNumber = 1; dependencyNumber <= fulfilledDependency.times; dependencyNumber++) {
			dependencyNumbers.push(dependencyNumber);
		}
		return dependencyNumbers;
	}
	checkVeganFeature(article: Article) {
		// this.checkVeganFeatureValue = Object.entries(article.compound?.features)
		// 	.filter(it => it[0] == 'vegan' || it[0] == 'vegetarian')
		// 	.filter(it => !!it[1])
		// 	.map(it => it[0])[0];
		const result = Object.entries(article.compound?.features)
			.filter(it => it[0] == 'vegetarian' || it[0] == 'vegan')
			.filter(it => !!it[1])
			.map(it => it[0]);
		if (result && result?.length > 1) {
			this.checkVeganFeatureValue = result[1];
		} else {
			this.checkVeganFeatureValue = result[0];
		}
	}
	checkIfArticleIsVisible(optionGroup: OptionGroup) {
		return (
			optionGroup?.articles?.filter(
				it => it?.visible && it?.isActive && getAvailability(it, OrderType.PREORDER, this.order?.preorder?.type)
			).length > 0
		);
	}
}
