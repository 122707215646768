export default class WebContentCardBraze {
	id: string;
	viewed: boolean;
	title: string;
	imageUrl: string;
	description: string;
	created: string;
	updated: string;
	categories: any[];
	expiresAt: string;
	url: string | null;
	linkText: string;
	aspectRatio: number;
	extras: Record<string, any>;
	pinned: boolean;
	dismissible: boolean;
	clicked: boolean;
	dismissed: boolean;
	isControl: boolean;
	test: boolean;
	Et: string | null;
	Tt: string | null;
	_: string;
	S: boolean;
}
