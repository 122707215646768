import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { PaymentSuccessPage } from './payment-success.page';

import { SharedModuleModule } from '../../shared-module/shared-module.module';
import { ModalNavigationGuard } from 'src/app/modal-navigation-guard';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';

const routes: Routes = [
	{
		path: '',
		component: PaymentSuccessPage,
		canDeactivate: [ModalNavigationGuard],
	},
];

@NgModule({
	imports: [CommonModule, FormsModule, IonicModule, RouterModule.forChild(routes), SharedModuleModule],
	declarations: [PaymentSuccessPage],
})
export class SuccessPageModule {}
