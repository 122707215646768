import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { AnalyticsService } from '../smoothr-web-app-core/services/analytics/analytics.service';
import { Api } from '../smoothr-web-app-core/api/api';
import { NavigationService } from './services/navigation.service';
import { register } from 'swiper/element/bundle';
import { BrazeService } from './services/braze.service';
import { RepositoryService } from 'src/smoothr-web-app-core/services/repository/repository.service';
declare var cookieConsentData: any;
declare var cookieConsentCallback: any;
register();

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
	static largeScreen = false;
	static analyticsEnabled = false;
	isCookieAccepted = false;
	static isWebApp = true;
	static isNativeApp = false;
	constructor(
		private router: Router,
		private analytics: AnalyticsService,
		platform: Platform,
		private translate: TranslateService,
		private navigationService: NavigationService,
		private brazeService: BrazeService,
		private repository: RepositoryService
	) {
		console.log(environment.customerGroup + ' ' + environment.version);
		const favIcon: any = document.querySelector("link[rel*='icon']") || document.createElement('link');
		favIcon.type = 'image/x-icon';
		favIcon.rel = 'shortcut icon';
		favIcon.href = '/assets/dean_and_david/logo.png';
		document.getElementsByTagName('head')[0].appendChild(favIcon);
		document.title = translate.instant('index.title.' + environment.customerGroup);

		this.initCookieConsent();
		const languageValue = localStorage.getItem('langOfApp');
		console.log(languageValue);
		if (languageValue) {
			translate.setDefaultLang(languageValue);
			translate.use(languageValue);
		} else {
			translate.setDefaultLang('de');
			translate.use('de');
		}

		translate.onLangChange.subscribe(languageChanged => {
			if (languageChanged) localStorage.setItem('langOfApp', languageChanged.lang);
		});
		platform.ready().then(() => {
			AppComponent.largeScreen = platform.width() >= 992;
			platform.resize.subscribe(() => {
				AppComponent.largeScreen = platform.width() >= 992;
			});
		});
		Api.isMaintenanceActive()
			.then(result => {
				if (result) {
					this.navigationService.maintenance();
				}
			})
			.catch(error => {
				console.log(error);
			});
	}
	private startBrazeInitialize() {
		this.brazeService.initializeService();
		this.brazeService.logCustomEvent('AppInitialized');
		this.brazeService.requestPushPermission();
		if (this.repository?.customer?._id) {
			this.brazeService.setUser(this.repository.customer._id);
			// this.brazeService.setMultipleCustomAttributes({
			// 	gender: 'M',
			// 	age_group: '18-24',
			// });
		}
		this.brazeService.showInAppMessage();
	}
	ngOnInit() {}

	ngDoCheck() {}

	async handleCookieConsent() {
		console.log('handleCookieConsent()');
		AppComponent.analyticsEnabled = cookieConsentData.targeting;
		await this.setAnalyticsEnabled(cookieConsentData.targeting);
	}

	async setAnalyticsEnabled(enabled: boolean) {
		await this.analytics.setAnalyticsCollectionEnabled(enabled);
	}

	private async initCookieConsent() {
		const onContentLoaded = async () => {
			cookieConsent.run({
				notice_banner_type: 'simple',
				consent_type: 'express',
				palette: 'dark',
				language: 'de',
				website_name: this.translate.instant('cookie_consent.title.' + environment.customerGroup[1]),
				cookies_policy_url: this.translate.instant('cookie_consent.policy_url.' + environment.customerGroup[1]),
			});
		};
		if (document.readyState === 'loading') {
			document.addEventListener('DOMContentLoaded', onContentLoaded);
		} else {
			await onContentLoaded();
		}
		cookieConsentCallback = async () => {
			await this.handleCookieConsent();
			this.startBrazeInitialize();
		};
		await this.handleCookieConsent();
	}
	openPage(value: string) {
		if (!value) {
			return;
		}
		this.router.navigate([value]);
	}
}
