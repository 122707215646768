import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ArticleOptionGroupFlavorComponent } from '../components/article-option-group-flavor/article-option-group-flavor.component';
import { ItemSelectableArticleComponent } from '../components/item-selectable-article/item-selectable-article.component';
import { ToOrderButtonComponent } from '../components/to-order-button/to-order-button.component';
import { OrderListComponent } from '../components/order-list/order-list.component';
import { ToggleButtonGroupComponent } from '../components/toggle-button-group/toggle-button-group.component';
import { MenuPopoverComponent } from '../components/menu-popover/menu-popover.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MyOrderComponent } from '../components/my-order/my-order.component';

import { ItemViewComponent } from '../components/item-view/item-view.component';
import { ArticleOptionGroupSingleComponent } from '../components/article-option-group-single/article-option-group-single.component';
import { VenueSuggestionComponent } from '../components/venue-suggestion/venue-suggestion.component';
import { HomePopoverComponent } from '../components/home-popover/home-popover.component';
import { ArticleOptionGroupCheckboxComponent } from '../components/article-option-group-checkbox/article-option-group-checkbox.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { createTranslateLoader } from '../app.module';
import { BurgerBtnComponent } from '../components/burger-btn/burger-btn.component';
import { ArticleOptionGroupMultipleComponent } from '../components/article-option-group-multiple/article-option-group-multiple.component';
import { RouterModule } from '@angular/router';
import { OrderContentComponent } from '../components/order-content/order-content.component';
import { PromoCodeComponent } from '../components/promo-code/promo-code.component';
import { PaymentMethodsDisplayComponent } from '../components/payment-methods-display/payment-methods-display.component';
import { CustomAddressModalComponent } from '../components/custom-address-modal/custom-address-modal.component';
import { AllowGpsModalComponent } from '../components/allow-gps-modal/allow-gps-modal.component';
import { TipComponent } from '../components/tip/tip.component';
import { LangStringPipe } from '../../smoothr-web-app-core/pipes/lang-string.pipe';
import { TagComponent } from '../components/tag/tag.component';
import { QuantitySelectorComponent } from '../components/quantity-selector/quantity-selector.component';
import { PreorderTypeChipComponent } from '../components/preorder-type-chip/preorder-type-chip.component';
import { AccountToolbarComponent } from '../components/account-toolbar/account-toolbar.component';
import { MenuModalComponent } from '../components/menu-modal/menu-modal.component';
import { NoteArticleModalComponent } from '../components/note-article-modal/note-article-modal.component';
import { LangObservablePipe } from '../../smoothr-web-app-core/pipes/lang-obervable.pipe';
import { AppRestorePasswordModalComponent } from '../components/app-restore-password-modal/app-restore-password-modal.component';
import { SelectDateFormcontrolComponent } from '../components/select-date-formcontrol/select-date-formcontrol.component';
import { SelectLanguagePopoverComponent } from '../components/select-language-popover/select-language-popover.component';
import { MapVenueComponent } from 'src/maps/components/map-venue/map-venue.component';
import { HeaderComponent } from '../components/header/header.component';
import { MapSearchVenueComponent } from 'src/maps/components/map-search-venue/map-search-venue.component';
import { InputPhoneWithCountyCodeComponent } from '../components/input-phone-with-county-code/input-phone-with-county-code.component';
import { CustomModalWithCountrySelectionComponent } from '../components/input-phone-with-county-code/custom-modal-with-country-selection/custom-modal-with-country-selection.component';
import { CheckoutModalComponent } from '../components/checkout-modal/checkout-modal.component';
import { PaymentComponent } from '../components/payment-modal/payment-modal.component';
import { OrderPageToolbarComponent } from '../components/order-page-toolbar/order-page-toolbar.component';
import { SwiperDirective } from '../directives/swiper.directive';
import { AddCustomTipComponent } from '../components/add-custom-tip/add-custom-tip.component';
import { AppToolbarVenueInfoComponent } from '../components/app-toolbar-venue-info/app-toolbar-venue-info.component';
import { AccordionListComponent } from '../components/accordion-list/accordion-list.component';
import { AllergensInfoModalComponent } from '../components/allergens-info-modal/allergens-info-modal.component';
import { ArticleOptionGroupCustomComponent } from '../components/article-option-group-custom/article-option-group-custom.component';
import { ItemSelectableArticleCustomComponent } from '../components/item-selectable-article-custom/item-selectable-article-custom.component';
import { ShowRemoveIngrediantsDialogComponent } from '../components/show-remove-ingrediants-dialog/show-remove-ingrediants-dialog.component';
import { AppHeaderMenuComponent } from '../components/app-header-menu/app-header-menu.component';
import { OrderContentModalComponent } from '../components/order-content-modal/order-content-modal.component';
import { ChangeVenuePopoverComponent } from '../components/change-venue-popover/change-venue-popover.component';
import { MenuListFiltersComponent } from '../components/menu-list-filters/menu-list-filters.component';
import { CheckDeliveryRadiusModalComponent } from '../components/check-delivery-radius-modal/check-delivery-radius-modal.component';
import { RecomendationArticleModalComponent } from '../components/recomendation-article-modal/recomendation-article-modal.component';
import { EnterFoodspotCodeModalComponent } from '../components/enter-foodspot-code-modal/enter-foodspot-code-modal.component';
import { InfoModalComponent } from '../components/info-modal/info-modal.component';
import { DrawNewsBrazeItemComponent } from '../components/draw-news-braze-item/draw-news-braze-item.component';

@NgModule({
	declarations: [
		ArticleOptionGroupFlavorComponent,
		ArticleOptionGroupSingleComponent,
		ItemSelectableArticleComponent,
		ArticleOptionGroupCustomComponent,
		ToOrderButtonComponent,
		OrderListComponent,
		ToggleButtonGroupComponent,
		MenuPopoverComponent,
		VenueSuggestionComponent,
		MyOrderComponent,
		HomePopoverComponent,
		ItemViewComponent,
		ArticleOptionGroupCheckboxComponent,
		ArticleOptionGroupMultipleComponent,
		BurgerBtnComponent,
		OrderContentComponent,
		PromoCodeComponent,
		PaymentMethodsDisplayComponent,
		CustomAddressModalComponent,
		AllowGpsModalComponent,
		TagComponent,
		TipComponent,
		LangStringPipe,
		LangObservablePipe,
		QuantitySelectorComponent,
		PreorderTypeChipComponent,
		AccountToolbarComponent,
		MenuModalComponent,
		NoteArticleModalComponent,
		AppRestorePasswordModalComponent,
		SelectDateFormcontrolComponent,
		SelectLanguagePopoverComponent,
		MapVenueComponent,
		HeaderComponent,
		MapSearchVenueComponent,
		InputPhoneWithCountyCodeComponent,
		CustomModalWithCountrySelectionComponent,
		CheckoutModalComponent,
		PaymentComponent,
		OrderPageToolbarComponent,
		SwiperDirective,
		AddCustomTipComponent,
		AppToolbarVenueInfoComponent,
		AccordionListComponent,
		AllergensInfoModalComponent,
		ItemSelectableArticleCustomComponent,
		ShowRemoveIngrediantsDialogComponent,
		AppHeaderMenuComponent,
		OrderContentModalComponent,
		ChangeVenuePopoverComponent,
		MenuListFiltersComponent,
		CheckDeliveryRadiusModalComponent,
		RecomendationArticleModalComponent,
		EnterFoodspotCodeModalComponent,
		InfoModalComponent,
		DrawNewsBrazeItemComponent,
	],
	exports: [
		ArticleOptionGroupCheckboxComponent,
		ArticleOptionGroupFlavorComponent,
		ArticleOptionGroupSingleComponent,
		ArticleOptionGroupMultipleComponent,
		ArticleOptionGroupCustomComponent,
		ItemSelectableArticleComponent,
		ToOrderButtonComponent,
		OrderListComponent,
		ToggleButtonGroupComponent,
		HomePopoverComponent,
		VenueSuggestionComponent,
		MenuPopoverComponent,
		BurgerBtnComponent,
		MyOrderComponent,
		ItemViewComponent,
		TranslateModule,
		OrderContentComponent,
		PromoCodeComponent,
		CustomAddressModalComponent,
		AllowGpsModalComponent,
		LangStringPipe,
		LangObservablePipe,
		TagComponent,
		QuantitySelectorComponent,
		PreorderTypeChipComponent,
		AccountToolbarComponent,
		MenuModalComponent,
		NoteArticleModalComponent,
		AppRestorePasswordModalComponent,
		SelectDateFormcontrolComponent,
		SelectLanguagePopoverComponent,
		MapVenueComponent,
		HeaderComponent,
		MapSearchVenueComponent,
		InputPhoneWithCountyCodeComponent,
		CustomModalWithCountrySelectionComponent,
		CheckoutModalComponent,
		PaymentComponent,
		OrderPageToolbarComponent,
		SwiperDirective,
		AddCustomTipComponent,
		AppToolbarVenueInfoComponent,
		AccordionListComponent,
		AllergensInfoModalComponent,
		ItemSelectableArticleCustomComponent,
		ShowRemoveIngrediantsDialogComponent,
		AppHeaderMenuComponent,
		OrderContentModalComponent,
		ChangeVenuePopoverComponent,
		MenuListFiltersComponent,
		CheckDeliveryRadiusModalComponent,
		RecomendationArticleModalComponent,
		EnterFoodspotCodeModalComponent,
		InfoModalComponent,
		DrawNewsBrazeItemComponent,
	],
	imports: [
		CommonModule,
		IonicModule,
		FormsModule,
		MatSnackBarModule,
		ReactiveFormsModule,
		TranslateModule.forChild({
			defaultLanguage: 'de',
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient],
			},
		}),
		RouterModule,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModuleModule {}
