import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { HomePage } from '../home/home.page';
import { MenuPage } from '../menu/menu.page';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import { environment } from '../../../environments/environment';
import { NavigationService } from 'src/app/services/navigation.service';
import { Location } from '@angular/common';
import { Api } from 'src/smoothr-web-app-core/api/api';

@Component({
	selector: 'app-impressum',
	templateUrl: './impressum.page.html',
	styleUrls: ['impressum.page.scss'],
})
export class ImpressumPage {
	static url = 'impressum';
	environment = environment;
	public checkIfVenueExistImpressum = '';

	constructor(
		private repository: RepositoryService,
		private navigationService: NavigationService,
		private location: Location
	) {}
	ionViewDidEnter() {
		if (this.repository._venue && this.repository._order) {
			if (this.repository._venue) {
				if (this.repository._venue?.legal?.imprint) {
					this.checkIfVenueExistImpressum = this.repository._venue?.legal?.imprint;
				} else {
					this.checkToShowData(this.repository._venue._id);
				}
				return;
			} else {
				this.checkIfVenueExistImpressum = '';
			}
		} else {
			this.checkIfVenueExistImpressum = '';
		}
	}
	private async checkToShowData(venueId: string) {
		try {
			//TODO: deploy and check with client
			// const legal = (await Api.getLegal(venueId)).data;
			// if (legal.type === 'pdf') {
			// 	// window.open(legal.imprint, '_blank');
			// 	// this.location.back();
			// 	const link = document.createElement('a');
			// 	link.href = legal.imprint;
			// 	link.target = '_blank';
			// 	link.rel = 'noopener noreferrer'; // Add security attributes
			// 	document.body.appendChild(link);
			// 	link.click(); // Simulates a user click
			// 	document.body.removeChild(link); // Clean up
			// 	this.location.back(); // Navigate back after opening the link
			// }
		} catch (e) {
			console.error('Error while getting legal information');
			console.error(e);
		}
	}
	async goBack() {
		if (this.location) {
			this.location.back();
			return;
		}
		if (this.repository._order === null) {
			await this.navigationService.home();
		} else {
			await this.navigationService.menu();
		}
	}
}
