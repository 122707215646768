import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { MenuPage } from './menu.page';
import { SharedModuleModule } from '../../shared-module/shared-module.module';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { InformationModalComponent } from '../../components/information-modal/information-modal.component';
import { CategoryChipListComponent } from '../../components/category-chip-list/category-chip-list.component';
import { MenuFiltersComponent } from 'src/app/components/menu-filters/menu-filters.component';
import { FiltersAdditionalOptionsComponent } from 'src/app/filters-additional-options/filters-additional-options.component';
import { ScrollingCategoriesComponent } from 'src/app/components/scrolling-categoris/scrolling-categories.component';

const routes: Routes = [
	{
		path: '',
		component: MenuPage,
	},
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		IonicModule,
		RouterModule.forChild(routes),
		SharedModuleModule,
		ReactiveFormsModule,
		MenuFiltersComponent,
		FiltersAdditionalOptionsComponent,
		ScrollingCategoriesComponent,
	],
	providers: [
		{ provide: MatBottomSheetRef, useValue: {} },
		{ provide: MAT_BOTTOM_SHEET_DATA, useValue: {} },
	],
	declarations: [MenuPage, InformationModalComponent, CategoryChipListComponent],

	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MenuPageModule {}
