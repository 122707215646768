// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  padding: 15px;
  position: relative;
}

.info {
  font-family: AppFontSemiBold, sans-serif;
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 24px;
  text-transform: capitalize;
  text-align: center;
  color: var(--ion-color-primary);
}

.text {
  font-family: AppFont;
  margin-bottom: 30px;
  text-align: center;
}

.close {
  position: absolute;
  top: 0px;
  right: -5px;
  z-index: 999;
}

.button-block {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
.button-block ion-button {
  --background: white;
  color: var(--ion-color-primary);
  border: 2px solid var(--ion-color-primary);
  border-radius: 28px;
  width: 80%;
  font-size: 16px;
  font-family: AppFontSemiBold, sans-serif;
  overflow: hidden;
  text-transform: uppercase;
  height: 50px;
}
.button-block .button:last-child {
  --background: var(--ion-color-primary);
  border: none;
  color: white;
}

ion-icon {
  z-index: 10;
  color: var(--ion-color-primary);
  zoom: 0.9;
}`, "",{"version":3,"sources":["webpack://./src/app/components/available-driver-modal/available-driver-modal.component.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,kBAAA;AACD;;AAGA;EACC,wCAAA;EACA,gBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,0BAAA;EACA,kBAAA;EACC,+BAAA;AAAF;;AAGA;EACE,oBAAA;EACA,mBAAA;EACA,kBAAA;AAAF;;AAIA;EACC,kBAAA;EACA,QAAA;EACA,WAAA;EACA,YAAA;AADD;;AAGA;EACC,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;AAAD;AACC;EACC,mBAAA;EACA,+BAAA;EACA,0CAAA;EACA,mBAAA;EACA,UAAA;EACA,eAAA;EACA,wCAAA;EACA,gBAAA;EACA,yBAAA;EACA,YAAA;AACF;AACC;EACC,sCAAA;EACA,YAAA;EACA,YAAA;AACF;;AAEA;EACC,WAAA;EACA,+BAAA;EACA,SAAA;AACD","sourcesContent":[".wrapper {\n\tpadding: 15px;\n\tposition: relative;\n\n}\n\n.info {\n\tfont-family: AppFontSemiBold, sans-serif;\n\tmargin-top: 10px;\n\tmargin-bottom: 20px;\n\tfont-size: 20px;\n\tline-height: 24px;\n\ttext-transform: capitalize;\n\ttext-align: center;\n  color: var(--ion-color-primary);\n}\n\n.text {\n  font-family: AppFont;\n  margin-bottom: 30px;\n  text-align: center;\n\n}\n\n.close {\n\tposition: absolute;\n\ttop: 0px;\n\tright: -5px;\n\tz-index: 999;\n}\n.button-block {\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\tmargin-top: 10px;\n\tion-button {\n\t\t--background: white;\n\t\tcolor: var(--ion-color-primary);\n\t\tborder: 2px solid var(--ion-color-primary);\n\t\tborder-radius: 28px;\n\t\twidth: 80%;\n\t\tfont-size: 16px;\n\t\tfont-family: AppFontSemiBold, sans-serif;\n\t\toverflow: hidden;\n\t\ttext-transform: uppercase;\n\t\theight: 50px;\n\t}\n\t.button:last-child {\n\t\t--background: var(--ion-color-primary);\n\t\tborder: none;\n\t\tcolor: white;\n\t}\n}\nion-icon {\n\tz-index: 10;\n\tcolor: var(--ion-color-primary);\n\tzoom: 0.9;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
