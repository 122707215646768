import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import Article from 'src/smoothr-web-app-core/models/Article';
import { numberD } from 'src/smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-allergens-info-modal',
	templateUrl: './allergens-info-modal.component.html',
	styleUrls: ['./allergens-info-modal.component.scss'],
})
export class AllergensInfoModalComponent {
	desc: string;
	article: any;
	constructor(private modalCtrl: ModalController) {}
	numberD = numberD;
	static async show(modalCtrl: ModalController, article: Article) {
		console.log(article);
		const modal = await modalCtrl.create({
			cssClass: 'auto-height',
			component: AllergensInfoModalComponent,
			componentProps: {
				article: article,
			},
			showBackdrop: true,
			backdropDismiss: true,
		});
		await modal.present();
	}

	dismiss() {
		this.modalCtrl.dismiss();
	}
	isAllergens() {
		if (!this.article?.compound?.allergens) {
			return null;
		}
		return Object.values(this.article?.compound?.allergens).find(it => it === true);
	}
	isAddit() {
		if (!this.article?.compound?.additives) {
			return null;
		}
		return Object.values(this.article?.compound?.additives).find(it => it === true);
	}
	isHaveAnyValueMoreZero(article: any) {
		if (!article?.nutritions) {
			return null;
		}
		return Object.values(article?.nutritions)
			.map(it => (numberD(it) ? numberD(it) : 0))
			.find(it => it > 0);
	}
}
