import { Component, EventEmitter, Input, Output } from '@angular/core';
import Article from '../../../smoothr-web-app-core/models/Article';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import { OrderType } from '../../../smoothr-web-app-core/enums/OrderType';
import { environment } from '../../../environments/environment';
import OptionGroup from '../../../smoothr-web-app-core/models/OptionGroup';
import TagUtils from '../../../smoothr-web-app-core/utils/tag-utils';
import { getPrice, numberD, numberToCurrency, resolveByType } from '../../../smoothr-web-app-core/utils/utils';
import { ValidationUtils } from 'src/smoothr-web-app-core/utils/validation-utils';

@Component({
	selector: 'app-item-selectable-article-custom',
	templateUrl: './item-selectable-article-custom.component.html',
	styleUrls: ['item-selectable-article-custom.component.scss'],
})
export class ItemSelectableArticleCustomComponent {
	@Input() article: Article;
	@Input() selected: number;
	@Input() preorderType: PreorderType;
	@Input() group: OptionGroup;
	@Output() add = new EventEmitter<Article>();
	@Output() remove = new EventEmitter();
	@Input() currency: string;
	@Input() customAdd: boolean = false;
	@Input() customRemove: boolean = false;
	@Input() mainArticle: Article;
	numberToCurrency = numberToCurrency;

	constructor() {}

	get price(): number {
		if (this.article) {
			if (this.group) {
				if (this.group?.tags?.find(it => it?.identifier == 'noprice')) {
					return 0;
				}
			}
			if (this.article?.tags?.find(it => it?.identifier == 'show_dependency_price')) {
				const result = this.calcDep(this.group._id, this.mainArticle, this.article);

				if (result) {
					return result;
				}
			}
			if (this.article?.displayPriceByType?.preorder && numberD(this.article?.displayPriceByType?.preorder.takeAway) === 0) {
				console.log(this.article.name.de, this.article, getPrice(this.article, OrderType.PREORDER, this.preorderType));

				return 0;
			}
		}
		// console.log(this.article.name.de, getPrice(this.article, OrderType.PREORDER, this.preorderType))

		return getPrice(this.article, OrderType.PREORDER, this.preorderType);
	}

	get isEmptyArticle(): boolean {
		return TagUtils.hasEmptyTag(this.article);
	}
	calcDep(group: string, mainArticle: Article, renderArticle: Article) {
		if (this.mainArticle.groupDependencies.length > 0) {
			const pushGroup: string[] = [];
			this.mainArticle.groupDependencies.forEach(it => {
				it.dependencies.forEach(dep => {
					dep.groupArticles.forEach(depGroup => {
						if (depGroup === renderArticle._id) {
							pushGroup.push(it.group);
						}
					});
				});
			});

			if (pushGroup.length > 0) {
				const optionGroupForPrice: OptionGroup[] = [];

				this.mainArticle.groups.forEach(it => {
					if (it.visible)
						pushGroup.forEach(groupId => {
							if (groupId == it?._id) {
								optionGroupForPrice.push(it);
							}
						});
				});
				if (optionGroupForPrice.length > 0) {
					const result = optionGroupForPrice.map(it =>
						it.articles.length > 2 ? it?.articles.filter(art => !(!art?.visible || !art?.isActive)) : it.articles
					) as Article[][];
					if (result.length === 0) {
						return 0;
					}
					const minPrice: number[] = [];
					result.forEach(it => {
						minPrice.push(
							getPrice(
								this.minOfItem(it, opt => getPrice(opt, OrderType.PREORDER, this.preorderType)),
								OrderType.PREORDER,
								this.preorderType
							)
						);
					});

					return minPrice.reduce((a, b) => a + b, 0);
				}
			}
			return 0;
		}
		return 0;
	}
	private minOfItem<T>(items: T[], transformer: (item: T) => number): T {
		if (items.length === 1) {
			return items[0];
		}

		const values = items.map(item => transformer(item));
		const min = Math.min(...values);
		const index = values.indexOf(min);
		return items[index];
	}
	addItem() {
		if (this.customRemove && this.selected > 0) {
			this.remove.emit();
			return;
		}
		if (this.customAdd && this.selected > 0) {
			this.remove.emit();
			return;
		}
		if (this.isEmptyArticle) {
			this.add.emit();
			return;
		}
		if (this.group && !this.group.hasMultiple) {
			this.add.emit();
			return;
		}
		if (this.group && this.group.hasMultiple && this.selected == 0) {
			this.add.emit();
			return;
		}
		// this.isEmptyArticle || ? this.add.emit() : null
	}
	onError(event: Event, fallbackUrl: string) {
		const element = event.target as HTMLImageElement;
		element.src = fallbackUrl;
	}
}
