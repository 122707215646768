// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px;
}
ion-icon {
	width: 70px;
	height: 70px;
	margin-bottom: 15px;
}
ion-button {
	min-height: 40px;
}
h3,
p {
	margin-bottom: 15px;
	text-align: center;
	color: var(--ion-color-secondary);
}
#barcode {
	width: 100%;
	height: 100px; /* Adjust as per the size you want */
}

.without_promo {
	margin-top: 10px;
	--background: var(--ion-color-danger);
	color: white;
	text-transform: inherit;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/error-promcode-dialog/error-promcode-dialog.component.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,sBAAsB;CACtB,uBAAuB;CACvB,mBAAmB;CACnB,aAAa;AACd;AACA;CACC,WAAW;CACX,YAAY;CACZ,mBAAmB;AACpB;AACA;CACC,gBAAgB;AACjB;AACA;;CAEC,mBAAmB;CACnB,kBAAkB;CAClB,iCAAiC;AAClC;AACA;CACC,WAAW;CACX,aAAa,EAAE,oCAAoC;AACpD;;AAEA;CACC,gBAAgB;CAChB,qCAAqC;CACrC,YAAY;CACZ,uBAAuB;AACxB","sourcesContent":[".wrapper {\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: center;\n\talign-items: center;\n\tpadding: 20px;\n}\nion-icon {\n\twidth: 70px;\n\theight: 70px;\n\tmargin-bottom: 15px;\n}\nion-button {\n\tmin-height: 40px;\n}\nh3,\np {\n\tmargin-bottom: 15px;\n\ttext-align: center;\n\tcolor: var(--ion-color-secondary);\n}\n#barcode {\n\twidth: 100%;\n\theight: 100px; /* Adjust as per the size you want */\n}\n\n.without_promo {\n\tmargin-top: 10px;\n\t--background: var(--ion-color-danger);\n\tcolor: white;\n\ttext-transform: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
