import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, viewChild } from '@angular/core';
import OptionGroup from '../../../smoothr-web-app-core/models/OptionGroup';
import ArticleOption from '../../../smoothr-web-app-core/models/ArticleOption';
import { DisplayIdentifier } from '../../../smoothr-web-app-core/enums/DisplayIdentifier';
import Article from '../../../smoothr-web-app-core/models/Article';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import { OrderType } from '../../../smoothr-web-app-core/enums/OrderType';
import { getAvailability } from '../../../smoothr-web-app-core/utils/utils';
import { AppComponent } from 'src/app/app.component';

@Component({
	selector: 'app-article-option-group-custom',
	templateUrl: './article-option-group-custom.component.html',
	styleUrls: ['article-option-group-custom.component.scss'],
})
export class ArticleOptionGroupCustomComponent implements OnInit, AfterViewInit {
	optionGroupValue: OptionGroup;
	@ViewChild('wrapperArticles') wrapperArticles: ElementRef<HTMLElement>;
	@Input() set optionGroup(value: OptionGroup) {
		if (value) {
			value.articles = value.articles.filter(it => !this.isHidden(it) && !it.hidden);
			this.optionGroupValue = value;
		}
	}
	@Input() article: Article;
	@Output() selection = new EventEmitter<ArticleOption>();
	@Input() preorderType: PreorderType;
	@Input() currency: string;
	@Input() customView: boolean = false;
	@Input() showAllButton = true;
	di = DisplayIdentifier;
	showFirstArticles = false;
	showAmount = AppComponent.largeScreen ? 4 : 3;
	showBackIcon = false;
	showNextIcon = false;
	constructor() {}

	private _selected: ArticleOption[] = [];

	get selected(): ArticleOption[] {
		return this._selected;
	}

	@Input()
	set selected(value: ArticleOption[]) {
		this._selected = value;
	}

	ngOnInit() {}

	ngAfterViewInit() {
		setTimeout(() => {
			this.updateScrollIcons();
		}, 1000);
	}
	ionViewDidEnter() {
		console.log('DID ENTER');
		this.updateScrollIcons();
	}
	onAdd(article: Article) {
		const articleOption = new ArticleOption();
		articleOption.article = article;
		articleOption.group = this.optionGroupValue._id;
		articleOption.quantity = 1;
		this.selection.emit(articleOption);
	}

	onRemove(article: Article) {
		const option = new ArticleOption();
		option.article = article;
		option.quantity = -1;
		option.group = this.optionGroupValue._id;
		this.selection.emit(option);
	}

	count(article: Article) {
		return this.selected.filter(ao => ao.article._id === article._id).reduce((prev, curr) => prev + curr.quantity, 0);
	}

	isHidden(article: Article): boolean {
		return this.preorderType && !getAvailability(article, OrderType.PREORDER, this.preorderType);
	}
	findArticleBrot(optionGroup: OptionGroup) {
		return optionGroup.articles.find(it => it.visible);
	}
	showAll(value: boolean) {
		this.showFirstArticles = value;
	}
	checkIfModifierSelectExist(optionGroup: OptionGroup) {
		return !!(optionGroup?.tags ?? []).find(it => it?.identifier === 'modifier_select');
	}
	checkIfModifierRemoveExist(optionGroup: OptionGroup) {
		return !!(optionGroup?.tags ?? []).find(it => it?.identifier === 'modifier_remove');
	}
	scrollBack() {
		const wrapper = this.wrapperArticles.nativeElement;
		wrapper.scrollBy({
			top: 0,
			left: -wrapper.clientWidth,
			behavior: 'smooth',
		});
	}
	scrollForward() {
		const wrapper = this.wrapperArticles.nativeElement;
		wrapper.scrollBy({
			top: 0,
			left: wrapper.clientWidth,
			behavior: 'smooth',
		});
	}

	onScroll() {
		this.updateScrollIcons();
	}

	updateScrollIcons() {
		if (this.showAmount && this.optionGroupValue?.articles && this.optionGroupValue?.articles?.length <= 3) {
			this.showBackIcon = false;
			this.showNextIcon = false;
			return;
		}
		const wrapper = this.wrapperArticles.nativeElement;
		const maxScrollLeft = wrapper.scrollWidth - wrapper.clientWidth;

		this.showBackIcon = wrapper.scrollLeft > 0;
		this.showNextIcon = wrapper.scrollLeft < maxScrollLeft;
	}
}
