import { apiEnvironment } from '../smoothr-web-app-core/environments/apiEnvironment';

export const losteriaCustomerGroup = 'dean_and_david';
export const customerGroup = losteriaCustomerGroup;

const supportEmail = {
	dean_and_david: 'dean_and_david@smoothr.de',
};
const firebaseConfig = {
	dean_and_david: {
		apiKey: 'AIzaSyBgQ_LSUpazFB09uhSYSrvdp6A6XVUVQGQ',
		authDomain: 'eam-outlet.firebaseapp.com',
		projectId: 'eam-outlet',
		storageBucket: 'eam-outlet.appspot.com',
		messagingSenderId: '415039699499',
		appId: '1:415039699499:web:94886645cf34f4e7f8886e',
		measurementId: 'G-72DG8HJSZR',
	},
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyDx99_I34aUK3cLhF7zTI6Uxcq6FP9qKvA',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup + '-dev',
	...apiEnvironment,
	countryList: ['de'],
	gaId: '',
	hasRecommendations: true,
	apiUrl: 'https://dev-dean-and-david.smoothr.de/',
	location: '',
	enableCashPayment: true,
	allowNavigationToWorldline: true,
	deactivateDelivery: false,
};

import 'zone.js';
