import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Api, PAYMENT_SERVICE } from '../../api/api';
import { ModalController } from '@ionic/angular';
import Payment from '../../models/Payment';
import { environment } from '../../../environments/environment';
import { PaymentMethod } from '../../enums/PaymentMethod';
import Venue from '../../models/Venue';
import Order from '../../models/Order';
import { PaymentStatus } from '../../models/PaymentStatus';
import Customer from '../../models/Customer';
import { OrderUtils } from '../../utils/order-utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { paymentFromOrders, sleep } from 'src/smoothr-web-app-core/utils/utils';
import { AnalyticsService } from '../../services/analytics/analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { AdyenCheckout, ApplePay, GooglePay } from '@adyen/adyen-web';
import { CustomCard } from '@adyen/adyen-web';
import { AppComponent } from 'src/app/app.component';

export enum PaymentModalResult {
	SUCCESS,
	ERROR,
	DISMISS,
}

// tslint:disable-next-line:prefer-const
declare var wpwlOptions: any;
// @ts-ignore
const applePayAvailable = !!window.ApplePaySession;

@Component({
	selector: 'app-payment-modal',
	templateUrl: './payment-modal.component.html',
	styleUrls: ['./payment-modal.component.scss'],
})
export class PaymentModalComponent implements OnInit {
	payment: Payment;
	venue: Venue;
	orders: Order[];
	customer: Customer;
	loading = false;
	paymentMethods: PaymentMethod[] = [];
	selectedPaymentMethod: PaymentMethod = null;
	pm = PaymentMethod;
	vrUrl = environment.baseUrl + PAYMENT_SERVICE + 'vr/pay/';
	orderUtils = OrderUtils;
	environment = environment;
	payments = {};
	wpwlOptionsBase = {
		locale: 'de',
		style: 'plain',
	};
	forceAddressInput: false;
	isAdyenLoading = false;
	@ViewChild('iframeContainer', { static: false }) iframeContainer: ElementRef;

	constructor(
		private modalCtrl: ModalController,
		private analytics: AnalyticsService,
		private snackBarCtrl: MatSnackBar,
		private translate: TranslateService,
		private cdr: ChangeDetectorRef
	) {}

	get order(): Order {
		return this.orders[0];
	}

	static async show(
		modalCtrl: ModalController,
		venue: Venue,
		orders: Order[],
		customer: Customer,
		tip: number = 0,
		forceAddressInput: boolean = false
	): Promise<{ result: PaymentModalResult; error: any; payment: Payment }> {
		const paymentMethods: PaymentMethod[] = [];
		if (venue.ccEnabled) {
			paymentMethods.push(PaymentMethod.CREDIT_CARD);
		}
		if (venue.gpEnabled) {
			paymentMethods.push(PaymentMethod.GPAY);
		}
		console.log(venue.apEnabled, applePayAvailable);
		if (venue.apEnabled && applePayAvailable) {
			paymentMethods.push(PaymentMethod.APAY);
		}
		if (venue.ppEnabled) {
			paymentMethods.push(PaymentMethod.PAYPAL);
		}
		if (venue.sbEnabled) {
			paymentMethods.push(PaymentMethod.SOFORTBANKING);
		}

		//TURN OF FOR TABILO
		if (venue.cashEnabled && venue.cashPreorderEnabled) {
			paymentMethods.push(PaymentMethod.CASH);
		}
		if (venue.ccvEnabled) {
			paymentMethods.push(PaymentMethod.CCV);
		}
		if (venue.adyenEnabled) {
			paymentMethods.push(PaymentMethod.ADYEN);
		}
		const modal = await modalCtrl.create({
			component: PaymentModalComponent,
			componentProps: {
				payment: paymentFromOrders(orders, tip),
				paymentMethods,
				venue,
				orders,
				customer,
				forceAddressInput,
			},
			backdropDismiss: false,
		});
		await modal.present();
		const result = await modal.onDidDismiss();
		await sleep(100);
		return result.data;
	}

	ngOnInit() {
		this.analytics.paymentModalOpened();
	}
	async tryToPayWithGooglePay(googleToken: string) {
		console.log('YES', googleToken);
		try {
			this.payment = {
				...this.payment,
				method: PaymentMethod.GPAY,
				adyenPayment: {
					gpToken: googleToken,
				},
			};
			const response = await Api.createPayment(this.payment);
			if (response.status == PaymentStatus.created) {
				await sleep(2500);
				const result = await Api.getPayment(response._id);
				if (result.data.status === PaymentStatus.done) {
					await this.modalCtrl.dismiss({
						result: PaymentModalResult.SUCCESS,
						payment: result,
					});
				}
			}
		} catch (e) {}
	}
	async loadGpayAdyen() {
		try {
			await sleep(500);

			const handlePayment = (state: any, component: any) => {
				console.log(state, component, state?.isValid, state?.data?.paymentMethod?.googlePayToken);
				if (state?.isValid && state?.data?.paymentMethod?.googlePayToken) {
					this.tryToPayWithGooglePay(state?.data?.paymentMethod?.googlePayToken);
				}
			};

			const handleAdditionalDetails = (state: any, component: any) => {
				console.log(state, component);
				// Handle additional details for 3DS, etc.
			};

			const configObj = {
				clientKey: 'test_AVFLPT5W6FEVJLMHFUQPOVIUNAJB7WZR',
				environment: 'test', // Change to 'live' for production
				countryCode: 'DE',
				currency: 'EUR',
				onSubmit: (state, component) => {
					handlePayment(state, component);
				},
				onAdditionalDetails: (state, component) => {
					handleAdditionalDetails(state, component);
				},
				locale: 'de_DE',
				analytics: {
					enabled: false, // Disable analytics to avoid CORS errors
				},
				paymentMethodsConfiguration: {
					googlepay: {
						environment: 'TEST' as any, // Change to 'PRODUCTION' for live
						amount: { currency: 'EUR', value: 1000 }, // Specify the amount
						configuration: {
							gatewayMerchantId: 'DeanAndDavid_EComtest_TEST',
							merchantId: 'DeanAndDavid_EComtest_TEST',
							merchantName: 'Your Merchant Name',
						},
						buttonColor: 'black', // Optional: Customize the button color
						buttonType: 'buy', // Optional: Customize the button type
						onPaymentDataChanged: paymentData => {
							console.log('Payment data changed:', paymentData);
							// Optional: Handle payment data changes
						},
						onPaymentAuthorized: paymentData => {
							console.log('Payment authorized:', paymentData);
							if (paymentData.paymentMethodData && paymentData.paymentMethodData.tokenizationData) {
								const gpayToken = paymentData.paymentMethodData.tokenizationData.token;
								this.tryToPayWithGooglePay(gpayToken);
							}
							return { transactionState: 'SUCCESS' }; // or 'ERROR' depending on your logic
						},
					},
				},
			} as any;

			const checkout = await AdyenCheckout(configObj);
			new GooglePay(checkout, {
				buttonType: 'checkout',
				buttonColor: 'white',

				// The 'merchant configuration' can be set, but in the majority of cases you don't have to, because the values are returned by your backend when retrieving the configured payment methods
				configuration: {
					gatewayMerchantId: 'DeanAndDavid_EComtest_TEST',
					merchantId: 'DeanAndDavid_EComtest_TEST',
					merchantName: 'Your Merchant Name',
				},
			}).mount('#gpay-container');
		} catch (e) {
			console.log('Error loading Google Pay:', e);
		}
	}

	async paymentMethodAdyen() {
		await sleep(1000);

		const configuration = {
			locale: 'de_DE',
			environment: 'test',
			clientKey: 'test_AVFLPT5W6FEVJLMHFUQPOVIUNAJB7WZR',
			countryCode: 'DE',
			currency: 'EUR',
			analytics: {
				enabled: false, //disable analytics cors errors
			},
			hasHolderName: true,
			holderNameRequired: true,
			billingAddressRequired: true,
		} as any;

		const checkout = await AdyenCheckout(configuration);

		const customCard = new CustomCard(checkout, {
			type: 'card',
			brands: ['mc', 'visa', 'amex', 'bcmc', 'maestro'],
			styles: {
				error: { color: 'red' },
				validated: { color: 'green' },
				placeholder: { color: '#d8d8d8' },
			},

			onLoad: function (value) {
				console.log('Custom Card Loaded', value);
			},
			onError: function (err) {
				console.log('Error:', err);
			},
		}).mount('#customCard-container');

		document.getElementById('payButton').addEventListener('click', () => {
			//Manuel submit form
			if (customCard.state.data && customCard.state.isValid) {
				const stateData = customCard.state.data;
				const object = {
					card: {
						number: stateData.encryptedCardNumber,
						month: stateData.encryptedExpiryMonth,
						year: stateData.encryptedExpiryYear,
						code: stateData.encryptedSecurityCode,
					},
				};
				this.startPaymentAdyen(object);
			}
			console.log(customCard.state);
			// customCard.submit();
		});
	}
	async startPaymentAdyen(data: {
		card: {
			number: any;
			month: any;
			year: any;
			code: any;
		};
	}) {
		try {
			this.isAdyenLoading = true;
			this.payment = {
				...this.payment,
				method: PaymentMethod.CREDIT_CARD,
				adyenPayment: data,
			};
			this.payment = await Api.createPayment(this.payment);
			if (this.payment.status === PaymentStatus.created) {
				await sleep(4000);
				const paymentData = (await Api.getPayment(this.payment._id)).data;
				console.log(paymentData);
				if (this.payment?.adyenPayment?.redirectUrl) {
					if (AppComponent.isNativeApp) {
						this.loadIframe(this.payment);

						return;
					}
					const form = document.createElement('form');
					form.method = 'POST';
					form.action = this.payment.adyenPayment.redirectUrl;

					const paReqInput = document.createElement('input');
					paReqInput.type = 'hidden';
					paReqInput.name = 'PaReq';
					paReqInput.value = this.payment.adyenPayment.cardAuthRequestPaReq;
					form.appendChild(paReqInput);

					const mdInput = document.createElement('input');
					mdInput.type = 'hidden';
					mdInput.name = 'MD';
					mdInput.value = this.payment.adyenPayment.cardPaymentSessionMd;
					form.appendChild(mdInput);

					document.body.appendChild(form);
					form.submit();
				}
			}
		} catch (e) {
			this.isAdyenLoading = false;
			console.log(e);
		}
	}
	loadIframe(payment: Payment) {
		const htmlContent = `
		  <!DOCTYPE html>
		  <html>
		  <head>
			<title>Payment Redirect</title>
		  </head>
		  <body>
			<script>
			let lastUrl = location.href;

			  function submitForm(payment, formParams) {
				const form = document.createElement('form');
				form.method = 'POST';
				form.action = '${payment.adyenPayment.redirectUrl}';
				const paReqInput = document.createElement('input');
				paReqInput.type = 'hidden';
				paReqInput.name = 'PaReq';
				paReqInput.value = '${payment.adyenPayment.cardAuthRequestPaReq}';
				form.appendChild(paReqInput);
				const mdInput = document.createElement('input');
				mdInput.type = 'hidden';
				mdInput.name = 'MD';
				mdInput.value = '${payment.adyenPayment.cardPaymentSessionMd}';
				form.appendChild(mdInput);
				document.body.appendChild(form);
				form.submit();
			  }
			  window.addEventListener('message', function(event) {
				console.log('Message received:', event.data); // Debug message
				if (event.data.type === 'setData') {
				  const payment = event.data.payment;
				  const formParams = event.data.formParams;
				  submitForm(payment, formParams);
				}
			  });
		  
			  // Start polling for URL changes
			</script>
		  </body>
		  </html>
		`;

		// Create a Blob URL for the HTML content
		const blob = new Blob([htmlContent], { type: 'text/html' });
		const url = URL.createObjectURL(blob);

		// Create and append the iframe
		const iframe = document.createElement('iframe');
		iframe.src = url;
		iframe.style.width = '100%';
		iframe.style.height = '75vh'; // Adjust height as needed
		iframe.id = 'custom_3d';

		this.iframeContainer.nativeElement.appendChild(iframe);

		// Clean up the Blob URL after use
		iframe.onload = param => {
			console.log('Iframe loaded ------', param);
			iframe.contentWindow.postMessage(
				{
					type: 'setData',
					payment: this.payment,
					formParams: this.payment,
				},
				'*'
			);
			this.checkPayment(this.payment);
			window.addEventListener('message', this.handleMessage.bind(this));

			URL.revokeObjectURL(url);
		};
	}

	handleMessage(event: MessageEvent) {}
	async checkPayment(payment: Payment, attept: number = 0) {
		setTimeout(async () => {
			try {
				const result = (await Api.getPayment(payment._id)).data;
				if (result.status != PaymentStatus.created) {
					this.snackBarCtrl.open('Payment was successfully done :)', null, {
						duration: 2500,
					});
					await this.modalCtrl.dismiss({
						result: PaymentModalResult.SUCCESS,
						payment: result,
					});
				}
			} catch (e) {}
		}, 1000);
	}
	async loadAdyenApay() {
		const initiateApplePay = () => {
			const paymentRequest = {
				countryCode: 'DE',
				currencyCode: 'EUR',
				environment: 'test',
				total: {
					label: 'Your Merchant Name',
					amount: '0.50',
				},
				clientKey: 'test_AVFLPT5W6FEVJLMHFUQPOVIUNAJB7WZR',
				supportedNetworks: ['visa', 'masterCard', 'amex'],
				merchantCapabilities: ['supports3DS'],
			};
			//@ts-ignore
			const session = new ApplePaySession(3, paymentRequest);

			session.onvalidatemerchant = event => {
				// Call your server to validate the merchant

				console.log(event);

				fetch('https://dev-api.smoothr.de/v3/payment/adyen/sessions/venues/66605316ad87cfffc93b3e12', {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
					},
				})
					.then(response => response.json())
					.then(merchantSession => {
						console.log(merchantSession);
						console.log(session);
						console.log(typeof merchantSession);
						session.completeMerchantValidation(merchantSession);
					})
					.catch(error => console.log('Merchant validation failed:', error));
			};

			session.onpaymentauthorized = event => {
				const paymentData = event.payment;
				console.log('paymentData', paymentData);
				// Process the payment via your backend (forward to Adyen)
				// fetch('/process-payment', {
				//   method: 'POST',
				//   body: JSON.stringify({ paymentData }),
				//   headers: {
				//     'Content-Type': 'application/json'
				//   }
				// })
				//     .then(response => response.json())
				//     .then(data => {
				//       session.completePayment(ApplePaySession.STATUS_SUCCESS);
				//     })
				//     .catch(error => {
				//       session.completePayment(ApplePaySession.STATUS_FAILURE);
				//       console.error('Payment processing failed:', error);
				//     });
			};

			session.begin();
		};
		initiateApplePay();

		// try {
		// 	await sleep(500);

		// 	const handlePayment = (state: any, component: any) => {
		// 		console.log(state, component, state?.isValid);
		// 	};

		// 	const handleAdditionalDetails = (state: any, component: any) => {
		// 		console.log(state, component);
		// 		// Handle additional details for 3DS, etc.
		// 	};

		// 	const configObj = {
		// 		clientKey: 'test_AVFLPT5W6FEVJLMHFUQPOVIUNAJB7WZR',
		// 		environment: 'test', // Change to 'live' for production
		// 		countryCode: 'DE',
		// 		currency: 'EUR',
		// 		onSubmit: (state, component) => {
		// 			handlePayment(state, component);
		// 		},
		// 		onAdditionalDetails: (state, component) => {
		// 			handleAdditionalDetails(state, component);
		// 		},
		// 		locale: 'de_DE',
		// 		analytics: {
		// 			enabled: false, // Disable analytics to avoid CORS errors
		// 		},
		// 		paymentMethodsConfiguration: {
		// 			applepay: {
		// 				environment: 'TEST' as any, // Change to 'PRODUCTION' for live
		// 				amount: { currency: 'EUR', value: 1000 }, // Specify the amount
		// 				configuration: {
		// 					gatewayMerchantId: 'DeanAndDavid_EComtest_TEST',
		// 					merchantId: 'DeanAndDavid_EComtest_TEST',
		// 					merchantName: 'Your Merchant Name',
		// 				},
		// 				buttonColor: 'black', // Optional: Customize the button color
		// 				buttonType: 'buy', // Optional: Customize the button type
		// 				onPaymentDataChanged: paymentData => {
		// 					console.log('Payment data changed:', paymentData);
		// 					// Optional: Handle payment data changes
		// 				},
		// 				onPaymentAuthorized: paymentData => {
		// 					console.log('Payment authorized:', paymentData);
		// 					if (paymentData.paymentMethodData && paymentData.paymentMethodData.tokenizationData) {
		// 						const gpayToken = paymentData.paymentMethodData.tokenizationData.token;
		// 						this.tryToPayWithGooglePay(gpayToken);
		// 					}
		// 					return { transactionState: 'SUCCESS' }; // or 'ERROR' depending on your logic
		// 				},
		// 			},
		// 		},
		// 	} as any;

		// 	const checkout = await AdyenCheckout(configObj);
		// 	new ApplePaySession()
		// 	new ApplePay(checkout, {
		// 		buttonColor: 'white',
		// 		amount: { currency: 'EUR', value: 1000 },
		// 		// The 'merchant configuration' can be set, but in the majority of cases you don't have to, because the values are returned by your backend when retrieving the configured payment methods
		// 		configuration: {
		// 			merchantId: 'DeanAndDavid_EComtest_TEST',
		// 			merchantName: 'Your Merchant Name',
		// 		},
		// 	}).mount('#applepay-container');
		// } catch (e) {
		// 	console.log('Error loading Google Pay:', e);
		// }
	}
	async loadPayment() {
		if (this.loading) {
			return;
		}
		this.loading = true;
		this.resetJs();
		this.payment._id = null;
		this.payment.method = this.selectedPaymentMethod;
		// reset payment container
		this.selectedPaymentMethod = null;
		wpwlOptions = null;
		this.cdr.detectChanges();
		this.selectedPaymentMethod = this.payment.method;

		try {
			switch (this.selectedPaymentMethod) {
				case PaymentMethod.PAYPAL:
					await this.loadPaypal();
					break;
				case PaymentMethod.CREDIT_CARD:
					await this.loadCreditCard();
					break;
				case PaymentMethod.SOFORTBANKING:
					await this.loadKlarna();
					break;
				case PaymentMethod.GPAY:
					if (this.venue.adyenEnabled) {
						await this.loadGpayAdyen();
					} else {
						await this.loadGpay();
					}
					break;
				case PaymentMethod.APAY:
					if (this.venue.adyenEnabled) {
						await this.loadAdyenApay();
					} else {
						await this.loadApay();
					}
					break;
				case PaymentMethod.CASH:
					await this.loadCashButton();
					break;
				case PaymentMethod.CCV:
					await this.ccvPay(PaymentMethod.CCV);
					break;
				case PaymentMethod.ADYEN:
					await this.paymentMethodAdyen();
					break;
			}
		} catch (e) {
			console.error(e);
			this.snackBarCtrl.open(this.translate.instant('payment_modal.error'));
			this.orders.forEach(order => {
				this.analytics.paymentLoadingFailed(order, this.selectedPaymentMethod);
			});
			this.selectedPaymentMethod = null;
			this.loading = false;
			return false;
		}
		this.loading = false;
		this.orders.forEach(order => {
			this.analytics.paymentLoaded(order, this.selectedPaymentMethod);
		});
	}

	async ccvPay(paymentMethod: PaymentMethod) {
		return new Promise<void>(async (resolve, reject) => {
			try {
				this.payment = await Api.createCCVPayment(this.venue._id, this.payment);
				this.payments[paymentMethod] = JSON.parse(JSON.stringify(this.payment));

				this.loading = false;
				// await this.navigateToPaymentPape(this.payment.walleePayment.url);

				resolve();
			} catch (e) {
				console.error(e, 'error while button setup');
				this.loading = false;
				reject();
			}
		});
	}
	loadVr(paymentMethod: PaymentMethod, paramsBuilder: (payment: Payment) => any): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const prevPayment = this.payments[paymentMethod];
				if (prevPayment) {
					console.log({ paymentMethod, wpwl: prevPayment.wpwlOptions });
					this.payment = prevPayment;
					wpwlOptions = JSON.parse(JSON.stringify(this.payment.wpwlOptions));
					console.log(wpwlOptions);
					await this.loadVrJs(this.payment.vrPayment.checkoutId);
					return;
				}
				this.payment = await Api.createPayment(this.payment);
				wpwlOptions = {
					...this.wpwlOptionsBase,
					...paramsBuilder(this.payment),
				};
				console.log({ paymentMethod, wpwl: wpwlOptions });
				this.payment.wpwlOptions = JSON.parse(JSON.stringify(wpwlOptions));
				this.payments[paymentMethod] = JSON.parse(JSON.stringify(this.payment));

				await this.loadVrJs(this.payment.vrPayment.checkoutId);
				resolve();
			} catch (e) {
				reject(e);
			}
		});
	}

	async loadCreditCard() {
		if (!this.venue.vrPaymentEnabled && this.venue.ppFullIntegrationEnabled) {
			await this.loadPaypalCreditCard();
		}
		await this.loadVr(PaymentMethod.CREDIT_CARD, payment => ({
			billingAddress: this.getBillingAddress(this.order, this.customer),
			total: payment.sum,
			mandatoryBillingFields: {
				country: true,
				state: false,
				city: true,
				postcode: true,
				street1: true,
			},
		}));
	}

	async loadGpay() {
		await this.loadVr(PaymentMethod.GPAY, payment => ({
			googlePay: {
				gatewayMerchantId: payment.merchantId,
				allowedAuthMethods: ['PAN_ONLY'],
				allowedCardNetworks: ['MASTERCARD', 'VISA'],
				merchantName: this.venue.name,
				merchantId: environment.GPAY.MERCHANT_ID,
				gateway: environment.GPAY.GATEWAY,
				assuranceDetailsRequired: true,
			},
		}));
	}

	async loadApay() {
		await this.loadVr(PaymentMethod.APAY, payment => ({
			applePay: {
				version: 3,
				displayName: this.venue.name,
				total: this.payment.sum,
				currencyCode: this.payment.currency,
				checkAvailability: 'canMakePayments',
				merchantIdentifier: environment.APAY_MERCHANT_ID,
				style: 'black',
				merchantCapabilities: ['supports3DS'],
				supportedNetworks: ['masterCard', 'visa'],
				shippingType: 'storePickup',
				createCheckout: () => {
					return this.payment.vrPayment.checkoutId;
				},
				onCancel: () => {
					console.log('onCancel');
					this.loading = false;
				},
				onPaymentAuthorized: applePayment => {
					console.log('onPaymentAuthorized payment: ' + JSON.stringify(applePayment));
					// this.payment.status = PaymentStatus.done;
					// this.orders.forEach(order => {
					// 	this.analytics.purchase(order, this.payment);
					// });
					// this.modalCtrl.dismiss({
					// 	result: PaymentModalResult.SUCCESS,
					// 	payment: this.payment,
					// });
				},
			},
		}));
	}

	getBillingAddress(order: Order, customer: Customer): { country: string; city: string; postcode: string; street1: string } {
		if (customer && customer.street) {
			return {
				country: customer.country.toUpperCase(),
				city: customer.city,
				postcode: customer.postalCode,
				street1: customer.street + ' ' + customer.number,
			};
		}
		if (this.forceAddressInput) {
			return {
				country: '',
				city: '',
				postcode: '',
				street1: '',
			};
		}
		return {
			country: order.preorder?.country?.toUpperCase(),
			city: order.preorder?.city,
			postcode: order.preorder?.postalCode,
			street1: order.preorder?.street + ' ' + order.preorder?.number,
		};
	}

	resetJs() {
		const head = document.getElementsByTagName('head')[0];
		const prevVrJs = document.getElementById('vr-js');
		if (prevVrJs) {
			head.removeChild(prevVrJs);
		}
		const prevPpJs = document.getElementById('paypal-js');
		if (prevPpJs) {
			head.removeChild(prevPpJs);
		}
	}

	loadVrJs(checkoutId: string): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			console.log('load: ' + environment.VR_URL + '?checkoutId=' + checkoutId);
			let widget = null;
			while (!widget) {
				this.cdr.detectChanges();
				await sleep(10);
				widget = document.getElementsByClassName('paymentWidgets')[0];
			}
			const head = document.getElementsByTagName('head')[0];
			const script = document.createElement('script');
			script.type = 'text/javascript';
			script.id = 'vr-js';
			script.src = `${environment.VR_URL}?checkoutId=${checkoutId}`;
			head.appendChild(script);
			script.onerror = () => {
				reject('Could not load script');
				this.loading = false;
			};
			script.onload = async () => {
				this.loading = false;

				resolve();
			};
		});
	}

	loadPaypal(): Promise<any> {
		return this.loadPaypalBase(PaymentMethod.PAYPAL, 'paypal-button');
	}

	loadKlarna(): Promise<any> {
		return this.loadPaypalBase(PaymentMethod.SOFORTBANKING, 'klarna-button');
	}

	loadPaypalCreditCard(): Promise<any> {
		return this.loadPaypalBase(PaymentMethod.CREDIT_CARD, 'paypal-cc-button');
	}

	loadPaypalJs(payment: Payment): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			const head = document.getElementsByTagName('head')[0];
			const script = document.createElement('script');
			script.type = 'text/javascript';
			script.id = 'paypal-js';
			script.src = environment.PAYPAL.JS_URL + payment.currency + '&merchant-id=' + payment.merchantId;
			// script.src =
			// 	'https://www.paypal.com/sdk/js?client-id=Ac5vAqN4prOBX3YUFPX_L9Bmz3ncsdmOgkz7LuE2zP3yDbIaJB0y3SD4CTBdJqoHQhcWP6-WMklA-thJ&integration-date=2020-09-04&components=buttons,funding-eligibility&currency=';
			script.setAttribute('data-partner-attribution-id', environment.PAYPAL.BN_CODE);
			head.appendChild(script);
			script.onerror = () => reject('Could not load script');
			script.onload = () => resolve();
		});
	}

	loadPaypalButton(payment: Payment, fundingSource: any, elementId: string): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			// @ts-ignore
			const button = paypal.Buttons({
				intent: 'capture',
				commit: true,
				vault: false,
				enableStandardCardFields: true,
				createOrder: (data, actions) => {
					return payment.paypal.orderId;
				},
				onCancel: close => {
					console.log('CANCEL');
					this.modalCtrl.dismiss({
						result: PaymentModalResult.ERROR,
						error: {
							error: PaymentMethod.PAYPAL,
							orderId: this.order._id,
						},
					});
					return;
				},

				onApprove: data => {
					this.finishPaypal(payment, data)
						.then(() => {
							console.log('PAYMENT DONE');
							resolve();
						})
						.catch(err => {
							console.log('ERROR DISMISS');
							reject(err);
						});
				},

				onError: err => {
					this.loading = true;
					console.log('ERRRROR IN PAYPAL PAYMENT');
					this.modalCtrl.dismiss({
						result: PaymentModalResult.ERROR,
						error: err,
					});
				},
				fundingSource,
			});
			if (!button.isEligible()) {
				reject('Button not eligible');
				return;
			}
			button.render('#' + elementId);
			resolve();
		});
	}

	async finishPaypal(payment: Payment, data: any) {
		this.loading = true;
		try {
			const result = await Api.finishPaypalPayment(data.orderID);
			console.log('RESULT finish payment');
			// this.orders.forEach(order => {
			// 	this.analytics.purchase(order, result);
			// });
			await this.modalCtrl.dismiss({
				result: PaymentModalResult.SUCCESS,
				payment: result,
			});
		} catch (e) {
			console.log('ERROR IN FINISHED PAYMENT');
			this.orders.forEach(order => {
				this.analytics.paymentFailed(order, payment, 'error finishing paypal ' + e.toString());
			});
			this.loading = false;
			throw e;
		}
	}

	loadPaypalBase(paymentMethod: PaymentMethod, elementId: string): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const prevPayment = this.payments[paymentMethod];
				if (prevPayment) {
					this.payment = prevPayment;
					await this.loadPaypalJs(this.payment);
					// @ts-ignore
					await this.loadPaypalButton(this.payment, this.paymentMethodToPaypalFunding(paymentMethod), elementId);
					resolve();
					return;
				}
				this.payment = await Api.createPayment(this.payment);
				this.payments[paymentMethod] = JSON.parse(JSON.stringify(this.payment));

				await this.loadPaypalJs(this.payment);
				// @ts-ignore
				await this.loadPaypalButton(this.payment, this.paymentMethodToPaypalFunding(paymentMethod), elementId);
				resolve();
			} catch (e) {
				console.error('error while paypal setup');
				reject();
			}
		});
	}

	paymentMethodToPaypalFunding(paymentMethod: PaymentMethod): any {
		switch (paymentMethod) {
			case PaymentMethod.CREDIT_CARD:
				// @ts-ignore
				return paypal.FUNDING.CARD;
			case PaymentMethod.SOFORTBANKING:
				// @ts-ignore
				return paypal.FUNDING.CARD;
			case PaymentMethod.PAYPAL:
				// @ts-ignore
				return paypal.FUNDING.PAYPAL;
			default:
				return null;
		}
	}

	async dismiss() {
		if (this.loading) {
			return;
		}
		if (this.orders[0]?.sanifairVouchers?.length > 0) {
			// try {
			// 	await Api.extendSanifairVoucher(this.orders[0]?._id);
			// } catch (e) {
			// 	this.snackBarCtrl.open('Smth go wrong while extend order...', null, {
			// 		duration: 4000
			// 	});
			// }
		}
		this.modalCtrl.dismiss({
			result: PaymentModalResult.DISMISS,
		});
	}
	async loadCashButton() {
		this.selectedPaymentMethod = PaymentMethod.CASH;
	}
	async loadCashPay() {
		console.log('Cash Payment');
		this.loading = true;
		this.payment = await Api.createPayment(this.payment);
		this.loading = false;
		this.modalCtrl.dismiss({
			result: PaymentModalResult.SUCCESS,
			payment: this.payment,
		});
	}
	navigateCvv(url: string) {
		window.location.replace(url);
	}
}
