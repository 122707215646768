import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import Article from '../../../smoothr-web-app-core/models/Article';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import { OrderType } from '../../../smoothr-web-app-core/enums/OrderType';
import ArticleGroup from '../../../smoothr-web-app-core/models/ArticleGroup';
import { OrderUtils } from '../../../smoothr-web-app-core/utils/order-utils';
import { environment } from 'src/environments/environment';
import { defaultsToArticleOption, getBasePrice, getPrice, numberD, numberToCurrency } from '../../../smoothr-web-app-core/utils/utils';
import { TOGGLE_TAG } from '../menu-filters/menu-filters.component';
import { TranslateService } from '@ngx-translate/core';
import { ValidationUtils } from 'src/smoothr-web-app-core/utils/validation-utils';
import OptionGroup from 'src/smoothr-web-app-core/models/OptionGroup';
import { AllergensInfoModalComponent } from '../allergens-info-modal/allergens-info-modal.component';
import { ModalController } from '@ionic/angular';
import Order from 'src/smoothr-web-app-core/models/Order';
export enum shoGif {
	halbHabl = 'halb_halb',
	buildOwn = 'build_own',
	ownSalate = 'own_salate',
	default = null,
}
@Component({
	selector: 'app-item-view',
	templateUrl: './item-view.component.html',
	styleUrls: ['item-view.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemViewComponent implements OnInit {
	public order: Order;
	@Input() currency: string;
	@Input() selectedToggleTag: TOGGLE_TAG;
	price: number;
	ot = OrderType;
	environment = environment;
	@Input() hasOrder = false;
	@Input() set orderValue(value: Order) {
		this.order = value;
		this.findAmountOfItems();
	}
	landscape = false;
	priceText = '';
	numberToCurrency = numberToCurrency;
	fromPrice = false;
	showGif = shoGif.default;
	measurmentText: string = '';
	basePrice = '';
	checkQuantityIfHasInBasket = 0;
	findFeatureArray: string[] = [];
	checkVeganFeatureValue: string = '';
	showMostWanted = false;
	constructor(
		private translate: TranslateService,
		private modalCtrl: ModalController
	) {}

	private _article: Article;

	get article(): Article {
		return this._article;
	}

	@Input()
	set article(value: Article) {
		this._article = value;
		this.reloadPrice();
		this.checkMeasurmentPlusPfand(value);
		this.showMeasurement(value);
		// this.checkIfLandscape(value.assets.large)
	}

	private _preorderType: PreorderType = null;

	get preorderType(): PreorderType {
		return this._preorderType;
	}

	@Input()
	set preorderType(value: PreorderType) {
		this._preorderType = value;
		this.reloadPrice();
		this.showMeasurement(this._article);
	}
	private minOfItem<T>(items: T[], transformer: (item: T) => number): T {
		if (items.length === 1) {
			return items[0];
		}
		const values = items.map(item => transformer(item));
		const min = Math.min(...values);
		const index = values.indexOf(min);
		return items[index];
	}
	ngOnInit(): void {
		setTimeout(() => {
			this.findAmountOfItems();
		}, 1000);
	}
	findAmountOfItems() {
		if (this.order && this.article) {
			if (this.order.orderedArticles.length > 0) {
				const foundItems = this.order.orderedArticles.filter(it => it.article._id === this.article._id);
				if (foundItems) {
					this.checkQuantityIfHasInBasket = foundItems.reduce((a, b) => a + b.quantity, 0);
				}
			} else {
				this.checkQuantityIfHasInBasket = 0;
			}
		}
	}
	private reloadPrice() {
		this.fromPrice = false;
		const articleGroup = new ArticleGroup();
		articleGroup.article = this.article;
		articleGroup.groups = defaultsToArticleOption(this.article, [], [], OrderType.PREORDER, this.preorderType);

		articleGroup.quantity = 1;

		const article = JSON.parse(JSON.stringify(articleGroup.article)) as Article;
		let price = OrderUtils.totalPrice(articleGroup, this.order?.type, this.preorderType);

		const processedGroups: string[] = [];

		if (article.tags.find(it => it.identifier === 'most_wanted')) {
			this.showMostWanted = true;
		}
		article.groups.forEach(grp => {
			if (grp.requiredAmount > 0 && grp.visible && grp.articles.length > 0) {
				this.fromPrice = true;
			}
			if (processedGroups.indexOf(grp._id) >= 0) return;
			if (ValidationUtils.isGroupDependencyFulfilled(articleGroup.article, articleGroup.groups, grp).times < 0) return;
			if (grp.requiredAmount == grp.limit) {
				const minimumItem = this.minOfItem(grp.articles, opt => numberD(opt.price));

				price += numberD(minimumItem.price) * grp.requiredAmount;
				processedGroups.push(grp._id);
			}
		});
		const tagPrice = this.findTagPrice(this.article);
		this.price = price;
		this.priceText = numberToCurrency(this.price, this.currency);

		this.checkVeganFeature(this.article);
		this.findFeature(this.article);

		return;
	}
	checkVeganFeature(article: Article) {
		this.checkVeganFeatureValue = Object.entries(article.compound?.features)
			.filter(it => it[0] == 'vegan' || it[0] == 'vegetarian')
			.filter(it => !!it[1])
			.map(it => it[0])[0];
	}
	findFeature(article: Article) {
		if (article?.compound?.features) {
			delete article.compound?.features?._id;
			delete article.compound?.features?.updatedAt;
			delete article.compound?.features?.createdAt;
			delete article.compound?.features?.createdAt;

			this.findFeatureArray = Object.entries(article.compound?.features)
				.filter(
					it => it[0] == 'new'
					// || it[0] == 'special' || it[0] == 'topSelling'
				)
				.filter(it => !!it[1])
				.map(it => it[0]);
			return;
		}
		this.findFeatureArray = [];
	}
	dependenciesRegardingOptionArticle(article: Article, groups: OptionGroup[], optionArticle: Article): OptionGroup[] {
		return article.groupDependencies
			.map(gd => {
				if (gd.dependencies.find(dependeci => dependeci.groupArticles.includes(optionArticle._id)) == null) {
					return null;
				} else {
					return groups.find(it => gd.group === it._id);
				}
			})
			.filter(it => !!it);
	}

	checkIfLandscape(src: string) {
		const image = new Image();
		image.src = src;
		var height = image.height;
		var width = image.width;
		console.log('checkIfLandscape', height, width);

		if (width > height) {
			console.log(width, height);
			this.landscape = true;
		}
	}
	hasTag(article: Article) {
		if (!article?.compound?.features) {
			return [];
		}
		const tagArray = [];
		if (article.compound?.features?.vegetarian) {
			tagArray.push('vegetarian');
		}
		if (article.compound?.features?.vegan) {
			tagArray.push('vegan');
		}
		//TODO: test
		if (article?.compound?.additives?.containsAlcohol) {
			tagArray.push('alcohol');
		}

		return tagArray;
	}
	checkIfOwnPizza(article: Article) {
		return article?.number == '40144';
	}

	showMeasurement(article: Article) {
		if (article && this._preorderType) {
			if (article?.measurement?.refAmount && article?.measurement?.refUnit && article.measurement.amount && this._preorderType) {
				if (article?.measurement?.refAmount == 100 && article?.measurement?.refUnit === 'g') {
					this.basePrice =
						'1' +
						' ' +
						'kg' +
						' = ' +
						numberToCurrency(getBasePrice(article, OrderType.PREORDER, this._preorderType) * 10, this.currency);
					return;
				}
				if (article?.measurement?.refAmount == 100 && article?.measurement?.refUnit === 'ml') {
					this.basePrice =
						'1' +
						' ' +
						'l' +
						' = ' +
						numberToCurrency(getBasePrice(article, OrderType.PREORDER, this._preorderType) * 10, this.currency);
					return;
				}
				this.basePrice =
					article.measurement.refAmount +
					' ' +
					article.measurement.refUnit +
					' = ' +
					numberToCurrency(getBasePrice(article, OrderType.PREORDER, this._preorderType), this.currency);
				return;
			}
		} else {
			return (this.basePrice = '');
		}
	}
	getArticlePrice(article: Article) {
		let totalPrice = getPrice(article, OrderType.PREORDER, this.preorderType);
		const tagPrice = this.findTagPrice(this.article);
		if (tagPrice && tagPrice > 0) {
			totalPrice = totalPrice - tagPrice;
		}
		return totalPrice;
	}
	checkPfandTag(article: Article) {
		if (article.tags?.length === 0) {
			return '';
		}
		const deposit25 = article.tags.find(it => it.identifier === 'deposit25') ? 'deposit25' : '';
		const deposit15 = article.tags.find(it => it.identifier === 'deposit15') ? 'deposit15' : '';
		const deposit8 = article.tags.find(it => it.identifier === 'deposit08') ? 'deposit08' : '';
		const deposit28 = article.tags.find(it => it.identifier === 'deposit28') ? 'deposit28' : '';

		return deposit15 || deposit25 || deposit8 || deposit28;
	}
	checkMeasurmentPlusPfand(article: Article) {
		if (!article) {
			return;
		}
		let result = '';

		const pfandValue = this.checkPfandTag(article);
		if (pfandValue) {
			result =
				this.translate.instant('pfand_text.zzgl') +
				numberToCurrency(this.getPricePfandValue(pfandValue), this.currency) +
				this.translate.instant('pfand_text.pfand') +
				'<br/>';
		} else {
			if (result.length > 0) {
				result = result + ')';
			}
		}

		this.measurmentText = result;
	}

	getPricePfandValue(it: string) {
		if (it === 'deposit25') {
			return 0.25;
		}
		if (it === 'deposit15') {
			return 0.15;
		}
		if (it === 'deposit08') {
			return 0.08;
		}
		if (it === 'deposit28') {
			return 0.28;
		}
		return 0;
	}
	findTagPrice(article: Article) {
		if (article && article.tags && article.tags.length > 0) {
			const foundTag = article?.tags?.find(
				it =>
					it?.identifier === 'deposit25' ||
					it?.identifier === 'deposit15' ||
					it?.identifier === 'deposit08' ||
					it?.identifier === 'deposit28'
			);
			if (foundTag) {
				if (foundTag.identifier === 'deposit25') {
					return 0.25;
				}
				if (foundTag.identifier === 'deposit15') {
					return 0.15;
				}
				if (foundTag.identifier === 'deposit08') {
					return 0.08;
				}
				if (foundTag.identifier === 'deposit28') {
					return 0.28;
				}
				return 0;
			}
			return null;
		} else {
			return null;
		}
	}
	showNutrition(article: Article) {
		AllergensInfoModalComponent.show(this.modalCtrl, article);
	}
}
