import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';
import { HomePage } from './pages/home/home.page';
import { OrderPage } from './pages/order/order.page';
import { SignUpPage } from './pages/sign-up/sign-up.page';
import { SignInPage } from './pages/sign-in/sign-in.page';
import { MyOrdersPage } from './pages/my-orders/my-orders.page';
import { PaymentSuccessPage } from './pages/payment-success/payment-success.page';
import { MenuPage } from './pages/menu/menu.page';
import { VenuePage } from './pages/venue/venue.page';
import { EmailConfirmationPage } from './pages/email-confirmation/email-confirmation.page';
import { AccountPage } from './pages/account/account.page';
import { ModalNavigationGuard } from './modal-navigation-guard';
import { PrivacyPage } from './pages/privacy/privacy.page';
import { TosPage } from './pages/tos/tos.page';
import { ImpressumPage } from './pages/impressum/impressum.page';
import { EmailActionPage } from './pages/email-action/email-action.page';
import { MaintenancePage } from './pages/maintenance/maintenance.page';
import { SignInOrderPage } from './pages/sign-in-order/sign-in-order.page';
import { CheckOrderGuard } from './guard/check-order-guard';
import { MapPage } from './pages/map/map.page';
import { VenueLinkPage } from './pages/venue-link/venue-link.page';
import { NAVIGATIONURLS } from './services/navigation.service';

const routes: Routes = [
	{
		path: '',
		redirectTo: HomePage.url,
		pathMatch: 'full',
		canDeactivate: [ModalNavigationGuard],
	},
	{
		path: OrderPage.cancelUrlWithPaymentParam,
		redirectTo: OrderPage.paymentCancelUrl,
	},
	{
		path: OrderPage.failUrlWithPaymentParam,
		redirectTo: OrderPage.paymentFailUrl,
	},
	{
		path: HomePage.url,
		loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
		canDeactivate: [ModalNavigationGuard],
	},
	{
		path: MenuPage.url,
		loadChildren: () => import('./pages/menu/menu.module').then(m => m.MenuPageModule),
		canActivate: [CheckOrderGuard],
		canDeactivate: [ModalNavigationGuard],
	},
	{
		path: NAVIGATIONURLS.foodSpot(),
		loadChildren: () => import('./pages/foodspot/foodspot.module').then(m => m.FoodSpotPageModule),
		canActivate: [CheckOrderGuard],
		canDeactivate: [ModalNavigationGuard],
	},
	{
		path: PaymentSuccessPage.urlWithPaymentParam,
		loadChildren: () => import('./pages/payment-success/payment-success.module').then(m => m.SuccessPageModule),
		canDeactivate: [ModalNavigationGuard],
	},
	{
		path: PaymentSuccessPage.url,
		loadChildren: () => import('./pages/payment-success/payment-success.module').then(m => m.SuccessPageModule),
		canDeactivate: [ModalNavigationGuard],
	},
	{
		path: OrderPage.paymentFailUrl,
		loadChildren: () => import('./pages/order/order.module').then(m => m.OrderPageModule),
		canDeactivate: [ModalNavigationGuard],
	},
	{
		path: OrderPage.paymentCancelUrl,
		loadChildren: () => import('./pages/order/order.module').then(m => m.OrderPageModule),
		canDeactivate: [ModalNavigationGuard],
	},
	{
		path: OrderPage.url,
		loadChildren: () => import('./pages/order/order.module').then(m => m.OrderPageModule),
		canDeactivate: [ModalNavigationGuard],
	},
	{
		path: SignUpPage.url,
		loadChildren: () => import('./pages/sign-up/sign-up.module').then(m => m.SignUpPageModule),
		canDeactivate: [ModalNavigationGuard],
	},
	{
		path: SignUpPage.userDataUrl,
		loadChildren: () => import('./pages/sign-up/sign-up.module').then(m => m.SignUpPageModule),
		canDeactivate: [ModalNavigationGuard],
	},

	{
		path: SignInPage.url,
		loadChildren: () => import('./pages/sign-in/sign-in.module').then(m => m.SignInPageModule),
		canDeactivate: [ModalNavigationGuard],
	},
	{
		path: SignInOrderPage.url,
		loadChildren: () => import('./pages/sign-in-order/sign-in-order.module').then(m => m.SignInOrderPageModule),
		canDeactivate: [ModalNavigationGuard],
	},
	{
		path: MyOrdersPage.url,
		loadChildren: () => import('./pages/my-orders/my-orders.module').then(m => m.MyOrdersPageModule),
		canDeactivate: [ModalNavigationGuard],
	},
	{
		path: VenuePage.url,
		loadChildren: () => import('./pages/venue/venue.module').then(m => m.VenuePageModule),
		canDeactivate: [ModalNavigationGuard],
	},
	{
		path: EmailConfirmationPage.url,
		loadChildren: () => import('./pages/email-confirmation/email-confirmation.module').then(m => m.EmailConfirmationPageModule),
		canDeactivate: [ModalNavigationGuard],
	},
	{
		path: AccountPage.url,
		loadChildren: () => import('./pages/account/account.module').then(m => m.AccountPageModule),
		canDeactivate: [ModalNavigationGuard],
	},
	{
		path: ImpressumPage.url,
		loadChildren: () => import('./pages/impressum/impressum.module').then(m => m.ImpressumPageModule),
		canDeactivate: [ModalNavigationGuard],
	},
	{
		path: TosPage.url,
		loadChildren: () => import('./pages/tos/tos.module').then(m => m.TosPageModule),
		canDeactivate: [ModalNavigationGuard],
	},
	{
		path: PrivacyPage.url,
		loadChildren: () => import('./pages/privacy/privacy.module').then(m => m.PrivacyPageModule),
		canDeactivate: [ModalNavigationGuard],
	},
	{
		path: MaintenancePage.url,
		loadChildren: () => import('./pages/maintenance/maintenance.page.module').then(m => m.MaintenancePageModule),
	},
	{
		path: EmailActionPage.url,
		loadChildren: () => import('./pages/email-action/email-action.module').then(m => m.EmailActionPageModule),
	},
	{
		path: MapPage.url,
		loadChildren: () => import('./pages/map/map.module').then(m => m.MapPageModule),
	},
	{
		path: NAVIGATIONURLS.cookie(),
		loadChildren: () => import('./pages/cookie/cookie.module').then(m => m.CookiePageModule),
	},
	{
		path: NAVIGATIONURLS.privacyApp(),
		loadChildren: () => import('./pages/privacy-app/privacy-app.module').then(m => m.PrivacyAppPageModule),
	},
	{
		path: NAVIGATIONURLS.mapTesting(),
		loadChildren: () => import('./pages/map-testing/map-testing.module').then(m => m.MapPageModule),
	},
	{
		path: NAVIGATIONURLS.promo(),
		loadChildren: () => import('./pages/promotions/promotions.module').then(m => m.PromotionsPageModule),
	},
	// {
	// 	path: VenueLinkPage.urlWithParam,
	// 	loadChildren: () => import('./pages/venue-link/venue-link.module').then(m => m.VenueLinkPageModule),
	// 	canDeactivate: [ModalNavigationGuard],
	// },
	{
		path: '**',
		redirectTo: '/',
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
