import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-menu-list-filters',
	templateUrl: './menu-list-filters.component.html',
	styleUrls: ['./menu-list-filters.component.scss'],
})
export class MenuListFiltersComponent implements OnInit {
	addedFilters: string[] = [];
	@Input() set addedFiltersValue(value: string[]) {
		this.addedFilters = value ?? [];
		console.log('HERE', this.addedFilters);
	}
	@Output() selectedFilters = new EventEmitter<string[]>();
	public listFilters = [
		{
			value: 'vegan',
			name: 'features.vegan',
		},
		{
			value: 'vegetarian',
			name: 'features.vegetarian',
		},
	];
	constructor(public translate: TranslateService) {}

	ngOnInit() {}
	selectFilters() {
		this.selectedFilters.emit(this.addedFilters);
	}
	changeVariable(event: any, value: string) {
		if (event?.detail?.checked) {
			this.addedFilters.push(value);
		} else {
			this.addedFilters = this.addedFilters.filter(it => it != value);
		}
	}
}
