// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-toolbar {
  padding: 0 12px;
  --background: var(--account-toolbar-background);
}
ion-toolbar ion-icon {
  height: 19px;
  width: 19px;
}
ion-toolbar ion-title {
  font-family: AppFontBold, sans-serif !important;
  color: var(--ion-color-secondary);
}`, "",{"version":3,"sources":["webpack://./src/app/components/account-toolbar/account-toolbar.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,+CAAA;AACF;AAAE;EACE,YAAA;EACA,WAAA;AAEJ;AAAE;EAEE,+CAAA;EACA,iCAAA;AACJ","sourcesContent":["ion-toolbar {\n  padding: 0 12px;\n  --background: var(--account-toolbar-background);\n  ion-icon {\n    height: 19px;\n    width: 19px;\n  }\n  ion-title {\n\n    font-family: AppFontBold, sans-serif !important;\n    color: var(--ion-color-secondary);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
