import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { Api } from '../../../smoothr-web-app-core/api/api';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import { Router } from '@angular/router';
import { PaymentStatus } from '../../../smoothr-web-app-core/models/PaymentStatus';
import * as moment from 'moment';
import Order from '../../../smoothr-web-app-core/models/Order';
import { MenuPage } from '../menu/menu.page';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import { NavigationService } from 'src/app/services/navigation.service';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'app-my-orders',
	templateUrl: './my-orders.page.html',
	styleUrls: ['my-orders.page.scss'],
})
export class MyOrdersPage extends RepositoryDirective {
	static url = 'my-orders';

	orders: Order[] = [];
	loading = false;
	@ViewChild('swiperMyOrders', { static: false }) slides;

	slideOptions = {
		spaceBetween: 0,
		initialSlide: 0,
		speed: 400,
		allowTouchMove: false,
	};
	constructor(
		public repository: RepositoryService,
		private cdr: ChangeDetectorRef,
		private router: Router,
		private navigationService: NavigationService,
		private location: Location,
		public translate: TranslateService,
		private snackCTRL: MatSnackBar
	) {
		super(repository);
	}

	get openOrders(): Order[] {
		return this.orders.filter(value => moment(value.orderAt).add(30, 'minutes').isSameOrAfter(moment()));
	}

	get doneOrders(): Order[] {
		return this.orders.filter(value => moment(value.orderAt).add(30, 'minutes').isBefore(moment()));
	}

	// static navigate(router: Router) {
	// 	router.navigateByUrl(MyOrdersPage.url);
	// }

	onCustomer() {
		super.onCustomer();
		this.loadOrders();
	}

	loadOrders() {
		this.orders = [];
		if (!this.customer) {
			console.log('user not loaded');
			this.cdr.detectChanges();
			return;
		}
		Api.getCustomerOrders(this.customer.uid)
			.then(res => {
				this.orders = res.data.withPayment;
				console.log(this.orders);
				this.loading = false;
				this.cdr.detectChanges();
			})
			.catch(err => {
				console.log(err);
				this.loading = false;
				this.cdr.detectChanges();
			});
	}

	async goBack() {
		if (this.location) {
			this.location.back();
			return;
		}
		await this.navigationService.menu();
	}
	async selectOrder() {
		this.loading = true;
		await this.slides.nativeElement?.swiper.update;

		const orderCode = this.getElementIndex(this.slides?.nativeElement);
		if (!orderCode) {
			this.loading = false;
			return;
		}
		const foundOrder = this.orders.find(it => it._id == orderCode);
		if (!foundOrder) {
			this.loading = false;

			return;
		}
		try {
			await this.repository.reorder(this.translate, foundOrder);
			this.navigationService.order();
			this.loading = false;
		} catch (e) {
			this.loading = false;

			this.snackCTRL.open('Sorry, have problems with making order...', null, {
				duration: 4000,
			});
		}
	}
	getElementIndex(element: any) {
		const arrayOfSwiperElements = element.querySelectorAll('.slide');
		let activeIndex: any = -1;
		arrayOfSwiperElements.forEach((slide, index) => {
			if (slide.classList.contains('swiper-slide-active')) {
				const slideId = slide.getAttribute('id');
				activeIndex = slideId;
				return;
			}
		});
		if (activeIndex < 0) {
			activeIndex = this.orders[0]?._id;
		}
		return activeIndex;
	}
}
