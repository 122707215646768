import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Api } from '../../../smoothr-web-app-core/api/api';
import Order from '../../../smoothr-web-app-core/models/Order';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { OrderUtils } from '../../../smoothr-web-app-core/utils/order-utils';
import { SignInPage } from '../../pages/sign-in/sign-in.page';
import { SignUpPage } from '../../pages/sign-up/sign-up.page';
import { Router } from '@angular/router';
import { axiosErrorToMessage, numberToCurrency } from '../../../smoothr-web-app-core/utils/utils';
import { AnalyticsService } from '../../../smoothr-web-app-core/services/analytics/analytics.service';
import { CustomerAuth } from '../../../smoothr-web-app-core/models/CustomerAuth';
import Customer from '../../../smoothr-web-app-core/models/Customer';
import { NavigationService, NAVIGATIONURLS } from 'src/app/services/navigation.service';
import { PromoCodeType } from 'src/smoothr-web-app-core/models/PromoCodeType';
import ArticleGroup from 'src/smoothr-web-app-core/models/ArticleGroup';
import { ModalController } from '@ionic/angular';
import { AppComponent } from 'src/app/app.component';
import { ModalInfoComponent } from '../modal-info/modal-info.component';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import Article from 'src/smoothr-web-app-core/models/Article';

@Component({
	selector: 'app-promo-code',
	templateUrl: './promo-code.component.html',
	styleUrls: ['promo-code.component.scss'],
})
export class PromoCodeComponent implements OnInit, OnDestroy {
	@Output()
	error = new EventEmitter<string>();
	@Output()
	success = new EventEmitter<Order>();
	@Output()
	deletePromo = new EventEmitter<Order>();
	private _customer: Customer;
	numberToCurrency = numberToCurrency;
	ou = OrderUtils;
	get customer(): Customer {
		return this._customer;
	}
	@Input() venue: Venue;

	@Input()
	set customer(value) {
		this._customer = value;
		// this.inactive = this._customer?.isAnonymous || !this._customer?.emailVerifiedAt;
		this.inactive = false;
	}

	input: string;
	showInput = true;
	loading = false;
	inactive = true;
	private _error: string;
	private deletePromoSubscription: Subscription;

	constructor(
		private repository: RepositoryService,
		private translate: TranslateService,
		private analytics: AnalyticsService,
		private cdr: ChangeDetectorRef,
		private router: Router,
		private navigationService: NavigationService,
		private modalCtrl: ModalController
	) {}

	private _order: Order;

	get order(): Order {
		return this._order;
	}

	@Input()
	set order(order: Order) {
		this._order = order;
		if (this._order && this._order.promoCode && this._order.promoCode.code) {
			this.showInput = false;
			this.input = this._order.promoCode.code;
		}
	}

	get promoError(): string {
		return this._error;
	}

	set promoError(value: string) {
		this._error = value;
		if (this._error) {
			this.loading = false;
			this.error.emit(value);
		}
	}

	ngOnInit() {
		this.deletePromoSubscription = this.deletePromo.subscribe(() => (this.promoError = null));
	}

	ngOnDestroy() {
		this.deletePromoSubscription.unsubscribe();
		this.deletePromoSubscription = null;
	}

	async checkPromoCode() {
		console.log('VENUE', this.venue);

		if (!this.input || this.input.replace(' ', '').length === 0) {
			this.promoError = this.translate.instant('promo_code.not_valid');
			return;
		}
		this.loading = true;
		this.promoError = null;
		try {
			const promoCode = (
				await Api.getPromoCode({
					code: this.input,
					venue: this.order.venue,
				})
			).data;
			promoCode.mov = +promoCode.mov;
			if (OrderUtils.orderTotalPrice(this.order, false, false) < promoCode.mov) {
				this.promoError = this.translate.instant('promo_code.mov_not_reached', {
					mov: numberToCurrency(promoCode.mov, this.order.currency),
				});
				return;
			}
			console.log('VENUE', this.venue);
			if (promoCode && promoCode.type == PromoCodeType.COUPON) {
				try {
					const orderCopy = JSON.parse(JSON.stringify(this.order));
					orderCopy.promoCode = promoCode;
					orderCopy.userUid = this.repository.customer.uid;
					orderCopy.orderAt = null;
					const responseOrder = await Api.applyPromoCode(orderCopy);
					if (responseOrder.data) {
						const foundPromo = responseOrder.data.orderedArticles.find(
							it => it.isPromo && promoCode.value.find(promovalue => promovalue == it.article._id)
						);
						if (!foundPromo) {
							this.loading = false;
							this.showInput = false;
							return;
						}
						const mappedFoundPromo = ([].concat(...this.venue.articleCategories.map(it => it.articles)) as Article[]).find(
							it => {
								console.log(it, it._id, foundPromo, foundPromo.article._id);
								return it._id === foundPromo.article._id;
							}
						);
						console.log('SHOW GROUP', foundPromo, mappedFoundPromo);
						if (!mappedFoundPromo) {
							this.loading = false;
							this.showInput = false;
							return;
						}
						foundPromo.article = mappedFoundPromo;
						console.log('FOUND Promo', foundPromo);
						const showModalConfig = await this.openModal(foundPromo);
						if (!showModalConfig) {
							this.loading = false;
							this.showInput = false;
							return;
						}
						this.order.orderedArticles.push(showModalConfig);

						this.success.emit(
							OrderUtils.applyPromo(this.translate, this.repository._venue, this.order, promoCode, this.analytics)
						);
					}
					this.loading = false;
					this.showInput = false;
					return;
				} catch (e) {
					this.promoError = axiosErrorToMessage(this.translate, e);
					this.loading = false;
					this.showInput = false;
					return;
				}
			}
			try {
				try {
					const orderCopy = JSON.parse(JSON.stringify(this.order));
					orderCopy.promoCode = promoCode;
					orderCopy.userUid = this.repository.customer.uid;
					orderCopy.orderAt = null;
					await Api.applyPromoCode(orderCopy);
				} catch (e) {
					this.promoError = axiosErrorToMessage(this.translate, e);
					return;
				}
				this.success.emit(OrderUtils.applyPromo(this.translate, this.repository._venue, this.order, promoCode, this.analytics));
				this.loading = false;
				this.showInput = false;
			} catch (e) {
				this.promoError = e;
				this.loading = false;
			}
		} catch (e) {
			this.loading = false;
			if (e.response.status === 404) {
				this.promoError = this.translate.instant('promo_code.not_valid');
				return;
			}
			this.promoError = axiosErrorToMessage(this.translate, e);
			return;
		}
	}

	resetError() {
		if (this._error) {
			this.promoError = null;
		}
	}

	editPromo() {
		const promoCode = this.order.promoCode;
		this.order = OrderUtils.removePromo(this.order);
		if (promoCode && promoCode.code) {
			this.order.promoCode = promoCode.code;
		}
		this.showInput = true;
	}

	removePromoAndEmit() {
		if (this.order) {
			this.deletePromo.emit(OrderUtils.removePromo(this.order));
			this.showInput = true;
		}
	}

	onAddClick() {
		this.showInput = true;
		this.cdr.detectChanges();
	}

	openLogin() {
		this.navigationService.navigateToUrlWithParams(NAVIGATIONURLS.signIn(), {
			promo: true,
		});
	}

	openReg() {
		this.navigationService.signUp();
	}
	async openModal(item: ArticleGroup) {
		if (!item) {
			return;
		}
		const modal = await this.modalCtrl.create({
			cssClass: AppComponent.largeScreen ? 'item-modal large-modal' : 'item-modal',
			component: ModalInfoComponent,
			componentProps: {
				articleGroup: item,
			},
			mode: 'ios',
			backdropDismiss: true,
		});
		await modal.present();
		const response = await modal.onDidDismiss();
		if (response.data && response.data.articleGroup) {
			OrderUtils.addToOrder(this.order, response.data.articleGroup, this.analytics);

			// this.repository.onOrderChange(this.order);
			return response.data.articleGroup;
		} else {
			return null;
		}
	}
}
