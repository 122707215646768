// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.wrapper.has-background {
  background: white;
}
.wrapper .country-code {
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  text-align: center;
  font-family: AppFont;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1A1A18;
}
.wrapper .country-code::after {
  content: "";
  position: absolute;
  right: -4px;
  bottom: -2px;
  height: 48px;
  width: 1px;
  background: #e5e5e5;
}
.wrapper .country-code .flag-container {
  height: 17px;
  width: 22px;
  border-radius: 3px;
  overflow: hidden;
  margin-right: 10px;
}
.wrapper .country-code .flag-container .flag {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.wrapper .country-code .arrow {
  height: 4px;
  width: 7px;
  margin-left: 13px;
}
.wrapper ion-input {
  padding: 0px 15px !important;
  font-family: AppFont, sans-serif;
  color: var(--ion-color-secondary);
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/input-phone-with-county-code/input-phone-with-county-code.component.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,mBAAA;EACA,2BAAA;AACD;AAAC;EACC,iBAAA;AAEF;AAAC;EACC,uBAAA;EAAA,kBAAA;EACA,aAAA;EACA,kBAAA;EACA,oBAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;AAEF;AAAE;EACC,WAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,UAAA;EACA,mBAAA;AAEH;AACE;EACC,YAAA;EACA,WAAA;EACA,kBAAA;EACA,gBAAA;EACA,kBAAA;AACH;AAAG;EACC,WAAA;EACA,YAAA;EACA,iBAAA;AAEJ;AAEE;EACC,WAAA;EACA,UAAA;EACA,iBAAA;AAAH;AAGC;EACC,4BAAA;EACA,gCAAA;EACA,iCAAA;EACA,eAAA;AADF","sourcesContent":[".wrapper {\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: flex-start;\n\t&.has-background {\n\t\tbackground: white;\n\t}\n\t.country-code {\n\t\twidth: fit-content;\n\t\tpadding: 10px;\n\t\ttext-align: center;\n\t\tfont-family: AppFont;\n\t\tposition: relative;\n\t\tdisplay: flex;\n\t\tjustify-content: center;\n\t\talign-items: center;\n\t\tcolor: #1A1A18;\n\n\t\t&::after {\n\t\t\tcontent: '';\n\t\t\tposition: absolute;\n\t\t\tright: -4px;\n\t\t\tbottom: -2px;\n\t\t\theight: 48px;\n\t\t\twidth: 1px;\n\t\t\tbackground: #e5e5e5;\n\t\t}\n\n\t\t.flag-container {\n\t\t\theight: 17px;\n\t\t\twidth: 22px;\n\t\t\tborder-radius: 3px;\n\t\t\toverflow: hidden;\n\t\t\tmargin-right: 10px;\n\t\t\t.flag {\n\t\t\t\twidth: 100%;\n\t\t\t\theight: 100%;\n\t\t\t\tobject-fit: cover;\n\t\t\t}\n\t\t}\n\n\t\t.arrow {\n\t\t\theight: 4px;\n\t\t\twidth: 7px;\n\t\t\tmargin-left: 13px;\n\t\t}\n\t}\n\tion-input {\n\t\tpadding: 0px 15px !important;\n\t\tfont-family: AppFont, sans-serif;\n\t\tcolor: var(--ion-color-secondary);\n\t\tfont-size: 16px;\n\t}\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
