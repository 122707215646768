import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import PromoCode from 'src/smoothr-web-app-core/models/PromoCode';
import { numberD, numberToCurrency, sleep } from 'src/smoothr-web-app-core/utils/utils';
import { SotPromoBarcodeDialogComponent } from '../sot-promo-barcode-dialog/sot-promo-barcode-dialog.component';
import moment from 'moment';
import { NAVIGATIONURLS, NavigationService } from 'src/app/services/navigation.service';
import { Router } from '@angular/router';
import { RepositoryService } from 'src/smoothr-web-app-core/services/repository/repository.service';
import { OrderUtils } from 'src/smoothr-web-app-core/utils/order-utils';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from 'src/smoothr-web-app-core/services/analytics/analytics.service';
import { ErrorPromcodeDialogComponent } from 'src/app/components/error-promcode-dialog/error-promcode-dialog.component';
import { PromoCodeType } from 'src/smoothr-web-app-core/models/PromoCodeType';

@Component({
	selector: 'app-coupon-item-show-modal',
	templateUrl: './coupon-item-show-modal.component.html',
	styleUrls: ['./coupon-item-show-modal.component.scss'],
})
export class CouponItemShowModalComponent implements OnInit {
	public promoCode: PromoCode;
	public numberD = numberD;
	public numberToCurrency = numberToCurrency;
	public isAddedPromo: boolean;

	constructor(
		private modalCtrl: ModalController,
		private navService: NavigationService,
		private router: Router,
		private repository: RepositoryService,
		public translate: TranslateService,
		private analytics: AnalyticsService
	) {}
	static async show(modalCtrl: ModalController, promoCode: PromoCode, isAddedPromo: boolean = true): Promise<any> {
		const modal = await modalCtrl.create({
			component: CouponItemShowModalComponent,
			cssClass: 'auto-height',
			componentProps: {
				promoCode,
				isAddedPromo,
			},
		});
		await modal.present();
		const result = await modal.onDidDismiss();
		await sleep(100);
		return result.data;
	}
	ngOnInit() {}
	close() {
		this.modalCtrl.dismiss();
	}
	async showBarCodeForSOT() {
		try {
			await SotPromoBarcodeDialogComponent.show(this.modalCtrl, this.promoCode);
		} catch (e) {
			console.log(e);
		}
	}
	async showInApp(promo: PromoCode) {
		if (promo.venues.length > 0 && this.repository?._venue && this.repository?._order) {
			console.log(promo.venues.includes(this.repository?._venue?._id), promo.venues.includes(this.repository?._venue?.masterId));
			if (!promo.venues.includes(this.repository?._venue?._id) && !promo.venues.includes(this.repository?._venue?.masterId)) {
				await ErrorPromcodeDialogComponent.show(this.modalCtrl);
				return;
			}
		}
		if (this.repository?._order && this.repository?._venue && !this.repository?._order?.promoCode) {
			try {
				if (promo.type == PromoCodeType.FREE_ARTICLE) {
					this.repository.promoCodeFree$.next(promo);
				} else {
					this.repository.onOrderChange(
						OrderUtils.applyPromo(this.translate, this.repository._venue, this.repository._order, promo, this.analytics)
					);
				}
				await sleep(300);
				this.navService.menu();
				this.modalCtrl.dismiss();
				return;
			} catch (e) {
				console.log(e);
			}
			return;
		}
		if (this.repository?._order && this.repository?._venue && this.repository?._order?.promoCode) {
			try {
				const order = OrderUtils.removePromo(this.repository?._order);
				console.log('REMOVE PROMO', order);
				this.repository.onOrderChange(order);
				await sleep(300);
				if (promo.type == PromoCodeType.FREE_ARTICLE) {
					this.repository.promoCodeFree$.next(promo);
				} else {
					this.repository.onOrderChange(
						OrderUtils.applyPromo(this.translate, this.repository._venue, order, promo, this.analytics)
					);
				}
				this.navService.menu();
				this.modalCtrl.dismiss();
				return;
			} catch (e) {
				console.log(e);
			}
			return;
		}
		this.navigateToMapWithPromo(promo);
	}
	private navigateToMapWithPromo(promo: PromoCode) {
		this.router.navigate([NAVIGATIONURLS.map()], {
			queryParams: {
				promoCodeId: promo._id,
			},
		});
		this.modalCtrl.dismiss();
	}
	calculateDaysLeft(item: PromoCode) {
		const now = moment();
		const diff = moment(item.to).diff(now, 'days');
		const from = moment(new Date(item.from)).diff(now, 'days');
		if (from > 0) {
			return this.translate.instant('add_promo_dialog.in_future', {
				dateDiff: from,
			});
		}
		if (diff > 0) {
			return (
				this.translate.instant('add_promo_dialog.from_time') +
				' ' +
				moment(item.to).diff(now, 'days') +
				' ' +
				this.translate.instant('add_promo_dialog.to_time')
			);
		}
		return this.translate.instant('add_promo_dialog.expired');
	}
	checkIfTimeBetween(item: PromoCode) {
		return moment().isBetween(moment(item.from), moment(item.to));
	}
	removePromoCode() {
		this.modalCtrl.dismiss('remove');
	}
	addPromoCode() {
		this.modalCtrl.dismiss('add');
	}
}
