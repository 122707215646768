import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import validator from 'validator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AccountPage } from '../account/account.page';
import { EmailConfirmationPage } from '../email-confirmation/email-confirmation.page';
import { Api } from '../../../smoothr-web-app-core/api/api';
import { environment } from '../../../environments/environment';
import { Gender } from 'src/smoothr-web-app-core/enums/Gender';
import { HomePage } from '../home/home.page';
import { MenuPage } from '../menu/menu.page';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { SignInPage } from '../sign-in/sign-in.page';
import { ValidationUtils } from '../../../smoothr-web-app-core/utils/validation-utils';
import { AuthStrategy } from '../../../smoothr-web-app-core/models/AuthStrategy';
import { axiosErrorToMessage } from '../../../smoothr-web-app-core/utils/utils';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import { FormBuilder, FormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { NavigationService } from 'src/app/services/navigation.service';
import { IonInput } from '@ionic/angular';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { countries } from 'src/app/components/input-phone-with-county-code/mock-countries';
import { Company } from 'src/smoothr-web-app-core/models/CompanyCode';
import { AppComponent } from 'src/app/app.component';

@Component({
	selector: 'app-sign-up',
	templateUrl: './sign-up.page.html',
	styleUrls: ['sign-up.page.scss'],
})
export class SignUpPage implements OnDestroy, OnInit {
	static url = 'sign-up';
	static userDataUrl = 'user-data';
	public isAccount = false;
	showRePassword = false;
	showPassword = false;
	isValidEmail = true;
	isValidName = true;
	isValidPassword = true;
	isValidRePassword = true;
	venue: Venue;
	email = 'email';
	password = 'password';
	rePassword = 'rePassword';
	fullName = 'fullName';
	agbChecked = 'agbChecked';
	day = 'day';
	month = 'month';
	year = 'year';
	phone = 'phone';
	phoneCode = 'phoneCode';
	dnd_code = 'dnd_code';
	dnd_codeInvalid = false;
	loading = false;

	signUpForm: FormGroup;

	private readonly subscription: Subscription;

	constructor(
		private translate: TranslateService,
		private router: Router,
		private repository: RepositoryService,
		private snackbarCtrl: MatSnackBar,
		private fb: FormBuilder,
		private navigationService: NavigationService
	) {
		if (repository.customer && !repository.customer.isAnonymous) {
			this.navigationService.account();
		}
	}
	ngOnInit() {
		this.isAccount = false;
		if (this.router.url.includes('user-data')) {
			this.isAccount = true;
		}
		this.createFormSignUp();
	}

	createFormSignUp() {
		const formControlNames: any = {};
		// formControlNames[this.gender] = ['', [Validators.required]];
		formControlNames[this.fullName] = ['', [Validators.required, Validators.pattern(/\w+\s\w+/)]];

		if (!this.isAccount) {
			formControlNames[this.email] = [
				'',
				[
					Validators.required,
					Validators.pattern(
						"^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
					),
				],
			];
			formControlNames[this.agbChecked] = [false, [Validators.required, Validators.requiredTrue]];
			formControlNames[this.password] = [
				'',
				[Validators.required, Validators.minLength(8), Validators.pattern(/\d+/), Validators.pattern(/[A-Z]+/)],
			];

			formControlNames[this.rePassword] = ['', [Validators.required, this._isPasswordMatch()]];
		}

		formControlNames[this.day] = ['', [Validators.max(31)]];
		formControlNames[this.month] = ['', [Validators.max(12)]];
		formControlNames[this.year] = ['', [Validators.min(1900)]];
		formControlNames[this.phoneCode] = ['+49'];
		formControlNames[this.phone] = ['', [this._customPhoneValidatorForLibrary]];
		formControlNames[this.dnd_code] = [''];

		this.signUpForm = this.fb.group(formControlNames);
		if (this.isAccount) {
			this.patchForm();
		}
	}
	private patchForm() {
		let defaultPhone = {
			country: '+49',
			phone: '',
		};

		if (this.repository && this.repository?.customer && this.repository?.customer?.phone) {
			let countryCode = countries.find(it => this.repository.customer?.phone?.trim().startsWith('+' + it.dialCode));
			defaultPhone = {
				country: '+' + countryCode?.dialCode ?? '+49',
				phone: this.repository.customer?.phone
					? this.repository.customer?.phone?.trim()?.replace('+' + countryCode?.dialCode, '')
					: '',
			};
		}
		console.log(defaultPhone);
		if (this.isAccount && this.repository.customer) {
			this.signUpForm.patchValue({
				gender: this.repository.customer.gender,
				fullName: this.repository.customer.name,
				email: this.repository.customer.email,

				phone: {
					country: defaultPhone.country,
					phone: defaultPhone.phone,
				},
			});
			if (this.repository.customer.birthDate) {
				this.signUpForm.patchValue({
					day: new Date(this.repository.customer.birthDate).getDate(),
					month: new Date(this.repository.customer.birthDate).getMonth() + 1,
					year: new Date(this.repository.customer.birthDate).getFullYear(),
				});
			}
		}
		// this.route.queryParams.subscribe(params => {
		// 	if (params.email || params.name) {
		// 		this.signUpForm.patchValue({
		// 			name: params.name?.split(' ')[0] ?? '',
		// 			vorname: params.name?.split(' ')[1] ?? '',
		// 			email: params.email
		// 		});
		// 	}
		// });
		console.log(this.signUpForm.value);
	}
	private _isPasswordMatch(): ValidatorFn {
		return () => {
			const pass = this.signUpForm?.get('password')?.value;
			const confirmPass = this.signUpForm?.get('rePassword')?.value;
			return pass === confirmPass ? null : { notSame: true };
		};
	}
	private _customPhoneValidatorForLibrary(control: AbstractControl) {
		if (!control.value?.phone) {
			return null;
		}
		const phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
		try {
			const phoneNumber = phoneUtil.parse(control?.value?.country + control?.value?.phone.toString());
			if (phoneUtil.isValidNumber(phoneNumber) && phoneUtil.isPossibleNumber(phoneNumber)) {
				return null;
			} else {
				return { invalidPhone: true };
			}
		} catch (e) {
			return { invalidCountryCode: true };
		}
	}

	isValid(controlName?: string): boolean {
		if (controlName) {
			return this.signUpForm.get(controlName)?.hasError('required') && this.signUpForm.get(controlName)?.touched;
		}

		return this.signUpForm.invalid;
	}

	isValidByPattern(controlName?: string): boolean {
		if (controlName) {
			return this.signUpForm.get(controlName)?.errors && !this.signUpForm.get(controlName)?.hasError('required');
		}
		return this.signUpForm.invalid;
	}

	getControlValue(controlName?: string) {
		if (controlName) {
			return this.signUpForm?.get(controlName)?.value;
		}
		return '';
	}

	ngOnDestroy() {
		if (this.subscription && !this.subscription.closed) {
			this.subscription.unsubscribe();
		}
	}
	async update() {
		try {
			this.loading = true;

			const response = await Api.patchCustomer({
				...this.repository.customer,
				name: this.getControlValue('fullName'),

				// email: this.getControlValue('email'),
				preferredVenues: [],
				phone:
					this.getControlValue('phone')?.phone && this.getControlValue('phone')?.country
						? this.getControlValue('phone')?.country + this.getControlValue('phone')?.phone
						: null,
				birthDate:
					this.getControlValue(this.year) && this.getControlValue(this.month) && this.getControlValue(this.day)
						? new Date(
								new Date(0).setFullYear(
									this.getControlValue(this.year),
									Number(this.getControlValue(this.month)) - 1,
									this.getControlValue(this.day)
								)
						  ).toString()
						: null,
			});

			const mainUser = this.repository._customerAuth;
			mainUser.customer = { ...mainUser.customer, ...response.data };
			console.log(mainUser.customer);
			this.repository.customerAuth.next(mainUser);
			this.loading = false;
			this.snackbarCtrl.open('Your account was updated', null, {
				duration: 4000,
			});
			// await InformationModalComponent.show(this.modalCtrl, {
			// 	title: this.translate.instant('sign_up.success'),
			// 	info: this.translate.instant('sign_up.updated'),
			// 	closeButton: this.translate.instant('add_points.close')
			// });
		} catch (e) {
			console.log(e);
			this.loading = false;

			if (e?.response?.data?.message) {
				this.snackbarCtrl.open(e.response.data.message, null, {
					duration: 4000,
				});
			} else {
				this.snackbarCtrl.open(this.translate.instant('account.error'), null, {
					duration: 2000,
				});
			}
		}
	}
	async signUp() {
		if (!this.loading) {
			this.loading = true;
			this.dnd_codeInvalid = false;
			let company: Company = null;
			if (this.getControlValue(this.dnd_code)) {
				try {
					company = (await Api.validateCompanyCode(this.getControlValue(this.dnd_code))).data;
					console.log(company);
				} catch (e) {
					this.dnd_codeInvalid = true;
					this.loading = false;
					this.snackbarCtrl.open('Company code is invalid', null, {
						duration: 4000,
					});
					return;
				}
			}
			try {
				const objectToSend = {
					name: this.getControlValue('fullName'),
					gender: Gender.male,
					email: this.getControlValue('email'),
					preferredVenues: [],
					birthDate:
						this.getControlValue(this.year) != '' &&
						this.getControlValue(this.month) != '' &&
						this.getControlValue(this.day) != ''
							? new Date(
									new Date(0).setFullYear(
										this.getControlValue(this.year),
										Number(this.getControlValue(this.month)) - 1,
										this.getControlValue(this.day)
									)
							  ).toString()
							: '',
					phone:
						this.getControlValue('phone')?.phone && this.getControlValue('phone')?.country
							? this.getControlValue('phone')?.country + this.getControlValue('phone')?.phone
							: '',
					// dndCompany: company ? company?._id : '',
				};
				// if(company && company?._id) {
				// 	objectToSend.dndCompany
				// }
				await Api.signUpWithCredentials(objectToSend, this.getControlValue('password'));
				await this.navigationService.emailConfirmation();
			} catch (e) {
				if (e.response.data.name === 'UserAlreadyExistsError') {
					this.isValidEmail = false;
				}
				this.snackbarCtrl.open(axiosErrorToMessage(this.translate, e));
			}
			this.loading = false;
		}
	}

	async signIn() {
		await this.navigationService.signIn();
	}

	setEmailValid() {
		this.isValidEmail = true;
	}

	setNameValid() {
		this.isValidName = true;
	}

	setPasswordValid() {
		this.isValidPassword = true;
		this.isValidRePassword = true;
	}

	async signUpWithGoogle() {
		if (this.agbChecked && !this.loading) {
			this.loading = true;
			try {
				console.log('Starting signUp with Google');
				const result = await Api.signIn(AuthStrategy.GOOGLE);
				if (result && result.data) {
					if (AppComponent.largeScreen) {
						window.open(result.data.url, '_blank');
					} else {
						window.location.assign(result.data.url);
					}
				}
			} catch (e) {
				console.error(e);
			}
			this.loading = false;
		}
	}

	async signUpWithFacebook() {
		if (this.agbChecked && !this.loading) {
			this.loading = true;
			try {
				console.log('Starting signUp with Facebook');
				const result = await Api.signIn(AuthStrategy.FACEBOOK);
				console.log(result);
			} catch (e) {
				console.error(e);
			}
			this.loading = false;
		}
	}

	async signUpWithApple() {
		if (this.agbChecked && !this.loading) {
			this.loading = true;
			try {
				console.log('Starting signUp with Apple');
				const result = await Api.signIn(AuthStrategy.APPLE);
				console.log('RESULT', result);
				if (result && result.data) {
					var ua = navigator.userAgent.toLowerCase();

					if (ua.indexOf('safari') != -1) {
						if (ua.indexOf('chrome') > -1) {
							window.open(result.data.url, '_blank');
						} else {
							window.location.assign(result.data.url);
						}
					}
					// if (AppComponent.largeScreen) {
					//
					// } else {
					// 	window.location.assign(result.data.url)
					// }
				}
			} catch (e) {
				console.error(e);
			}
			this.loading = false;
		}
	}

	openTerms() {
		window.open('/tos', '_blank');
	}

	openPrivacy() {
		window.open('/privacy', '_blank');
	}

	goBack() {
		if (this.repository._order === null) {
			this.navigationService.home();
		} else {
			this.navigationService.menu();
		}
	}
	gotoNextField(nextElement: IonInput) {
		nextElement.setFocus();
	}
	getErrorsForm(controlName) {
		if (controlName) {
			return this.signUpForm.get('phone').errors;
		}

		return null;
	}
}
