import Order from '../models/Order';
import { OrderType } from '../enums/OrderType';
import ArticleGroup from '../models/ArticleGroup';
import Venue from '../models/Venue';
import { PreorderType } from '../enums/PreorderType';
import ArticleOption from '../models/ArticleOption';
import OptionGroup from '../models/OptionGroup';
import FulfilledDependency from '../models/FulfilledDependency';
import { Moment } from 'moment';
import { TimeUtils } from './time-utils';
import { TerminalorderType } from '../enums/TerminalorderType';
import { PromoCodeType } from '../models/PromoCodeType';
import { TranslateService } from '@ngx-translate/core';
import PromoCode from '../models/PromoCode';
import { DisplayIdentifier } from '../enums/DisplayIdentifier';
import Article from '../models/Article';
import { ValidationUtils } from './validation-utils';
import { calculateGeoDistance } from './maps-utils';
import TagUtils from './tag-utils';
import { Fee } from '../models/MovAndFee';
import {
	defaultsToArticleOption,
	foundDepositArticlePrice,
	getPrice,
	getRequiredArticles,
	isArticleHasDeposit,
	isDepositArticle,
	numberD,
	numberToCurrency,
	removeFromArray,
} from './utils';
import { AnalyticsService } from '../services/analytics/analytics.service';
import { Company } from '../models/CompanyCode';
// import groupBy from 'lodash/groupBy';

export class OrderUtils {
	static formattedOrderTotalPrice(order: Order, deliveryFee: boolean, promoCode: boolean): string {
		return numberToCurrency(OrderUtils.orderTotalPrice(order, deliveryFee, promoCode), order ? order.currency : null);
	}

	static totalPriceByOrder(articleGroup: ArticleGroup, order: Order): number {
		return this.totalPrice(articleGroup, order.type, order.preorder?.type, order.terminalorder?.type);
	}

	static orderTotalPrice(order: Order, deliveryFee: boolean, promoCode: boolean, company: Company = null) {
		let mulyply = 1;

		if (order.preorder.type === PreorderType.FOOD_SPOT && company && company?.storeDiscount && Number(company.storeDiscount)) {
			mulyply = 1 - Number(company.storeDiscount) ?? 1;
		}
		const articlePrices = +order.orderedArticles
			.map(orderedArticle => {
				const casted: any = orderedArticle;
				if (casted.totalPrice) {
					return numberD(casted.totalPrice);
				}
				return OrderUtils.totalPrice(orderedArticle, order.type, order.preorder?.type, order.terminalorder?.type);
			})
			.reduce((prev, curr) => prev.valueOf() + curr.valueOf(), 0);
		const fees = deliveryFee ? OrderUtils.getDeliveryFees(order) : 0;
		let promoValue = 0;
		if (promoCode && order.promoCode && order.promoCode.type) {
			switch (order.promoCode.type) {
				case PromoCodeType.ABSOLUTE:
					promoValue = +order.promoCode.value;
					break;
				case PromoCodeType.RELATIVE:
					promoValue = articlePrices * +order.promoCode.value;
					break;
				case PromoCodeType.DELIVERY_FEE:
					promoValue = fees;
					break;
				case PromoCodeType.FREE_ARTICLE:
					promoValue = OrderUtils.totalPrice(
						order.orderedArticles.find(artGrp => artGrp.isPromo),
						order.type,
						order.preorder?.type
					);
					break;
				case PromoCodeType.BOGO:
					promoValue = OrderUtils.bogoPrice(
						order.orderedArticles.find(artGrp => artGrp.isPromo),
						order.type,
						order.preorder?.type
					);
					break;
			}
		}
		if (order.preorder.type === PreorderType.FOOD_SPOT && company) {
			if (company?.isPaidByCompany && !company?.subsidy) {
				return 0;
			}
			if (company?.subsidy) {
				let wrapSubsity = Number(company.subsidy);
				let price = (articlePrices + fees) * mulyply - promoValue;
				let finalPrice = price - wrapSubsity;
				return finalPrice > 0 ? finalPrice : 0;
			}
		}

		return (articlePrices + fees) * mulyply - promoValue;
	}

	static bogoPrice(articleGroup: ArticleGroup, orderType: OrderType, preorderType: PreorderType): number {
		const basePrice = getPrice(articleGroup.article, orderType, preorderType);
		const fullPriceOptions = articleGroup.groups.filter(artOpt =>
			articleGroup.article.groups.find(
				grp => grp._id === artOpt.group && grp.displayIdentifiers.indexOf(DisplayIdentifier.fullPrice) >= 0
			)
		);
		return (
			basePrice +
			fullPriceOptions
				.map(artOpt => getPrice(artOpt.article, orderType, preorderType) * artOpt.quantity)
				.reduce((previousValue, currentValue) => previousValue + currentValue, 0)
		);
	}

	static isStandard(order: Order): boolean {
		return order && order?.type === OrderType.STANDARD;
	}

	static isCatering(order: Order): boolean {
		return order?.type === OrderType.CATERING;
	}

	static isPreorder(order: Order): boolean {
		return order?.type === OrderType.PREORDER && !!order?.preorder;
	}

	static isDelivery(order: Order): boolean {
		return OrderUtils.isPreorder(order) && order.preorder.type === PreorderType.DELIVERY;
	}

	static isParkCollect(order: Order): boolean {
		return OrderUtils.isPreorder(order) && order.preorder.type === PreorderType.PARK_COLLECT;
	}

	static isTakeAway(order: Order): boolean {
		return OrderUtils.isPreorder(order) && order.preorder.type === PreorderType.TAKE_AWAY;
	}

	static isInside(order: Order): boolean {
		return OrderUtils.isPreorder(order) && order.preorder.type === PreorderType.INSIDE;
	}

	static isFreeDeliveryPromo(order: Order): boolean {
		return OrderUtils.isDelivery(order) && OrderUtils.hasPromo(order) && order.promoCode.type === PromoCodeType.DELIVERY_FEE;
	}

	static isBogoOrFreeArticlePromo(order: Order): boolean {
		return (
			OrderUtils.isPreorder(order) &&
			OrderUtils.hasPromo(order) &&
			(order.promoCode.type === PromoCodeType.BOGO || order.promoCode.type === PromoCodeType.FREE_ARTICLE)
		);
	}

	static isAbsoluteOrRelativePromo(order: Order): boolean {
		return (
			OrderUtils.isPreorder(order) &&
			OrderUtils.hasPromo(order) &&
			(order.promoCode.type === PromoCodeType.RELATIVE || order.promoCode.type === PromoCodeType.ABSOLUTE)
		);
	}

	static hasPromo(order: Order): boolean {
		return order && order.promoCode && order.promoCode._id && order.promoCode.type;
	}

	static applyPromo(translate: TranslateService, venue: Venue, order: Order, promoCode: PromoCode, analytics: AnalyticsService): Order {
		if (OrderUtils.orderTotalPriceWithoutDiscounts(order) < promoCode.mov) {
			throw translate.instant('promo_code.mov_not_reached', {
				mov: numberToCurrency(promoCode.mov, order.currency),
			});
		}
		if (promoCode.type === PromoCodeType.DELIVERY_FEE && !OrderUtils.isDelivery(order)) {
			throw translate.instant('promo_code.free_delivery_wrong_type');
		}
		switch (promoCode.type) {
			case PromoCodeType.BOGO:
				// promoCode.value is array of article masterIds
				const possibleBogos = order.orderedArticles.filter(
					artGrp => promoCode.value.indexOf(artGrp.article.masterId) >= 0 || promoCode.value.indexOf(artGrp.article._id) >= 0
				);
				if (possibleBogos.length === 0) {
					throw translate.instant('promo_code.no_bogo_in_cart');
				} else {
					let candidate = possibleBogos[0];
					let currentBogoPrice = OrderUtils.bogoPrice(candidate, order.type, order.preorder?.type);
					for (const possibleBogo of possibleBogos) {
						const possibleBogoPrice = OrderUtils.bogoPrice(possibleBogo, order.type, order.preorder?.type);
						if (possibleBogoPrice < currentBogoPrice) {
							currentBogoPrice = possibleBogoPrice;
							candidate = possibleBogo;
						}
					}
					const bogo: ArticleGroup = JSON.parse(JSON.stringify(candidate));
					bogo.quantity = 1;
					bogo.isPromo = true;
					OrderUtils.addToOrder(order, bogo, analytics);
				}
				break;
			case PromoCodeType.FREE_ARTICLE:
				const allArticles: Article[] = [];
				venue.articleCategories.forEach(cat => allArticles.push(...cat.articles));
				// promoCode.value is  article masterId
				const freeArticle = allArticles.find(art => promoCode.value.indexOf(art.masterId) >= 0);
				const freeArticleGroup = new ArticleGroup();
				freeArticleGroup.article = freeArticle;
				freeArticleGroup.quantity = 1;
				freeArticleGroup.isPromo = true;
				freeArticleGroup.freeArticle = true;
				OrderUtils.addToOrder(order, freeArticleGroup, analytics);
				break;
		}
		order.promoCode = promoCode;
		return order;
	}

	static removePromo(order: Order): Order {
		const removedPromo = order.promoCode;
		order.promoCode = null;
		if (!removedPromo) {
			return order;
		}
		switch (removedPromo.type) {
			case PromoCodeType.DELIVERY_FEE:
			case PromoCodeType.ABSOLUTE:
			case PromoCodeType.RELATIVE:
				break;
			case PromoCodeType.BOGO:
			case PromoCodeType.FREE_ARTICLE:
				order.orderedArticles = order.orderedArticles.filter(artGrp => !artGrp.isPromo);
				break;
		}
		return order;
	}

	static orderTotalPriceWithoutDiscounts(order: Order): number {
		return this.orderTotalPrice(order, true, false);
	}

	static articleGroupsTotalPrice(
		articleGroups: ArticleGroup[],
		orderType: OrderType,
		preorderType: PreorderType,
		terminalorderType: TerminalorderType = null,
		company: Company = null
	): number {
		let mulyply = 1;
		if (company) {
			mulyply = 1 - Number(company.storeDiscount) ?? 1;
		}
		return (
			articleGroups
				.map(articleGroup => OrderUtils.totalPrice(articleGroup, orderType, preorderType, terminalorderType))
				.reduce((prev, curr) => prev + curr, 0) * mulyply
		);
	}

	static totalPrice(
		articleGroup: ArticleGroup,
		orderType: OrderType,
		preorderType: PreorderType,
		terminalOrderType: TerminalorderType = null,
		company: Company = null
	): number {
		const articleGroupValue = articleGroup as ArticleGroup;
		let discount = 0;
		let mulyply = 1;
		if (company) {
			mulyply = 1 - Number(company.storeDiscount) ?? 1;
		}
		if (
			articleGroup.article &&
			articleGroup.article.tags.length > 0 &&
			articleGroup.article.tags.find(it => it.identifier == 'appliesMostExpensive')
		) {
			const foundGroupsForDiscount =
				articleGroup?.article?.groups?.length > 0
					? articleGroupValue.article.groups.filter(it => it.tags.find(tag => tag.identifier === 'half_pizza'))
					: [];
			if (foundGroupsForDiscount) {
				const testGroups = new Map([].concat(...foundGroupsForDiscount).map(it => [it._id, it]));
				const foundGroupsForDiscountInArticle = articleGroupValue.groups.filter(it => testGroups.has(it.group));
				if (foundGroupsForDiscountInArticle.length > 0) {
					const findMinPrice = Math.min(
						...foundGroupsForDiscountInArticle.map(
							it => getPrice(it.article, orderType, preorderType, terminalOrderType) * it.quantity
						)
					);
					if (findMinPrice > 0) {
						discount = findMinPrice * articleGroup.quantity;
					}
				}
			}
		}
		return (
			(+getPrice(articleGroup.article, orderType, preorderType, terminalOrderType) +
				articleGroup.groups
					.map(option => {
						return +getPrice(option.article, orderType, preorderType, terminalOrderType) * option.quantity;
					})
					.reduce((prev, curr) => prev + curr, 0)) *
			(articleGroup.quantity - discount) *
			mulyply
		);
	}
	static checkIfOrdersTheSame(order: Order, newOrder: Order) {
		const mappedVerifiedOrder = new Map((order?.orderedArticles ?? []).map(it => [it?.article?._id, it?.quantity]));
		return newOrder.orderedArticles.every(
			it => mappedVerifiedOrder.has(it.article._id) && mappedVerifiedOrder.get(it.article._id) === it.quantity
		);
	}
	static injectRequiredArticles(venue: Venue, order: Order, analytics: AnalyticsService) {
		if (!venue || !order) {
			return;
		}
		const requiredArticles = getRequiredArticles(venue, order.preorder?.type);
		const requiredArticleGroups: ArticleGroup[] = [];
		for (const requiredArticle of requiredArticles) {
			if (!order.orderedArticles.find(oa => oa.article._id === requiredArticle._id)) {
				const ag = new ArticleGroup();
				ag.article = requiredArticle;
				ag.groups = defaultsToArticleOption(requiredArticle, ag.groups, requiredArticle.defaults, order.type, order.preorder?.type);
				ag.quantity = 1;
				requiredArticleGroups.push(ag);
			}
		}
		for (const requiredArticleGroup of requiredArticleGroups) {
			OrderUtils.addToOrder(order, requiredArticleGroup, analytics);
		}
	}

	static injectDeliveryFees(venue: Venue, order: Order) {
		if (!venue || !order) {
			if (OrderUtils.isPreorder(order)) {
				order.preorder.deliveryFee = undefined;
			}
			return;
		}
		if (!OrderUtils.isPreorder(order)) {
			return;
		}
		if (!OrderUtils.isDelivery(order)) {
			order.preorder.deliveryFee = undefined;
			return;
		}
		const articleSum = OrderUtils.articleGroupsTotalPrice(
			order.orderedArticles,
			order.type,
			order.preorder?.type,
			order.terminalorder?.type
		);
		order.preorder.deliveryFee = this.findMovAndFees(venue, order, articleSum).fee;
	}

	static findFees(venue: Venue, order: Order, totalPrice: number): number {
		return OrderUtils.findMovAndFees(venue, order, totalPrice).fee;
	}

	static findMov(venue: Venue, order: Order, totalPrice: number): number {
		return OrderUtils.findMovAndFees(venue, order, totalPrice).mov;
	}

	static addSingle(selectedOptions: ArticleOption[], option: ArticleOption) {
		const relevantOptions = selectedOptions.filter(value => value.group !== option.group);
		while (selectedOptions.length) {
			selectedOptions.pop();
		}
		selectedOptions.push(...relevantOptions);
		option.quantity = 1;
		selectedOptions.push(option);
	}

	static addToOrder(order: Order, articleGroup: ArticleGroup, analytics: AnalyticsService) {
		const currentPromoIndex = order.orderedArticles.findIndex(oa => oa.isPromo);
		if (
			currentPromoIndex >= 0 &&
			OrderUtils.isBogoOrFreeArticlePromo(order) &&
			order.promoCode.type === PromoCodeType.BOGO &&
			order.promoCode.value.indexOf(articleGroup.article.masterId) >= 0 &&
			OrderUtils.bogoPrice(articleGroup, order.type, order.preorder.type) <
				OrderUtils.bogoPrice(order.orderedArticles[currentPromoIndex], order.type, order.preorder.type)
		) {
			// new article is added and is bogo and is the smallest bogo price so there is no copy of this article.
			// remove isPromo Flag from previous article
			// add new article twice (once with isPromo true)
			order.orderedArticles[currentPromoIndex].isPromo = false;
			order.orderedArticles.push(articleGroup);
			const bogo = JSON.parse(JSON.stringify(articleGroup));
			bogo.isPromo = true;
			order.orderedArticles.push(bogo);
			// analytics.addToCart(order, articleGroup);
			return;
		}
		const index = order.orderedArticles.findIndex(orderedArticle => {
			return (
				orderedArticle.article._id === articleGroup.article._id &&
				articleGroup.groups.length === orderedArticle.groups.length &&
				articleGroup.isPromo === orderedArticle.isPromo &&
				articleGroup.groups
					.map(
						option =>
							orderedArticle.groups.findIndex(
								orderedOption =>
									option.article._id === orderedOption.article._id &&
									option.quantity === orderedOption.quantity &&
									option.dependency === orderedOption.dependency &&
									option.dependsOn === orderedOption.dependsOn &&
									option.dependencyNumber === orderedOption.dependencyNumber
							) >= 0
					)
					.reduce((previousValue, currentValue) => previousValue && currentValue, true)
			);
		});
		if (index >= 0) {
			order.orderedArticles[index].quantity++;
			order.orderedArticles[index].isRecommendedRecipe =
				order.orderedArticles[index].isRecommendedRecipe || articleGroup.isRecommendedRecipe;
		} else {
			order.orderedArticles.push(articleGroup);
			// analytics.addToCart(order, articleGroup);
		}
	}
	static addOptionNew(
		options: ArticleOption[],
		option: ArticleOption,
		group: OptionGroup,
		dependency: FulfilledDependency,
		dependencyNumber: number
	) {
		if (!dependency) {
			console.error('No dependency');
			return;
		}
		if (dependency.times < 0) {
			console.log('Not adding option. Dependency not fulfilled');
			return;
		}
		option.dependencyNumber = dependencyNumber;
		if (dependency.times > 0) {
			option.dependsOn = dependency.dependsOn;
			option.dependency = dependency.dependency._id;
		}
		const selection = filterMatchingOptionsNew(options, group, dependency, dependencyNumber);
		if (group.limit === 1) {
			OrderUtils.addSingle(options, option);
			return;
		}
		const emptyIndex = options.findIndex(emptyOption => emptyOption.group === group._id && TagUtils.hasEmptyTag(emptyOption.article));
		const count =
			selection.map(value => value.quantity).reduce((previousValue, currentValue) => previousValue + currentValue, 0) +
			option.quantity;
		if (count > group.limit && group.limit !== 0) {
			const indexOfFirst = options.indexOf(selection.find(value => value.article._id !== option.article._id));
			if (indexOfFirst >= 0) {
				if (options[indexOfFirst].quantity > 1) {
					options[indexOfFirst].quantity -= 1;
				} else {
					options.splice(indexOfFirst, 1);
				}
			}
		}
		const index = options.findIndex(value => value.article._id === option.article._id);
		if (index >= 0 && !group.hasMultiple) {
			removeFromArray(options, index);
		} else if (option.group === group._id && option.article.tags && option.article.tags.find(tag => tag.identifier === 'empty')) {
			if (emptyIndex >= 0) {
				removeFromArray(options, emptyIndex);
			} else {
				OrderUtils.addSingle(options, option);
			}
		} else {
			if (emptyIndex >= 0) {
				removeFromArray(options, emptyIndex);
			}
			const matchedIndex = options.findIndex(optionToMatch => {
				return (
					optionToMatch.group === option.group &&
					optionToMatch.article._id === option.article._id &&
					optionToMatch.dependency === option.dependency &&
					optionToMatch.dependsOn === option.dependsOn &&
					optionToMatch.dependencyNumber === option.dependencyNumber
				);
			});
			if (matchedIndex >= 0) {
				const requirements = options[matchedIndex].article.requirements;
				if (requirements) {
					if (
						(requirements.min !== -1 && requirements.min > options[matchedIndex].quantity + option.quantity) ||
						(requirements.max !== -1 && requirements.max < options[matchedIndex].quantity + option.quantity)
					) {
						console.log({
							message: 'Could not remove or add option',
							requirements,
							option: option.article.name.de,
							presentQuantity: options[matchedIndex].quantity,
							modifyBy: option.quantity,
						});
						return;
					}
				}
				options[matchedIndex].quantity += option.quantity;
				if (options[matchedIndex].quantity <= 0) {
					removeFromArray(options, matchedIndex);
				}
			} else {
				options.push(option);
			}
		}
		options = options.filter(option => {
			if (!option.dependsOn) return true;
			const parentsCount = options
				.filter(parent => parent.article._id === option.dependsOn)
				.map(parent => parent.quantity)
				.reduce((prev, curr) => prev + curr, 0);
			const keep = parentsCount >= option.dependencyNumber || option.dependencyNumber === 0;
			if (!keep) {
				console.log('Remove:', {
					parentsCount,
					dependencyNumber: option.dependencyNumber,
				});
			}
			return keep;
		});
		console.log(
			options.map(opt => ({
				quantity: opt.quantity,
				name: opt.article.name.de,
				dependencyNumber: opt.dependencyNumber,
			}))
		);
	}
	static addOption(options: ArticleOption[], option: ArticleOption, group: OptionGroup, dependency: FulfilledDependency) {
		if (!dependency) {
			console.error('No dependency');
			return;
		}
		if (dependency.times < 0) {
			console.log('Not adding option. Dependency not fulfilled');
			return;
		}
		if (dependency.times > 0) {
			option.dependencyNumber = dependency.times;
			option.dependsOn = dependency.dependsOn;
			option.dependency = dependency.dependency._id;
		}
		const selection = filterMatchingOptions(options, group, dependency);
		console.log('selection', selection);
		if (group.limit === 1) {
			OrderUtils.addSingle(options, option);
			return;
		}
		const emptyIndex = options.findIndex(emptyOption => emptyOption.group === group._id && TagUtils.hasEmptyTag(emptyOption.article));
		const count =
			selection.map(value => value.quantity).reduce((previousValue, currentValue) => previousValue + currentValue, 0) +
			option.quantity;
		if (count > group.limit && group.limit !== 0) {
			const indexOfFirst = options.indexOf(selection.find(value => value.article._id !== option.article._id));
			if (indexOfFirst >= 0) {
				if (options[indexOfFirst].quantity > 1) {
					options[indexOfFirst].quantity -= 1;
				} else {
					options.splice(indexOfFirst, 1);
				}
			}
		}
		const index = options.findIndex(value => value.article._id === option.article._id);
		if (index >= 0 && !group.hasMultiple) {
			removeFromArray(options, index);
		} else if (option.group === group._id && option.article.tags && option.article.tags.find(tag => tag.identifier === 'empty')) {
			if (emptyIndex >= 0) {
				removeFromArray(options, emptyIndex);
			} else {
				OrderUtils.addSingle(options, option);
			}
		} else {
			if (emptyIndex >= 0) {
				removeFromArray(options, emptyIndex);
			}
			const matchedIndex = options.findIndex(optionToMatch => {
				return (
					optionToMatch.group === option.group &&
					optionToMatch.article._id === option.article._id &&
					optionToMatch.dependency === option.dependency &&
					optionToMatch.dependsOn === option.dependsOn &&
					optionToMatch.dependencyNumber === option.dependencyNumber
				);
			});
			if (matchedIndex >= 0) {
				const requirements = options[matchedIndex].article.requirements;
				if (requirements) {
					if (
						(requirements.min !== -1 && requirements.min > options[matchedIndex].quantity + option.quantity) ||
						(requirements.max !== -1 && requirements.max < options[matchedIndex].quantity + option.quantity)
					) {
						console.log({
							message: 'Could not remove or add option',
							requirements,
							option: option.article.name.de,
							presentQuantity: options[matchedIndex].quantity,
							modifyBy: option.quantity,
						});
						return;
					}
				}
				options[matchedIndex].quantity += option.quantity;
				if (options[matchedIndex].quantity <= 0) {
					removeFromArray(options, matchedIndex);
				}
			} else {
				options.push(option);
			}
		}
	}

	static getDeliveryFees(order: Order): number {
		if (!OrderUtils.isDelivery(order)) {
			return 0;
		}
		if (!order.preorder.deliveryFee) {
			return 0;
		}
		return numberD(order.preorder.deliveryFee);
	}

	static validateOrder(venue: Venue, order: Order): { valid: boolean; movDifference: number } {
		const orderValue = OrderUtils.orderTotalPrice(order, false, true);
		let mov = order.preorder?.type === PreorderType.DELIVERY ? OrderUtils.findMov(venue, order, orderValue) : 0;
		let articlesValid = order.orderedArticles.length !== 0;
		if (OrderUtils.hasPromo(order)) {
			mov = Math.max(mov, +order.promoCode.mov);
		}
		for (const oa of order.orderedArticles) {
			if (!ValidationUtils.areGroupsValid(oa, oa.article.groups)) {
				articlesValid = false;
				break;
			}
		}
		return {
			valid: orderValue > mov && articlesValid,
			movDifference: orderValue - mov,
		};
	}

	static slotConflictingArticlesInOrder(slot: Moment, order: Order): ArticleGroup[] {
		const conflictingArticles: ArticleGroup[] = [];
		for (const articleGroup of order.orderedArticles) {
			if (!TimeUtils.doesHoursMatch(slot, articleGroup.article.availableHours)) {
				conflictingArticles.push(articleGroup);
			}
		}
		return conflictingArticles;
	}

	private static findMovAndFees(venue: Venue, order: Order, totalPrice: number): { mov: number; fee: number } {
		if (!venue) {
			return undefined;
		}
		if (!OrderUtils.isDelivery(order)) {
			return undefined;
		}
		if (!venue.movAndFee) {
			return {
				mov: numberD(venue.movDelivery) ?? 0,
				fee: OrderUtils.findDeliveryFeesDeprecated(venue, order, totalPrice),
			};
		}
		if (venue.deliveryByRadius) {
			const distance = calculateGeoDistance(
				order.preorder.lat,
				order.preorder.lng,
				venue.location.coordinates[1],
				venue.location.coordinates[0]
			);
			if (!venue.movAndFee.byRadius || venue.movAndFee.byRadius.length === 0) {
				console.log('No movAndFee.byRadius taking defaults');
				return {
					mov: numberD(venue.movAndFee.mov),
					fee: numberD(venue.movAndFee.fee),
				};
			}
			const byRadius = venue.movAndFee.byRadius.reduce((prev, curr) => {
				return curr.radius >= distance && curr.radius < prev.radius ? curr : prev;
			});
			if (!byRadius) {
				console.log('No specific fees found taking defaults');
				return {
					mov: numberD(venue.movAndFee.mov),
					fee: numberD(venue.movAndFee.fee),
				};
			}
			return {
				mov: numberD(byRadius ? byRadius.mov : venue.movAndFee.mov),
				fee: numberD(OrderUtils.findFee(byRadius.fees, totalPrice)?.fee ?? venue.movAndFee.fee),
			};
		} else {
			const postalCode = order.preorder.postalCode;
			console.log({ postalCode });
			if (!venue.movAndFee.byPostalCodes || venue.movAndFee.byPostalCodes.length === 0) {
				console.log('No movAndFee.byPostalCodes taking defaults');
				return {
					mov: numberD(venue.movAndFee.mov),
					fee: numberD(venue.movAndFee.fee),
				};
			}
			const byPostalCode = venue.movAndFee.byPostalCodes.find(bpc => bpc.postalCode === postalCode);

			if (!byPostalCode) {
				console.log('No specific fees found taking defaults');
				return {
					mov: numberD(venue.movAndFee.mov),
					fee: numberD(venue.movAndFee.fee),
				};
			}
			return {
				mov: numberD(byPostalCode ? byPostalCode.mov : venue.movAndFee.mov),
				fee: numberD(OrderUtils.findFee(byPostalCode.fees, totalPrice)?.fee ?? venue.movAndFee.fee),
			};
		}
	}

	private static findFee(fees: Fee[], totalPrice: number): Fee {
		const relevantFees = fees.filter(f => numberD(f.from) <= totalPrice);
		if (relevantFees.length === 0) {
			return null;
		}
		return relevantFees.reduce((prev, curr) => {
			return numberD(prev.from) > numberD(curr.from) ? prev : curr;
		});
	}

	private static findDeliveryFeesDeprecated(venue: Venue, order: Order, totalPrice: number): number {
		let candidate: any;
		if (order.preorder?.postalCode && venue.deliveryFeesPostalCodes && venue.deliveryPostalCodes.length > 0) {
			candidate = venue.deliveryFeesPostalCodes.find(fee => fee.postalCode === order.preorder?.postalCode);
			if (candidate) {
				return numberD(candidate.fee);
			}
		}
		if (venue.deliveryFees.length < 1) {
			return undefined;
		}
		candidate = venue.deliveryFees[0];
		for (const deliveryFee of venue.deliveryFees) {
			if (numberD(deliveryFee.from) <= totalPrice && numberD(candidate.fee) > numberD(deliveryFee.fee)) {
				candidate = deliveryFee;
			}
		}
		return numberD(candidate?.fee);
	}
	static addPfandArticles(venue: Venue, order: Order) {
		console.log('ADD PFAND ARTICLES');
		let tmpOrderedArticles = order.orderedArticles.filter(it => it?.quantity > 0);

		if (tmpOrderedArticles.length > 0) {
			//Checking how many articles with Pfand i have
			let depositCount = tmpOrderedArticles
				.map(it => {
					if (isArticleHasDeposit(it.article)) {
						return it.quantity;
					} else {
						return (
							it.groups.map(group => (isArticleHasDeposit(group.article) ? group.quantity : 0)).reduce((a, b) => a + b, 0) *
							it.quantity
						);
					}
				})
				.reduce((a, b) => a + b, 0);
			// if I don`t have any pfand  so i delete pfand articles from order
			if (depositCount <= 0) {
				tmpOrderedArticles = tmpOrderedArticles.filter(
					it =>
						!it.article.tags.find(
							it => it.identifier === 'deposit8' || it.identifier === 'deposit15' || it.identifier === 'deposit25'
						)
				);
				return tmpOrderedArticles;
			}
			tmpOrderedArticles = tmpOrderedArticles.filter(it => {
				console.log(it.article.name, isDepositArticle(it.article));
				return !(
					isDepositArticle(it.article) &&
					it.article.tags.find(
						it => it.identifier === 'deposit8' || it.identifier === 'deposit15' || it.identifier === 'deposit25'
					)
				);
			});
			console.log('FILTER', tmpOrderedArticles);
			// Using for grouping articles
			const groupByToMap = <T, Q>(array: T[], predicate: (value: T, index: number, array: T[]) => Q) =>
				array.reduce((map, value, index, array) => {
					const key = predicate(value, index, array);
					map.get(key)?.push(value) ?? map.set(key, [value]);
					return map;
				}, new Map<Q, T[]>());
			const arrayOfAllArticles = tmpOrderedArticles.reduce((acc, articlGroup) => {
				acc.push(...Array(articlGroup.quantity).fill(articlGroup.article));
				if (articlGroup.groups.length > 0) {
					articlGroup.groups.forEach(it => {
						acc.push(...Array(articlGroup.quantity * it.quantity).fill(it.article));
					});
				}
				return acc;
			}, [] as Article[]);
			//Group data to new MAP with keys of deposit
			const groupArray = groupByToMap(arrayOfAllArticles, v => foundDepositArticlePrice(v)?.identifier);
			console.log('GROUP DATA', groupArray);
			//for every deposit i check if need to create new Article in basket or increase quantity
			if (groupArray.get('deposit8')?.length > 0) {
				console.log('DEPOSIT 8', groupArray.get('deposit8'));
				let currentIndex = tmpOrderedArticles.findIndex(
					it => isDepositArticle(it.article) && it.article.tags.find(it => it.identifier === 'deposit8')
				);
				if (currentIndex >= 0) {
					tmpOrderedArticles[currentIndex].quantity = groupArray.get('deposit8')?.length;
				} else {
					const foundArticle = ([].concat(...venue.articleCategories.map(it => it.articles)) as Article[]).find(it => {
						return it.tags.length > 0 && isDepositArticle(it) && it.tags.find(it => it.identifier.includes('deposit8'));
					});
					if (foundArticle) {
						const articleGroupPfand = new ArticleGroup();
						articleGroupPfand.article = foundArticle;
						articleGroupPfand.quantity = groupArray.get('deposit8')?.length;
						articleGroupPfand.groups = [];
						tmpOrderedArticles.push(articleGroupPfand);
					}
				}
			}
			console.log(groupArray.get('undefined'));

			if (groupArray.get('deposit15')?.length > 0) {
				console.log('DEPOSIT 15', groupArray.get('deposit15'));
				let currentItem = tmpOrderedArticles.find(
					it => isDepositArticle(it.article) && it.article.tags.find(it => it.identifier === 'deposit15')
				);
				if (currentItem) {
					tmpOrderedArticles = tmpOrderedArticles.map(it => {
						if (isDepositArticle(it.article) && it.article.tags.find(it => it.identifier === 'deposit15')) {
							it.quantity = groupArray.get('deposit15')?.length;
							return it;
						} else {
							return it;
						}
					});

					// tmpOrderedArticles[currentIndex].quantity = groupArray.get('deposit15')?.length;
				} else {
					const foundArticle = ([].concat(...venue.articleCategories.map(it => it.articles)) as Article[]).find(it => {
						return it.tags.length > 0 && isDepositArticle(it) && it.tags.find(it => it.identifier.includes('deposit15'));
					});
					if (foundArticle) {
						const articleGroupPfand = new ArticleGroup();
						articleGroupPfand.article = foundArticle;
						articleGroupPfand.quantity = groupArray.get('deposit15')?.length;
						articleGroupPfand.groups = [];
						tmpOrderedArticles.push(articleGroupPfand);
					}
				}
				// let currentIndex = tmpOrderedArticles.findIndex(
				// 	it => isDepositArticle(it.article) && it.article.tags.find(it => it.identifier === 'deposit15')
				// );
				// if (currentIndex >= 0) {
				// 	tmpOrderedArticles[currentIndex].quantity = groupArray.get('deposit15')?.length;
				// } else {
				// 	const foundArticle = ([].concat(...venue.articleCategories.map(it => it.articles)) as Article[]).find(it => {
				// 		return (
				// 			it.tags.length > 0 && isDepositArticle(it) && it.tags.find(it => it.identifier.includes('deposit15'))
				// 		);
				// 	});
				// 	if (foundArticle) {
				// 		const articleGroupPfand = new ArticleGroup();
				// 		articleGroupPfand.article = foundArticle;
				// 		articleGroupPfand.quantity = groupArray.get('deposit15')?.length;
				// 		articleGroupPfand.groups = [];
				// 		tmpOrderedArticles.push(articleGroupPfand);
				// 	}
				// }
			}
			if (groupArray.get('deposit25')?.length > 0) {
				console.log('DEPOSIT 25', groupArray.get('deposit25'));
				let currentItem = tmpOrderedArticles.find(
					it => isDepositArticle(it.article) && it.article.tags.find(it => it.identifier === 'deposit25')
				);
				if (currentItem) {
					tmpOrderedArticles = tmpOrderedArticles.map(it => {
						if (isDepositArticle(it.article) && it.article.tags.find(it => it.identifier === 'deposit25')) {
							it.quantity = groupArray.get('deposit25')?.length;
							return it;
						} else {
							return it;
						}
					});

					// tmpOrderedArticles[currentIndex].quantity = groupArray.get('deposit15')?.length;
				} else {
					const foundArticle = ([].concat(...venue.articleCategories.map(it => it.articles)) as Article[]).find(it => {
						return it.tags.length > 0 && isDepositArticle(it) && it.tags.find(it => it.identifier.includes('deposit25'));
					});
					if (foundArticle) {
						const articleGroupPfand = new ArticleGroup();
						articleGroupPfand.article = foundArticle;
						articleGroupPfand.quantity = groupArray.get('deposit25')?.length;
						articleGroupPfand.groups = [];
						tmpOrderedArticles.push(articleGroupPfand);
					}
				}

				// let currentIndex = tmpOrderedArticles.findIndex(
				// 	it => isDepositArticle(it.article) && it.article.tags.filter(it => it.identifier === 'deposit25')
				// );
				// if (currentIndex >= 0) {
				// 	tmpOrderedArticles[currentIndex].quantity = groupArray.get('deposit25')?.length;
				// } else {
				// 	const foundArticle = ([].concat(...venue.articleCategories.map(it => it.articles)) as Article[]).find(it => {
				// 		return (
				// 			it.tags.length > 0 && isDepositArticle(it) && it.tags.find(it => it.identifier.includes('deposit25'))
				// 		);
				// 	});
				// 	if (foundArticle) {
				// 		const articleGroupPfand = new ArticleGroup();
				// 		articleGroupPfand.article = foundArticle;
				// 		articleGroupPfand.quantity = groupArray.get('deposit25')?.length;
				// 		articleGroupPfand.groups = [];
				// 		tmpOrderedArticles.push(articleGroupPfand);
				// 	}
				// }
			}
			// Using for pushing Pfand Articles to latest item of array
			console.log(tmpOrderedArticles);
			tmpOrderedArticles = this.movePfandElementsToEndOfArray(tmpOrderedArticles);
			return tmpOrderedArticles;
		} else {
			return [];
		}
	}
	static movePfandElementsToEndOfArray(arr: ArticleGroup[]): ArticleGroup[] {
		const foundPfandsArticles = arr.filter(it => it.article.tags.find(it => it.identifier === 'is_deposit'));
		return (arr = [...arr.filter(it => !it.article.tags.find(it => it.identifier === 'is_deposit')), ...foundPfandsArticles]);
	}
}
export const filterMatchingOptionsNew = (
	options: ArticleOption[],
	group: OptionGroup,
	dependency: FulfilledDependency,
	dependencyNumber: number
) => {
	// console.log(options, group, dependency, dependencyNumber);
	return options.filter(value => {
		return (
			group._id === value.group &&
			dependencyNumber === value.dependencyNumber &&
			(dependency.dependency?._id === value.dependency || (!dependency.dependency && !value.dependency))
		);
	});
};
export const filterMatchingOptions = (options: ArticleOption[], group: OptionGroup, dependency: FulfilledDependency) => {
	return options.filter(value => {
		return (
			group._id === value.group &&
			((!value.dependency && dependency.times === 0) ||
				(dependency.times === value.dependencyNumber &&
					dependency.dependsOn === value.dependsOn &&
					dependency.dependency._id === value.dependency))
		);
	});
};

export const filterMatchingOptionsWithDependencyNumber = (
	options: ArticleOption[],
	group: OptionGroup,
	dependency: FulfilledDependency,
	dependencyNumber: number
) => {
	return options.filter(value => {
		return (
			group._id === value.group &&
			((!value.dependency && dependency.times === 0) ||
				(dependency.times === value.dependencyNumber &&
					dependency.dependsOn === value.dependsOn &&
					dependency.dependency._id === value.dependency))
		);
	});
};
