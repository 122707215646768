import { Component, Input } from '@angular/core';
import { OrderStatus } from '../../../smoothr-web-app-core/enums/OrderStatus';
import * as moment from 'moment';
import { numberToCurrency, sleep } from '../../../smoothr-web-app-core/utils/utils';
import Order from '../../../smoothr-web-app-core/models/Order';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import { Router } from '@angular/router';
import { OrderPage } from '../../pages/order/order.page';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { OrderUtils } from '../../../smoothr-web-app-core/utils/order-utils';
import { NavigationService } from 'src/app/services/navigation.service';
import { MapsUtils } from 'src/smoothr-web-app-core/utils/maps-utils';
import { ValidationUtils } from 'src/smoothr-web-app-core/utils/validation-utils';

@Component({
	selector: 'app-my-order',
	templateUrl: './my-order.component.html',
	styleUrls: ['my-order.component.scss'],
})
export class MyOrderComponent {
	@Input() order: Order;

	showQr = false;
	moment = moment;

	os = OrderStatus;
	pt = PreorderType;
	numberToCurrency = numberToCurrency;
	OrderUtils = OrderUtils;
	@Input() showMinInfo = false;
	loading = false;

	constructor(
		private repository: RepositoryService,
		private router: Router,
		private snackbarCtrl: MatSnackBar,
		private translate: TranslateService,
		private navigationService: NavigationService
	) {}

	async reorder() {
		this.loading = true;
		try {
			//TODO: save address for delivery
			if (this.order.preorder.type === PreorderType.DELIVERY) {
				const pred =
					this.order.preorder.street +
					' ' +
					this.order.preorder.number +
					', ' +
					this.order.preorder.city +
					' ' +
					this.order.preorder.postalCode;
				const geocode = await MapsUtils.getPlace(pred);
				const address = MapsUtils.placeToAddress(geocode);
				const addressValidationResult = ValidationUtils.validateAddress(address, true);
				if (addressValidationResult) {
					return;
				}
				this.repository.address.emit(address);
			}
			console.log('HERE');
			await this.repository.reorder(this.translate, this.order);
			await sleep(100);
			await this.navigationService.order(true);
		} catch (e) {
			console.error(e);
			this.snackbarCtrl.open(e.message ? e.message : this.translate.instant('my_order.reorder_error'), null, {
				duration: 2000,
			});
		}
		this.loading = false;
	}
	checkDelivery(order: Order) {
		console.error(order);
		if (order.isPayed && order.status !== OrderStatus.DONE && order.preorder.type === PreorderType.DELIVERY) {
			if (order._id && (order as any)?.payments) this.navigationService.paymentSuccess(order._id, (order as any)?.payments[0]);
		}
	}
}
