import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { sleep } from 'src/smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-sot-promo-barcode-dialog',
	templateUrl: './sot-promo-barcode-dialog.component.html',
	styleUrls: ['./sot-promo-barcode-dialog.component.css'],
})
export class SotPromoBarcodeDialogComponent implements OnInit {
	constructor(private modalCtrl: ModalController) {}
	static async show(modalCtrl: ModalController, customTip: string): Promise<number> {
		const modal = await modalCtrl.create({
			cssClass: 'short-info-modal auto-height',
			component: SotPromoBarcodeDialogComponent,
			componentProps: {
				customTip,
			},
			showBackdrop: true,
			backdropDismiss: true,
		});
		await modal.present();
		const result = (await modal.onDidDismiss()).data;
		await sleep(200);
		return result;
	}

	ngOnInit() {}
	dismiss() {
		this.modalCtrl.dismiss();
	}
}
