import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Api } from 'src/smoothr-web-app-core/api/api';
import Customer from 'src/smoothr-web-app-core/models/Customer';
import { RepositoryService } from 'src/smoothr-web-app-core/services/repository/repository.service';
import { sleep } from 'src/smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-enter-personal-promo-code',
	templateUrl: './enter-personal-promo-code.component.html',
	styleUrls: ['./enter-personal-promo-code.component.scss'],
})
export class EnterPersonalPromoCodeComponent implements OnInit {
	public inputControl = new FormControl('', [Validators.required]);
	public isLoading = false;
	public customer: Customer;
	public isAdded = false;
	public errorMessage = '';
	constructor(
		private modalCtrl: ModalController,
		private repository: RepositoryService
	) {}
	static async show(modalCtrl: ModalController, customer: Customer): Promise<any> {
		const modal = await modalCtrl.create({
			component: EnterPersonalPromoCodeComponent,
			cssClass: 'auto-height',
			componentProps: {
				customer,
			},
		});
		await modal.present();
		const result = await modal.onDidDismiss();
		await sleep(100);
		return result.data;
	}
	ngOnInit() {
		this.isAdded = false;
	}
	checkCode() {}
	async addPromo() {
		this.isAdded = false;
		if (this.isLoading || !this.customer) {
			return;
		}
		if (!this.inputControl.value) {
			this.setErrorForm();
			return;
		}
		this.isLoading = true;

		try {
			const promoCode = (await Api.getPromoCodeByLinkedCustomerGroup(this.inputControl.value)).data;
			promoCode.mov = +promoCode.mov;
			console.log('HERE', promoCode, promoCode.boundToUser);
			// const result = await Api.addPersonPromo({
			// 	customer: this.customer._id,
			// 	promoCode: promoCode._id,
			// });
			if (promoCode.boundToUser) {
				const boundToUserPromo = await Api.boundPromoCodeToUser(promoCode._id, this.customer._id);
			}
			// if (!promoCode.boundToUser) {
			const result = await Api.addPersonPromo({
				customer: this.customer._id,
				promoCode: promoCode._id,
			});
			// }

			this.isAdded = true;
			this.isLoading = false;
			this.repository._customerAuth.customer = result;
			this.repository.customerAuth.next(this.repository._customerAuth);
		} catch (e) {
			this.isLoading = false;
			this.setErrorForm();
			if (e?.response?.data?.message) {
				this.errorMessage = e?.response?.data?.message;
			}
			this.isAdded = false;
		}
	}
	private setErrorForm() {
		this.inputControl.setErrors({
			notExist: true,
		});
	}
	public dismiss() {
		this.modalCtrl.dismiss();
	}
}
