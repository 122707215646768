import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import MarkerClusterer from '@googlemaps/markerclustererplus';
import { IonInput, ModalController, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject, debounceTime, map, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OrderType } from 'src/smoothr-web-app-core/enums/OrderType';
import { AllowGpsModalComponent } from '../../../app/components/allow-gps-modal/allow-gps-modal.component';
import { DeliveryNotAvailableModalComponent } from '../../../app/components/delivery-not-available-modal/delivery-not-available-modal.component';
import { DeliveryNotAvailableAction } from '../../../app/enums/DeliveryNotAvailableAction';
import { MenuPage } from '../../../app/pages/menu/menu.page';
import { MapsUtils, calculateGeoDistance } from '../../../maps/utils/maps';
import { MapsUtils as MapUtils2 } from 'src/smoothr-web-app-core/utils/maps-utils';

import { Api } from '../../../smoothr-web-app-core/api/api';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import { PreorderType } from '../../../smoothr-web-app-core/enums/PreorderType';
import Address from '../../../smoothr-web-app-core/models/Address';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import { RepositoryService } from '../../../smoothr-web-app-core/services/repository/repository.service';
import { TimeUtils } from '../../../smoothr-web-app-core/utils/time-utils';
import { coverFlow, sleep, venueAcceptsOrders } from '../../../smoothr-web-app-core/utils/utils';

import Map = google.maps.Map;
import MapOptions = google.maps.MapOptions;
import { MapLocationModalComponent } from 'src/app/components/map-location-modal/map-location-modal.component';
import { OrderUtils } from 'src/smoothr-web-app-core/utils/order-utils';
import { ValidationUtils } from 'src/smoothr-web-app-core/utils/validation-utils';
import { MapPage } from 'src/app/pages/map/map.page';
import { NavigationService } from 'src/app/services/navigation.service';
import { CheckDeliveryRadiusModalComponent } from 'src/app/components/check-delivery-radius-modal/check-delivery-radius-modal.component';
import { AppComponent } from 'src/app/app.component';
import { EnterFoodspotCodeModalComponent } from 'src/app/components/enter-foodspot-code-modal/enter-foodspot-code-modal.component';
import { FormControl } from '@angular/forms';
import { SearchCity } from 'src/smoothr-web-app-core/models/SearchCity';
import PromoCode from 'src/smoothr-web-app-core/models/PromoCode';
import { ErrorPromcodeDialogComponent } from 'src/app/components/error-promcode-dialog/error-promcode-dialog.component';
import { PromoCodeType } from 'src/smoothr-web-app-core/models/PromoCodeType';
import { AnalyticsService } from 'src/smoothr-web-app-core/services/analytics/analytics.service';
// import { ErrorSelectOrderTypePopoverComponent } from 'src/app/components/error-select-order-type-popover/error-select-order-type-popover.component';

@Component({
	selector: 'app-map-venue',
	templateUrl: './map-venue.component.html',
	styleUrls: ['./map-venue.component.scss'],
})
export class MapVenueComponent extends RepositoryDirective implements OnInit, OnDestroy {
	@Input() showSearchInput = false;
	@Input() showVenueList = true;
	@Input() venueToFirstPositionShortId = '';
	@ViewChild('mapContainer', { static: true })
	mapElement: ElementRef;
	@ViewChild('swiper') slides: ElementRef | undefined;
	map: Map;
	@ViewChild(IonInput, { static: true })
	inputField: IonInput;
	@ViewChild('venueList', { static: false }) venueList;
	public preorderTypes = [PreorderType.INSIDE, PreorderType.TAKE_AWAY, PreorderType.DELIVERY, PreorderType.FOOD_SPOT].filter(it =>
		environment.deactivateDelivery ? it !== PreorderType.DELIVERY && environment.deactivateDelivery : true
	);
	mapOptions: MapOptions = {
		maxZoom: 15,
		minZoom: 5,
		disableDefaultUI: true,
		clickableIcons: false,
	};
	clusterer: MarkerClusterer;
	loading = false;
	loadingAddress = false;
	predictions: any[] = [];
	showHint = false;
	searchTerm: string;
	allVenues: Venue[] = [];
	localVenues: Venue[] = [];
	selectedVenueForDelivery: Venue;
	showMap = true;
	slidesOpts = {
		slidesPerView: 1,
		coverflowEffect: {
			rotate: 0,
			stretch: 15,
			depth: 10,
			modifier: 2,
		},
		on: coverFlow,
	};
	selectedVenue: Venue;
	MapsUtils = MapsUtils;
	private _showPredictions = false;
	selectedType: PreorderType | null = null;
	showErrorSelectOrderType: boolean = false;
	searchCityForm: FormControl = new FormControl('');
	private onDestroy$ = new Subject<void>();
	public cityPrediction: SearchCity[] = [];
	public promoCodeId: string = '';
	constructor(
		private snackbarCtrl: MatSnackBar,
		public repository: RepositoryService,
		private translate: TranslateService,
		private router: Router,
		private cdr: ChangeDetectorRef,
		private modalCtrl: ModalController,
		private route: ActivatedRoute,
		private navService: NavigationService,
		private popover: PopoverController,
		private analytics: AnalyticsService
	) {
		super(repository);
	}

	get relevantVenues(): Venue[] {
		return this.address && this.selectedType === PreorderType.DELIVERY && this.localVenues?.length != 0
			? this.localVenues
			: (this.selectedType === PreorderType.INSIDE || this.selectedType === PreorderType.TAKE_AWAY) ? this.allVenues.filter(it => venueAcceptsOrders(it, this.selectedType)) : this.allVenues;
	}

	static navigate(router: Router) {
		return router.navigateByUrl(MapPage.url);
	}

	ngOnInit() {
		super.ngOnInit();
		this.searchCityForm.valueChanges.pipe(debounceTime(600), takeUntil(this.onDestroy$)).subscribe(v => {
			if (!v) {
				this.resetMap(true);
			} else {
				this.loadCity(v);
			}
		});
		this.map = new Map(this.mapElement.nativeElement, this.mapOptions);
		new Promise<void>(async resolve => {
			await this.fillInPlace(null);
			await this.initAutocomplete();
			resolve();
		});
		this.route.queryParams.subscribe(v => {
			if (v?.preorderType) {
				this.selectedType = v.preorderType;
				if (this.selectedType === PreorderType.DELIVERY) {
					this.showDialogForAddress();
				}
			}
			if (v?.promoCodeId) {
				this.promoCodeId = v.promoCodeId;
			}
		});
	}
	async loadCity(search: string) {
		try {
			const city = await Api.loadCitiesFromService(search);
			this.cityPrediction = city;
		} catch (e) { }
	}
	ngOnDestroy(): void {
		this.onDestroy$.next();
	}
	async resetMap(ignoreExistingOfVenue: boolean = false) {
		if (this.allVenues.length === 0 || ignoreExistingOfVenue) {
			try {
				this.allVenues = (await Api.getAllVenues(environment.customerGroup)).data
					.map(ven => {
						try {
							const resultMapping = TimeUtils.sanitizeHours(ven.openingHours, ven.specialOpeningHours ?? []);
							ven.openingHours = TimeUtils.checkSpecialHours(resultMapping, ven?.specialOpeningHours ?? [])
							ven.deliveryHours = TimeUtils.sanitizeHours(ven.deliveryHours);
						} catch (e) {
							console.error({
								message: 'Error while sanitizing hours ' + e,
								venue: ven.name + ' ' + ven.readableId,
								openingHours: ven.openingHours,
								deliveryHours: ven.deliveryHours,
							});
						}
						return ven;
					})
					.filter(
						ven =>
							ven.isPublished &&
							(venueAcceptsOrders(ven, PreorderType.DELIVERY) || venueAcceptsOrders(ven, PreorderType.TAKE_AWAY) || venueAcceptsOrders(ven, PreorderType.INSIDE))
					)
					.sort((a, b) => {
						const nameA = a?.name?.replace('Dean & David', '')?.trim();
						const nameB = b?.name?.replace('Dean & David', '')?.trim();
						return nameA?.localeCompare(nameB);
					});
				console.error(this.allVenues);
				if (this.address) {
					console.log('Sort');
					this.allVenues = this.allVenues.sort((a, b) => {
						return (
							calculateGeoDistance(this.address.lat, this.address.lng, a.location.coordinates[1], a.location.coordinates[0]) -
							calculateGeoDistance(this.address.lat, this.address.lng, b.location.coordinates[1], b.location.coordinates[0])
						);
					});
				}
				this.allVenues = this.allVenues.filter(it => it.readableId != 'dispatch_test_dnd_frei_komtur');
			} catch (e) { }
		}
		await this.setupMap(null);
	}

	async setupMap(selectedVenue: Venue) {
		if (this.loading) {
			return;
		}
		this.selectedVenue = selectedVenue ?? this.relevantVenues[0];
		console.log(this.selectedVenue, selectedVenue);
		this.loading = true;
		this.cdr.detectChanges();
		this.clusterer = MapsUtils.addVenuesToMap(this.clusterer, selectedVenue, this.relevantVenues, this.map, venue =>
			this.setupMap(venue)
		);
		if (selectedVenue && selectedVenue.location && selectedVenue.location.coordinates) {
			const selectedIndex = this.relevantVenues.findIndex(ven => ven._id === selectedVenue._id);
			// wait until slides rendered
			this.scrollToVenue(selectedIndex ?? 0);

			await this.slides.nativeElement.swiper.slideTo(selectedIndex);
		}
		this.loading = false;
		this.cdr.detectChanges();
	}

	hidePredictions() {
		setTimeout(() => {
			this._showPredictions = false;
		}, 200);
	}

	showPredictions() {
		this._showPredictions = true;
	}

	async executeSearch() {
		console.log('executeSearch()');
		this.loading = true;
		try {
			const result = await MapUtils2.executeSearch(this.inputField);
			await this.fillInPlace(result);
		} catch (e) { }
		this.loading = false;
	}

	resetSearch() {
		console.log('resetSearch()');
		this.searchTerm = '';
		this.predictions = [];
		this.selectedVenueForDelivery = null;
		this.selectedVenue = null;
		if (this.address) {
			this.repository.address.emit(null);
		}
		this.showHint = false;
		this.cdr.detectChanges();
	}

	async fillInPlace(address: Address) {
		console.log('fillInPlace()');
		if (!address) {
			return;
		}
		this.loading = true;
		try {
			this.searchTerm = MapsUtils.checkAddress(address);
			this.loading = false;
			if (this.address !== address) {
				this.repository.address.emit(address);
			}
			await this.onSlideChange();
		} catch (e) {
			console.error(e);
			this.snackbarCtrl.open(this.translate.instant(e), null, {
				duration: 2000,
			});
		}
		this.loading = false;
	}

	async loadVenues(address: Address) {
		if (this.loading) {
			return;
		}
		this.loading = true;
		this.cdr.detectChanges();
		this.localVenues = [];
		try {
			this.localVenues = (await this.repository.getVenuesByAddress(environment.customerGroup, address)).filter(it =>
				venueAcceptsOrders(it, PreorderType.DELIVERY)
			);
			this.searchTerm = MapsUtils.addressToString(address);
			console.log('LOCAL', this.localVenues);
			if (this.localVenues.length === 0) {
				this.snackbarCtrl.open(
					this.translate.instant('map_page.no_venues_in_address', {
						title: this.searchTerm,
					}),
					null,
					{
						duration: 2000,
					}
				);
				this.loading = false;
				await this.resetMap();
				this.cdr.detectChanges();
				return;
			}
			if (this.selectedVenueForDelivery) {
				const deliveryVenues = this.localVenues.filter(ven => venueAcceptsOrders(ven, PreorderType.DELIVERY));
				const selectedDeliveryVenue = deliveryVenues.find(ven => ven._id === this.selectedVenueForDelivery._id);
				if (selectedDeliveryVenue) {
					await this.selectVenue(selectedDeliveryVenue, PreorderType.DELIVERY);
				} else {
					// const choice = await DeliveryNotAvailableModalComponent.show(this.modalCtrl);
					// switch (choice) {
					// 	case DeliveryNotAvailableAction.DECLINE:
					// 		this.localVenues = deliveryVenues;
					// 		if (deliveryVenues.length === 0) {
					// 			this.repository.address.emit(null);
					// 		} else {
					// 			this.showHint = true;
					// 		}
					// 		await this.setupMap(null);
					// 		break;
					// 	case DeliveryNotAvailableAction.TAKE_AWAY:
					// 		await this.selectVenue(this.selectedVenueForDelivery, PreorderType.TAKE_AWAY);
					// 		break;
					// }
				}
				this.selectedVenueForDelivery = null;
				return;
			}
			this.selectedVenue = null;
			this.loading = false;
			await this.setupMap(this.selectedVenue);
		} catch (e) {
			console.error(e);
		}
		this.loading = false;
		this.cdr.detectChanges();
	}

	async onSlideChange() {
		let index = await this.slides?.nativeElement.swiper.activeIndex;
		console.log(index);
		if (index >= this.relevantVenues.length) {
			index = 0;
		}
		await this.setupMap(this.relevantVenues[index]);
	}

	async selectVenue(venue: Venue, preorderType: PreorderType, attempt: number = 0, prevError: any = null) {
		this.showErrorSelectOrderType = false;
		if (!preorderType) {
			this.showErrorSelectOrderType = true;
			return;
		}
		if (!venueAcceptsOrders(venue, preorderType)) {
			return;
		}
		preorderType = this.selectedType;
		console.log('SELECT VENUE', preorderType, this.address);
		if (preorderType === PreorderType.DELIVERY && !this.address) {
			// this.snackbarCtrl.open(this.translate.instant('map_page.enter_delivery_address'), null, {
			// 	duration: 2000,
			// });
			await this.showDialogForAddress();
			this.selectedVenueForDelivery = venue;
			this.showHint = true;
			return;
		}
		let promo: PromoCode;

		if (this.promoCodeId) {
			try {
				promo = (await Api.getPromoCodeById(this.promoCodeId)).data;
				if (promo?.venues && promo.venues.length > 0) {
					if (!promo.venues.find(it => it === venue._id || it === venue?.masterId)) {
						const result = await ErrorPromcodeDialogComponent.show(this.modalCtrl, true);
						if (!result) {
							return;
						}
						promo = null;
					}
				}
			} catch (e) { }
		}
		if (
			preorderType === PreorderType.DELIVERY &&
			venue.distance > venue.deliveryRadius &&
			venue.deliveryByRadius &&
			!venue.isPostalDelivery
		) {
			console.log('HERE NOT DELIVERY');
			this.snackbarCtrl.open(this.translate.instant('map_page.venue_does_not_delivery_to_address'), null, {
				duration: 2000,
			});
			this.showHint = true;

			return;
		}
		if (attempt > 5) {
			this.loading = false;
			this.snackbarCtrl.open(prevError, null, {
				duration: 2000,
			});
			return;
		}
		this.loading = true;
		if (preorderType === PreorderType.PARK_COLLECT) {
			// preorderType = await ParkCollectSheetComponent.show(this.modalCtrl, venue);
		}
		if (preorderType == PreorderType.DELIVERY && this.address && venue) {
			console.log('CHECK DELIVERY', !(await this.checkAddressDelivery(venue, this.address)));
			if (!(await this.checkAddressDelivery(venue, this.address))) {
				this.snackbarCtrl.open(this.translate.instant('map_page.venue_does_not_delivery_to_address'), null, {
					duration: 2000,
				});
				this.loading = false;
				this.repository.address.next(null);
				return;
			}
		}
		try {
			await this.repository.getVenue(venue._id);
			this.repository.createOrder(venue, this.address, OrderType.PREORDER, preorderType);
			if (promo) {
				await sleep(300);
				if (promo.type === PromoCodeType.FREE_ARTICLE) {
					this.repository.promoCodeFree$.next(promo);
				} else {
					this.repository.promoCodeFree$.next(null);
					this.repository.onOrderChange(OrderUtils.applyPromo(this.translate, this.venue, this.order, promo, this.analytics));
				}
			}
			await sleep(1000);
			if (AppComponent.isNativeApp) {
				this.navService.navigateSelectedCategory();
			} else {
				if (preorderType === PreorderType.FOOD_SPOT) {
					this.navService.foodSpot();
				} else {
					this.navService.menu();
				}
			} // this.router.navigateByUrl('/menu', {
			// 	replaceUrl: true,
			// });
			this.loading = false;
		} catch (e) {
			await this.selectVenue(venue, preorderType, attempt + 1, e);
		}
	}

	async initAutocomplete() {
		try {
			await MapUtils2.initAutocompleteCity(
				this.inputField,
				predictions => {
					console.log('PREV', predictions);
					this.predictions = predictions as any[];
					this.cdr.detectChanges();
				}
				// loading => (this.loadingAddress = loading)
			);
		} catch (e) {
			console.log(e);
		}

		this.loading = false;
	}

	async loadPlace(pred: SearchCity) {
		if (this.loading) {
			return;
		}
		this.loading = true;
		if (pred) {
			try {
				const result = await Api.getListOfVenues(pred.name);
				if (result.data) {
					if (result.data.length > 0) {
						const validatedVenues = result.data
							.map(ven => {
								try {
									ven.openingHours = TimeUtils.sanitizeHours(ven.openingHours, ven.specialOpeningHours ?? []);
									ven.deliveryHours = TimeUtils.sanitizeHours(ven.deliveryHours);
								} catch (e) {
									console.error({
										message: 'Error while sanitizing hours ' + e,
										venue: ven.name + ' ' + ven.readableId,
										openingHours: ven.openingHours,
										deliveryHours: ven.deliveryHours,
									});
								}
								return ven;
							})
							.filter(
								ven =>
									ven.isPublished &&
									(venueAcceptsOrders(ven, PreorderType.DELIVERY) || venueAcceptsOrders(ven, PreorderType.TAKE_AWAY))
							);
						if (validatedVenues.length > 0) {
							this.loading = false;
							this.allVenues = validatedVenues;
							this.cityPrediction = [];
							await this.setupMap(null);
						} else {
							this.loading = false;
							this.snackbarCtrl.open('Sorry, shops in this city were not found ...', null, {
								duration: 4000,
							});
						}
					} else {
						this.loading = false;
						this.snackbarCtrl.open('Sorry, shops in this city were not found ...', null, {
							duration: 4000,
						});
					}
				}
			} catch (e) {
				this.snackbarCtrl.open('Sorry, shops were not found...', null, {
					duration: 4000,
				});
				this.loading = false;

				console.log(e);
			}
		}
		// try {
		// 	const result = await MapUtils2.executeSearch(this.inputField);
		// 	await this.fillInPlace(result);
		// } catch (e) {
		// 	await this.fillInPlace(pred);
		// }

		this.loading = false;
		this.cdr.detectChanges();
	}

	onAddress() {
		super.onAddress();
		new Promise(async () => {
			if (this.address && this.selectedType === PreorderType.DELIVERY) {
				await this.loadVenues(this.address);
			} else {
				await this.resetMap();
				// this.resetSearch();
			}
		});
	}

	setShowMap(value: boolean) {
		this.showMap = value;
		this.cdr.detectChanges();
	}
	mapPrediction(value: any) {
		console.log(value);
		return 'test';
	}
	async checkAddressDelivery(venue: Venue, address: Address) {
		if (this.address.number && this.address.city && this.address.street) {
			try {
				const addressValidationResult = ValidationUtils.validateAddress(address, true);
				if (addressValidationResult) {
					return false;
				}
				if (venue.deliveryByAreas && venue?.deliveryAreas?.length > 0) {
					return true;
				}
				const delivers =
					(venue.deliveryByRadius &&
						calculateGeoDistance(address.lat, address.lng, venue.location.coordinates[1], venue.location.coordinates[0]) <=
						venue.deliveryRadius) ||
					(venue.isPostalDelivery && venue.deliveryPostalCodes.indexOf(address.postalCode));

				if (!delivers) {
					this.snackbarCtrl.open(this.translate.instant('errors.street_delivery'), null, {
						duration: 2000,
					});
					return false;
				}

				return true;
			} catch (e) {
				console.log(e);

				return false;
			}
		} else {
			return false;
		}
	}
	private async scrollToVenue(index: number) {
		try {
			await this.venueList.scrollToPoint(0, this.venueList.el.children[index].offsetTop - 250, 1000);
		} catch (e) { }
	}
	changeType(item: PreorderType) {
		this.showErrorSelectOrderType = false;
		this.selectedType = item;
		if (item === PreorderType.FOOD_SPOT) {
			if (!this.repository.customer.dndCompany) {
				this.askFoodSpotCode();
			}
		}

		if (item == PreorderType.DELIVERY) {
			this.showDialogForAddress();
		}
	}
	private async showDialogForAddress() {
		const result = await CheckDeliveryRadiusModalComponent.show(this.modalCtrl, this.address);

		if (result?.address) {
			this.repository.address.emit(result.address);
		}
	}

	async askFoodSpotCode() {
		try {
			const result = await EnterFoodspotCodeModalComponent.show(this.modalCtrl);
			if (result && result?.openingHours?.length > 0) {
				result.openingHours = TimeUtils.sanitizeHours(result.openingHours);
				this.repository.companyCode$.next(result);
				if (result.venue) {
					const venue = await this.repository.getVenue(result.venue);
					await this.selectVenue(venue, PreorderType.FOOD_SPOT);
					await sleep(200);
					this.selectedType = null;
				}
			} else {
				this.selectedType = null
			}
		} catch (e) { }
	}
}
