// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  width: 100%;
  padding: 20px;
  margin: 0 auto;
}
@media (min-width: 991px) {
  .wrapper {
    width: 30%;
  }
}
.wrapper .title {
  color: var(--ion-color-secondary);
  font-family: AppFontBold, sans-serif;
  text-transform: capitalize;
  padding-bottom: 10px;
}
@media (min-width: 991px) {
  .wrapper .title {
    text-align: center;
  }
}
.wrapper .filter_wrapper {
  width: 100%;
}
.wrapper .filter_wrapper .filter_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 5px 0px;
}
.wrapper .filter_wrapper .filter_item p {
  font-size: 16px;
  font-family: AppFont, sans-serif;
  color: var(--ion-color-secondary);
}
.wrapper .filter_wrapper .filter_item ion-toggle {
  --handle-background: var(--ion-color-primary);
  --handle-background-checked: white;
}
.wrapper ion-button {
  margin-top: 10px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/menu-list-filters/menu-list-filters.component.scss","webpack://./src/theme/mixins.scss"],"names":[],"mappings":"AAGA;EACI,WAAA;EACA,aAAA;EACA,cAAA;AAFJ;ACHE;EDEF;IAKQ,UAAA;EAAN;AACF;AACI;EACI,iCAAA;EACA,oCAAA;EACA,0BAAA;EACA,oBAAA;AACR;ACdE;EDSE;IAMQ,kBAAA;EAGV;AACF;AAAI;EACI,WAAA;AAER;AAAQ;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,WAAA;EACA,gBAAA;AAEZ;AADY;EACI,eAAA;EACA,gCAAA;EACA,iCAAA;AAGhB;AADY;EACI,6CAAA;EACA,kCAAA;AAGhB;AAGI;EACI,gBAAA;EACA,WAAA;AADR","sourcesContent":["@import \"../../../theme/mixins\";\n\n\n.wrapper {\n    width: 100%;\n    padding: 20px;\n    margin: 0 auto;\n    @include desktop {\n        width: 30%;\n    }\n    .title {\n        color: var(--ion-color-secondary);\n        font-family: AppFontBold, sans-serif;\n        text-transform: capitalize;\n        padding-bottom: 10px;\n        @include desktop {\n            text-align: center;\n        }\n       \n    }\n    .filter_wrapper {\n        width: 100%;\n\n        .filter_item {\n            display: flex;\n            justify-content: space-between;\n            align-items: center;\n            width: 100%;\n            padding: 5px 0px;\n            p {\n                font-size: 16px;\n                font-family: AppFont,sans-serif;\n                color: var(--ion-color-secondary);\n            }\n            ion-toggle {\n                --handle-background: var(--ion-color-primary);\n                --handle-background-checked: white;\n                // --handle-max-height: 15px;\n                // height: 20px;\n            }\n        }\n    }\n    ion-button {\n        margin-top: 10px;\n        width: 100%;\n    }\n}","@mixin desktop {\n  @media (min-width: 991px) {\n    @content;\n  }\n}\n\n@mixin mobile {\n  @media (max-width: 992px) {\n    @content\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
