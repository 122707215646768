// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 24px;
  background-color: white;
  padding: 20px 20px;
  box-shadow: 0px -4px 15px 0px rgba(79, 97, 79, 0.2509803922);
}
.header .logo {
  height: 50px;
}
.header ion-button h4 {
  color: white;
  text-transform: capitalize;
  font-size: 20px;
  padding-left: 10px;
}
.header .title {
  font-size: 16px;
  color: #000;
}
.flag-item {
  margin-right: 10px;
}
.flag-item ion-img {
  width: 45px;
  border: 2px solid var(--ion-color-primary);
  border-radius: 50%;
  padding: 2px;
}

.block_info {
  display: flex;
  align-items: center;
}

@media (max-width: 992px) {
  .login {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/header/header.component.scss","webpack://./src/theme/mixins.scss"],"names":[],"mappings":"AAEA;EACC,WAAA;EAEA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,oBAAA;EACA,uBAAA;EAEA,kBAAA;EACA,4DAAA;AAHD;AAKC;EACC,YAAA;AAHF;AAME;EACC,YAAA;EACA,0BAAA;EACA,eAAA;EACA,kBAAA;AAJH;AAOC;EACC,eAAA;EACA,WAAA;AALF;AAaA;EACC,kBAAA;AAXD;AAYC;EACC,WAAA;EACA,0CAAA;EACA,kBAAA;EACA,YAAA;AAVF;;AAcA;EACC,aAAA;EACA,mBAAA;AAXD;;AC7BE;ED0CF;IAEE,aAAA;EAVA;AACF","sourcesContent":["@import '../../../theme/mixins';\n\n.header {\n\twidth: 100%;\n\n\tdisplay: flex;\n\tjustify-content: space-between;\n\talign-items: center;\n\tpadding-inline: 24px;\n\tbackground-color: white;\n\n\tpadding: 20px 20px;\n\tbox-shadow: 0px -4px 15px 0px #4F614F40;\n\n\t.logo {\n\t\theight: 50px;\n\t}\n\tion-button {\n\t\th4 {\n\t\t\tcolor: white;\n\t\t\ttext-transform: capitalize;\n\t\t\tfont-size: 20px;\n\t\t\tpadding-left: 10px;\n\t\t}\n\t}\n\t.title {\n\t\tfont-size: 16px;\n\t\tcolor: #000;\n\t}\n\tapp-burger-btn {\n\t\t// display: block;\n\t\t// margin-left: auto;\n\t\t// margin-right: -20px;\n\t}\n}\n.flag-item {\n\tmargin-right: 10px;\n\tion-img {\n\t\twidth: 45px;\n\t\tborder: 2px solid var(--ion-color-primary);\n\t\tborder-radius: 50%;\n\t\tpadding: 2px;\n\t}\n}\n\n.block_info {\n\tdisplay: flex;\n\talign-items: center;\n}\n.login {\n\t@include mobile {\n\t\tdisplay: none;\n\t}\n}","@mixin desktop {\n  @media (min-width: 991px) {\n    @content;\n  }\n}\n\n@mixin mobile {\n  @media (max-width: 992px) {\n    @content\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
