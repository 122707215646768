import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IonicModule } from '@ionic/angular';
export enum TOGGLE_TAG {
	All = 'all',
	Vegeterian = 'veggie',
	Vegan = 'vegan',
	Fish = 'fish',
	Meat = 'meat',
	Alcohol = 'alcohol',
}

@Component({
	selector: 'app-menu-filters',
	standalone: true,
	imports: [CommonModule, IonicModule],
	template: `
		<div class="vegan_toggle">
			<div class="toggler">
				<div
					class="toggler_item all-item"
					(click)="toggleSwitch(toggleTagEnum.All)"
					[class.selected]="selectedToggleTag === toggleTagEnum.All"
				>
					<ion-icon src="../../../assets/dean_and_david/slider-veggie/all.svg"></ion-icon>
					<p>All</p>
				</div>

				<div
					class="toggler_item image-block"
					(click)="toggleSwitch(toggleTagEnum.Vegeterian)"
					[class.selected]="selectedToggleTag === toggleTagEnum.Vegeterian"
				>
					<ion-icon class="second-img" src="../../../assets/dean_and_david/slider-veggie/veggie.svg"></ion-icon>
					<p>Veggie</p>
				</div>
				<div
					class="toggler_item image-block"
					(click)="toggleSwitch(toggleTagEnum.Vegan)"
					[class.selected]="selectedToggleTag === toggleTagEnum.Vegan"
				>
					<ion-icon class="first-img" src="../../../assets/dean_and_david/slider-veggie/vegetarian.svg"></ion-icon>
					<p>Vegan</p>
				</div>
				<div
					class="toggler_pin"
					[ngClass]="{
						first: selectedToggleTag === toggleTagEnum.All,
						second: selectedToggleTag === toggleTagEnum.Vegeterian,
						third: selectedToggleTag === toggleTagEnum.Vegan
					}"
				></div>
			</div>
		</div>
	`,
	styleUrls: ['./menu-filters.component.scss'],
})
export class MenuFiltersComponent {
	swipeCoord = [0, 0];
	swipeTime = new Date().getTime();
	@Input() selectedToggleTag: TOGGLE_TAG = TOGGLE_TAG.All;
	@Input() showFilters = false;
	@Output() showFiltersValue = new EventEmitter<boolean>();
	@Output() toggleTagValue = new EventEmitter<TOGGLE_TAG>();

	toggleTagEnum = TOGGLE_TAG;

	showFiltersFunc() {
		this.showFilters = !this.showFilters;
		this.showFiltersValue.emit(this.showFilters);
	}
	toggleSwitch(selectedItem: TOGGLE_TAG) {
		this.selectedToggleTag = selectedItem;
		this.toggleTagValue.emit(selectedItem);
	}
}
