// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer_block {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px;
  z-index: 99;
  background: white;
}
.footer_block ion-button {
  width: 100%;
  margin-top: 15px;
}
.footer_block .cancel_btn {
  --background: transparent;
  border: 2px solid var(--ion-color-primary);
  border-radius: 16px;
  color: var(--ion-color-primary);
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: auto;
  padding: 30px 0px;
  overflow: auto;
}
.wrapper .title {
  padding: 0px 20px;
  color: var(--ion-color-secondary);
  text-transform: capitalize;
}

.content {
  width: 100%;
  height: auto;
  overflow: auto;
}
.content app-article-option-group-checkbox {
  width: 100%;
}

.wrapper-header {
  width: 100%;
  text-align: center;
}

ion-footer {
  box-shadow: none !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/show-remove-ingrediants-dialog/show-remove-ingrediants-dialog.component.scss"],"names":[],"mappings":"AACA;EACI,aAAA;EACA,8BAAA;EACA,sBAAA;EACA,mBAAA;EACA,WAAA;EAEA,aAAA;EACA,WAAA;EAKA,iBAAA;AALJ;AACI;EACI,WAAA;EACA,gBAAA;AACR;AAEI;EACI,yBAAA;EACA,0CAAA;EACA,mBAAA;EACA,+BAAA;AAAR;;AAGA;EACI,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,mBAAA;EACA,YAAA;EACA,iBAAA;EACA,cAAA;AAAJ;AACI;EACI,iBAAA;EACA,iCAAA;EACA,0BAAA;AACR;;AAEA;EACI,WAAA;EACA,YAAA;EACA,cAAA;AACJ;AAAI;EACI,WAAA;AAER;;AAEA;EACI,WAAA;EACA,kBAAA;AACJ;;AACA;EACI,2BAAA;AAEJ","sourcesContent":["\n.footer_block {\n    display: flex;\n    justify-content: space-between;\n    flex-direction: column;\n    align-items: center;\n    width: 100%;\n\n    padding: 20px;\n    z-index: 99;\n    ion-button {\n        width: 100%;\n        margin-top: 15px;\n    }\n    background: white;\n    .cancel_btn {\n        --background: transparent;\n        border:2px solid var(--ion-color-primary);\n        border-radius: 16px;\n        color: var(--ion-color-primary);\n    }\n}\n.wrapper {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    align-items: center;\n    height: auto;\n    padding: 30px 0px;\n    overflow: auto;\n    .title {\n        padding: 0px 20px;\n        color: var(--ion-color-secondary);\n        text-transform: capitalize;\n    }\n}\n.content {\n    width: 100%;\n    height: auto;\n    overflow: auto;\n    app-article-option-group-checkbox {\n        width: 100%;\n    }\n\n}\n.wrapper-header {\n    width: 100%;\n    text-align: center;\n}\nion-footer{\n    box-shadow: none !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
